const skuURL = (sku, lang, version) => {
  if (version === 2) {
    if (sku.id_product_attribute > 0) {
      return `/${lang.suffix}/products/${sku.id_product}-${sku.id_product_attribute}-${sku[`link_rewrite_${lang.suffix}`]}.html`
    } else {
      return `/${lang.suffix}/products/${sku.id_product}-${sku[`link_rewrite_${lang.suffix}`]}.html`
    }
  }

  if (sku.id_product_attribute > 0) {
    return `/${lang.suffix}/products/${sku[`category_link_rewrite_${lang.suffix}`]}/${sku.id_product}-${sku.id_product_attribute}-${sku[`link_rewrite_${lang.suffix}`]}${sku.ean13 ? `-${sku.ean13}` : ''}.html`
  } else {
    return `/${lang.suffix}/products/${sku[`category_link_rewrite_${lang.suffix}`]}/${sku.id_product}-${sku[`link_rewrite_${lang.suffix}`]}${sku.ean13 ? `-${sku.ean13}` : ''}.html`
  }
}

module.exports = {
  skuURL
}

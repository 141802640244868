import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { PageLink } from '../..'
import { l, t } from '../../../lib/locale'
import { pages } from '../../../config/pages.json'

const ProductNotFoundPage = (props) => {
  return (
    <div className="product-list-breadcrumbs">

      <div className="row product-detail">
        <div className="col-12 product-detail-left">
          <h1>{l('product404-title', props.lang)}</h1>
          <p>{l('product404-description', props.lang)}</p>
        </div>
      </div>
    </div>
  )
}

ProductNotFoundPage.propTypes = {}

export default ProductNotFoundPage

import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import PageLink from "../../Page/Link"
import { context } from "../../../store"
import { l } from "../../../lib/locale"
import { pageURL } from "../../../lib/page"

const { pages } = require("../../../config/pages.json")
const currentstep = pages.find(i => i.id === "checkout-step-guest")
const nextstep = pages.find(i => i.id === "checkout-step-shipping")

const CheckoutBlockGuest = ({ lang, active, index }) => {
  const { store, sessionProvider, cartProvider } = useContext(context)

  const { disabled, customer } =
    store && store.session ? store.session : { disabled: false, customer: null }

  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState("")
  const [showLoginButton, setShowLoginButton] = useState(false)
  const [gender, setGender] = useState(customer ? customer.id_gender : null)
  const [firstname, setFirstname] = useState(customer ? customer.firstname : "")
  const [lastname, setLastname] = useState(customer ? customer.lastname : "")
  const [email, setEmail] = useState(customer ? customer.email : "")

  const [address1, setAddress1] = useState(customer ? customer.address1 : "")
  const [address2, setAddress2] = useState(customer ? customer.address2 : "")
  const [city, setCity] = useState(customer ? customer.city : "")
  const [postcode, setPostcode] = useState(customer ? customer.postcode : "")
  const [id_country, setCountry] = useState("")

  const readOnly = customer && customer.is_guest ? true : false

  const saveInfo = async e => {
    if (e) e.preventDefault()

    setError(false)
    setErrorCode("")
    setShowLoginButton(false)

    try {
      const guestAccount = {
        email,
        password: "", // guest does not need a password
        gender,
        firstname,
        lastname,
        newsletter: false, // @todo
        lang,
        address1,
        address2,
        city,
        postcode,
        id_country,
      }
      const result = await sessionProvider.create(guestAccount)

      if (result) {
        await cartProvider.refresh()
        navigate(nextstep[`path_${lang.suffix}`])
      } else {
        // @todo show error
        setErrorCode("error-checkout-guest")
        setError(true)
      }
    } catch (e) {
      setError(true)
      if (e.type === "INVALID_EMAIL") {
        setShowLoginButton(true)
        setErrorCode(`error-checkout-${e.type}`)
      } else {
        setErrorCode("error-checkout-guest")
      }
    }
  }

  return (
    <div className="content">
      <PageLink
        className="form-header d-flex align-items-center clickable"
        to={currentstep[`path_${lang.suffix}`]}
      >
        <h3>
          <span className="form-header-step">{index}</span>
          {l("checkout-info", lang)}
        </h3>
      </PageLink>
      {active && (
        <div className="form-content" id="personal">
          <div className="checkboxes">
            <p>{l("field-gender", lang)}</p>
            <div className="row mb-0">
              <div className="col-12">
                <input
                  className="mr-1"
                  type="checkbox"
                  id="woman"
                  checked={gender === 1}
                  onChange={e => setGender(1)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                />
                <label htmlFor="woman">{l("field-gender-ms", lang)}</label>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-12">
                <input
                  className="mr-1"
                  type="checkbox"
                  id="man"
                  checked={gender === 2}
                  onChange={e => setGender(2)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                />
                <label htmlFor="man">{l("field-gender-mr", lang)}</label>
              </div>
            </div>
          </div>
          <div className="inputs container">
            <div className="row mb-1">
              <div className="field col-12 col-md-6">
                <p>{l("field-firstname", lang)}</p>
                <input
                  className=""
                  value={firstname}
                  onChange={e => setFirstname(e.target.value)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                />
              </div>
              <div className="field col-12 col-md-6">
                <p>{l("field-lastname", lang)}</p>
                <input
                  className=""
                  value={lastname}
                  onChange={e => setLastname(e.target.value)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                />
              </div>
            </div>
            <div className="field">
              <p>{l("field-email", lang)}</p>
              <input
                className=""
                value={email}
                onChange={e => setEmail(e.target.value)}
                readOnly={readOnly}
                disabled={readOnly || disabled}
              />
            </div>
            <div className="row">
              <div className="field col-12 col-md-8">
                <p>{l("field-address", lang)}</p>
                <input
                  className=""
                  value={address1}
                  onChange={e => setAddress1(e.target.value)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                />
              </div>
              <div className="field col-12 col-md-4">
                <p>{l("field-address2", lang)}</p>
                <input
                  className=""
                  value={address2}
                  onChange={e => setAddress2(e.target.value)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                />
              </div>
            </div>
            <div className="row">
              <div className="field col-12 col-md-8">
                <p>{l("field-city", lang)}</p>
                <input
                  className=""
                  value={city}
                  onChange={e => setCity(e.target.value)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                />
              </div>
              <div className="field col-12 col-md-4">
                <p>{l("field-postcode", lang)}</p>
                <input
                  className=""
                  value={postcode}
                  onChange={e => setPostcode(e.target.value)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                />
              </div>
            </div>
            <div className="field">
              <p>{l("field-country", lang)}</p>
              <div className="input-group">
                <select
                  className="form-control"
                  value={id_country}
                  onChange={e => setCountry(e.target.value)}
                  onBlur={e => setCountry(e.target.value)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                >
                  <option value="" disabled>
                    &nbsp;
                  </option>
                  {store.cart.cart._embedded.countries.map(country => {
                    return (
                      <option
                        key={country.id_country}
                        value={country.id_country}
                      >
                        {country[`name_${lang.suffix}`]}
                      </option>
                    )
                  })}
                  {/* {store.cart.cart._embedded.countries
                  .map(({ node }) => node)
                  .filter(c => {
                    if (
                      +default_id_country > 0 &&
                      +c.id_country === +default_id_country
                    )
                      return true
                    else if (!default_id_country) return true
                    return false
                  })
                  .map(c => (
                    <option
                      value={c.id_country}
                      key={`country-${c.id_country}`}
                    >
                      {c[`name_${lang.suffix}`]}
                    </option>
                  ))} */}
                </select>
              </div>
            </div>

            {error && (
              <div className="alert alert-danger">
                {errorCode
                  ? l(errorCode, lang)
                  : l("error-checkout-guest", lang)}
              </div>
            )}

            <div className="d-flex justify-content-md-end">
              {showLoginButton && (
                <PageLink
                  to={pageURL("checkout", lang)}
                  className="btn btn-link mr-1"
                  disabled={disabled || readOnly}
                >
                  <i className="fas fa-chevron-left"></i>{" "}
                  {l("lostpassword-backtologin", lang)}
                </PageLink>
              )}
              <button
                className="btn-medium-secondary next-btn"
                onClick={saveInfo}
                disabled={disabled || readOnly}
              >
                {l("button-continue", lang)}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

CheckoutBlockGuest.propTypes = {
  lang: PropTypes.object,
  active: PropTypes.bool,
  index: PropTypes.number,
}

export default CheckoutBlockGuest

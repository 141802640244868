import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { context } from '../../../store'

const SKUGiftListSelector = ({ product, sku, lang, inline }) => {
  const [loading, setLoading] = useState(false)
  const [lists, setLists] = useState()
  const [selected, setSelected] = useState()
  const { store, appProvider, layoutProvider } = useContext(context)
  const { customer } = store && store.session
    ? store.session
    : { customer: null }
  const { disabled, giftlist_adding } = store && store.app
    ? store.app
    : { disabled: false, giftlist_adding: false }

  useEffect(() => {
    const loadItems = async () => {
      if (process.env.GATSBY_FEATURE_GIFTLIST && customer && !customer.is_guest) {
        setLoading(true)
        appProvider.myGiftlists().then(result => {
          setLists(result)
          setLoading(false)
        })
      }
    }

    loadItems()
  }, [appProvider])

  const handleAddToGiftList = async (e) => {
    if (e) e.preventDefault()
    if (selected) {
      const { item, error } = await appProvider.addToGiftlist(selected, sku)
      if (error) {
        layoutProvider.showGiftlistResult({
          event: 'error',
          error: 'addItem'
        })
      } else if (item) {
        layoutProvider.showGiftlistResult({
          event: 'success',
          item: item,
          id_giftlist: selected
        })
      } else {
        layoutProvider.showGiftlistResult({
          event: 'success',
          item: null,
          id_giftlist: selected
        })
      }
    }
  }

  if (!process.env.GATSBY_FEATURE_GIFTLIST || !customer || customer.is_guest) {
    return ''
  }
  else if (product.is_voucher || (product.is_combination && !sku)) {
    return ''
  } else if (loading) {
    return ''
  } else if (lists) {
    const isAdding = giftlist_adding && giftlist_adding === sku.id_sku

    return (
      <div className="row">
        <div className="col-12">
          <div className={`${inline ? '' : 'card'}`}>
            <div className={`${inline ? 'card-body' : ''}`}>
              <form onSubmit={handleAddToGiftList}>
                <div className="input-group">
                  <select className="form-control" onBlur={e => setSelected(e.target.value)} onChange={e => setSelected(e.target.value)}>
                    <option value="">kies uw lijst</option>
                    {lists._embedded.giftlists.map(list => (
                      <option value={list.id_giftlist} key={`select-${list.id_giftlist}`}>{list.name}</option>
                    ))}
                  </select>
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="submit" disabled={disabled || !selected}>
                      <i className={`fas ${isAdding ? 'fa-spin fa-spinner' : 'fa-plus'}`}></i> toevoegen aan mijn lijst
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return ''
  }
}

SKUGiftListSelector.propTypes = {
  product: PropTypes.object,
  sku: PropTypes.object,
  lang: PropTypes.object,
  inline: PropTypes.bool
}

export default SKUGiftListSelector

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { context } from '../../../store'
// import { t } from '../../../lib/locale'

const CheckoutButton = ({ children, lang, onClick, disabled}) => {
  const { store, cartProvider } = useContext(context)
  const { disabled: cartDisabled } = store && store.cart
    ? store.cart
    : { disabled: false }

  const initCheckout = async (e) => {
    if (e) e.preventDefault()
    cartProvider.initCheckout(lang, navigate)
    onClick()
  }

  return (
    <button onClick={initCheckout} className="btn-medium-secondary" disabled={disabled || cartDisabled}>
      {children}
    </button>
  )
}

CheckoutButton.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default CheckoutButton

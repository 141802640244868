import React from 'react'
import PropTypes from 'prop-types'
import { l, t } from '../../../lib/locale'
import { isOutOfStockAllowed } from '../../../lib/product'

const SKUAvailabilityLabel = ({ sku, lang }) => {
  const out_of_stock_allowed = isOutOfStockAllowed(+sku.out_of_stock_allowed)

  if (sku.stock_quantity > 0 && sku.quantity <= sku.stock_quantity) {
    return (
      <div className="product-detail-right-extra-info-item mt-1">
        <span className="product-detail-right-extra-info-success"></span>
        <p>{l('lbl-delivery-now', lang)}</p>
      </div>
    )
  } else if (out_of_stock_allowed) {
    return (
      <div className="product-detail-right-extra-info-item mt-1">
        <span className="product-detail-right-extra-info-warning"></span>
        <p>{l('lbl-delivery-later', lang)}</p>
      </div>
    )
  } else {
    return (
      <div className="product-detail-right-extra-info-item mt-1">
        <span className="product-detail-right-extra-info-warning"></span>
        <p>{l('lbl-delivery-later', lang)}</p>
      </div>
    )
  }
}

SKUAvailabilityLabel.propTypes = {
  sku: PropTypes.object,
  lang: PropTypes.object
}

export default SKUAvailabilityLabel

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { PageLayout, SessionLoginPage } from '../..'
import { context } from '../../../store'

const AccountLayout = (props) => {
  const { store } = useContext(context)
  const { customer } = store && store.session
    ? store.session
    : { customer: null }

  if (customer && customer.id_customer && !customer.is_guest) {
    return (
      <PageLayout className="account" {...props}>
        {props.children}
      </PageLayout>
    )
  } else {
    return (
      <SessionLoginPage lang={props.lang} switcher={props.switcher} />
    )
  }
}

AccountLayout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.object,
  switcher: PropTypes.object,
  className: PropTypes.string
}

export default AccountLayout

import React from 'react'
import PropTypes from 'prop-types'
import { l } from '../../../lib/locale'

const WishlistAddSKUButton = ({ sku, lang, className, invalid }) => {
  if (process.env.GATSBY_FEATURE_WISHLIST) {
    return (
      <button className="btn-add-to-wishlist">
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.613 6.25444C24.3031 4.53881 23.4094 2.69596 21.1556 1.79411L21.0366 1.74682C20.117 1.3937 19.1306 1.24888 18.1483 1.3228C16.0658 1.47773 14.2083 2.70086 13.0716 4.45237L12.9998 4.56164L12.928 4.45237C11.7865 2.70086 9.93384 1.47773 7.85126 1.3228C6.86902 1.24888 5.88261 1.3937 4.96306 1.74682L4.84401 1.79411C2.5902 2.69596 1.6965 4.53881 1.38664 6.25444C0.965886 8.58001 1.59865 10.961 2.95061 12.8985C5.88611 17.1207 11.7848 22.0703 12.8987 22.9949C12.9253 23.017 12.9588 23.029 12.9933 23.029C13.0278 23.029 13.0613 23.017 13.0879 22.9949C14.2083 22.0703 20.1005 17.1239 23.036 12.8985C24.401 10.961 25.0337 8.58001 24.613 6.25444Z" stroke="#7C8486" strokeWidth="2" strokeMiterlimit="10"></path>
        </svg>
        <p>{l('button-addtowishlist', lang)}</p>
      </button>
    )
  }
  return ''
}

WishlistAddSKUButton.propTypes = {
  sku: PropTypes.object,
  lang: PropTypes.object,
  className: PropTypes.string,
  invalid: PropTypes.bool
}

export default WishlistAddSKUButton

import React from 'react'
import PropTypes from 'prop-types'
import { l } from '../../../lib/locale'

const AccountAddressDeleteSideBar = ({ close, store, data, lang, provider }) => {
  const { disabled } = store && store.cart
    ? store.cart
    : { disabled: false }
  const a = data

  const confirm = async (e) => {
    e.preventDefault()
    close(e)
    await provider.deleteAddress(data)
  }



  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h2>{l('address-delete', lang)}</h2>
        </div>
        <button onClick={close} className="btn btn-link clickable">
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div>
        <p>{l('address-delete-confirm', lang)}</p>
        <p>{a.address1}{a.address2 ? ` ${a.address2}` : ''}{a.postcode ? ` ${a.postcode}` : ''}{a.city ? ` ${a.city}` : ''}</p>
      </div>
      <div className="un-modal-buttons d-flex justify-content-between align-items-center">
        <button className="btn-medium-light" onClick={close} disabled={disabled}>
          {l('button-cancel', lang)}
        </button>
        <button className="btn-medium-secondary" onClick={confirm} disabled={disabled}>
          {l('button-delete', lang)}
        </button>
      </div>
    </div>
  )
}

AccountAddressDeleteSideBar.propTypes = {
  close: PropTypes.func,
  store: PropTypes.object,
  data: PropTypes.object,
  lang: PropTypes.object,
  provider: PropTypes.object
}

export default AccountAddressDeleteSideBar

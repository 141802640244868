const formatAddress = (address, lang) => {
  const parts = []

  const country = (address._embedded && address._embedded.countries)
    ? address._embedded.countries.find(i => +i.id_country === +address.id_country)
    : null

  if (address.firstname) parts.push(address.firstname)
  if (address.lastname) parts.push(address.lastname)
  if (address.address1) parts.push(address.address1)
  if (address.address2) parts.push(address.address2)
  if (address.postcode) parts.push(address.postcode)
  if (address.city) parts.push(address.city)
  if (country && lang) parts.push(country[`name_${lang.suffix}`])

  return parts.join(' ')
}

const formatAddressOption = (address) => {
  const parts = []
  if (address.company) parts.push(address.company)
  if (address.vat_number) parts.push(address.vat_number)
  if (address.firstname) parts.push(address.firstname)
  if (address.lastname) parts.push(address.lastname)
  if (address.address1) parts.push(address.address1)
  if (address.address2) parts.push(address.address2)
  if (address.postcode) parts.push(address.postcode)
  if (address.city) parts.push(address.city)
  return parts.join(' ')
}

module.exports = {
  formatAddress,
  formatAddressOption
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AccountAddressForm } from '../..'
import { l } from '../../../lib/locale'

const AccountAddressSideBar = ({ close, store, data, lang, provider, id_country, id_carrier, refresh }) => {
  const [refreshing, setRefreshing] = useState(false)

  const cancel = async (address) => {
    setRefreshing(true)
    if (provider && address && address.id_address && id_carrier) {
      const opts = {
        id_carrier: id_carrier,
        id_address_delivery: address.id_address,
        service_point: null
      }
      await provider.updateCarrier(store.cart.cart, opts, lang);
    }
    await provider.getCheckout()
    if (refresh) refresh(address)
    setRefreshing(false)
    close()
  }

  return (
    <div className="d-flex flex-column">
      <div className="">
        {refreshing ? (
          <p>{l('loading', lang)}</p>
        ) : (
          <AccountAddressForm lang={lang} cancel={cancel} default_id_country={id_country} />
        )}
      </div>
    </div>
  )
}

AccountAddressSideBar.propTypes = {
  close: PropTypes.func,
  store: PropTypes.object,
  data: PropTypes.object,
  lang: PropTypes.object,
  provider: PropTypes.object
}

export default AccountAddressSideBar

import React from 'react'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import { t } from '../../../lib/locale'

const { pages } = require('../../../config/pages.json')

const PageBreadcrumb = ({ current, items, lang }) => {
  if (current) {
    return (
      <div className="breadcrumbs">
        {items ? items.map((i,idx) => {
          if (typeof i === 'object') {
            return (
              <React.Fragment key={`breadcrumb-${idx}`}>
                <PageLink to={t(i, 'path', lang)} className="clickable">{t(i, 'name', lang)}</PageLink>
                <i className="fas fa-chevron-right mx-2"></i>
              </React.Fragment>
            )
          } else {
            const page = pages.find(j => j.id === i)
            return (
              <React.Fragment key={`breadcrumb-${idx}`}>
                <PageLink to={t(page, 'path', lang)} className="clickable">{t(page, 'title', lang)}</PageLink>
                <i className="fas fa-chevron-right mx-2"></i>
              </React.Fragment>
            )
          }
        }) : null}
        <PageLink to={t(current, 'path', lang)} className="clickable">{t(current, 'title', lang)}</PageLink>
      </div>
    )
  }
  return ''
}

PageBreadcrumb.propTypes = {
  current: PropTypes.object,
  items: PropTypes.array,
  lang: PropTypes.object
}

export default PageBreadcrumb

import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { context } from '../../../store'
import { l } from '../../../lib/locale'
import { pageURL } from '../../../lib/page'
import { PageLink } from '../..'

const AccountLostPasswordForm = ({ lang, id_customer, token, reset_token }) => {
  const { store, sessionProvider } = useContext(context)
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  const { disabled } = store && store.session
    ? store.session
    : { disabled: false }

  const save = async (e) => {
    if (e) e.preventDefault()

    setSuccess(false)
    await sessionProvider.resetPassword(email, lang)
    setSuccess(true)
  }

  const update = async (e) => {
    if (e) e.preventDefault()

    setSuccess(false)
    if (password === password2) {
      await sessionProvider.setPassword(id_customer, token, reset_token, password, () => {
        navigate(pageURL('my-account', lang))
      })
    }
  }

  const updateEmail = (e) => setEmail(e.target.value);
  const updatePassword = (e) => setPassword(e.target.value);
  const updatePassword2 = (e) => setPassword2(e.target.value);

  if (id_customer && token && reset_token) {
    return (
      <div className="completed container">
        <div className="row">
          <div className="col-12">
            <h2>{l('lostpassword-title', lang)}</h2>
            <p>{l('lostpassword-description', lang)}</p>
          </div>

            {success ? (
              <div className="col-12 col-lg-8 offset-lg-2">
                <div className="alert alert-success">
                  {l('lostpassword-msg-updated', lang)}
                </div>
              </div>
            ) : (
              <>
                <div className="col-12 col-lg-8 offset-lg-2">
                  <form onSubmit={update}>
                    <div className="form-group">
                      <label htmlFor="password">{l('field-password', lang)}</label>
                      <input type="password" name="password" id="password" className="form-control" placeholder={l('field-password', lang)} value={password} onChange={updatePassword} disabled={disabled} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password2">{l('field-password', lang)}</label>
                      <input type="password" name="password2" id="password2" className="form-control" placeholder={l('field-password', lang)} value={password2} onChange={updatePassword2} disabled={disabled} />
                    </div>

                    <button className="btn btn-secondary mt-3" type="submit" disabled={disabled}>
                      {l('button-save-changes', lang)} <i className="fas fa-chevron-right"></i>
                    </button>
                  </form>
                </div>
              </>
            )}
        </div>
      </div>
    )
  } else {
    return (
      <div className="completed container">
        <div className="row">
          <div className="col-12">
            <h2>{l('lostpassword-title', lang)}</h2>
            <p>{l('lostpassword-description', lang)}</p>
          </div>

            {success ? (
              <div className="col-12 col-lg-8 offset-lg-2">
                <div className="alert alert-success">
                  {l('lostpassword-msg-reset', lang)}
                </div>
              </div>
            ) : (
              <>
                <div className="col-12 col-lg-8 offset-lg-2">
                  <form onSubmit={save}>
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder={l('field-email', lang)} value={email} onChange={updateEmail} />
                      <div className="input-group-append">
                        <button className="btn btn-secondary" type="submit">
                          {l('lostpassword-button', lang)} <i className="fas fa-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-12 col-lg-8 offset-lg-2 mt-3">
                  <PageLink to={'/'} className="btn btn-link">
                    <i className="fas fa-chevron-left"></i> {l('lostpassword-backtologin', lang)}
                  </PageLink>
                </div>
              </>
            )}
        </div>
      </div>
    )
  }
}

AccountLostPasswordForm.propTypes = {
  lang: PropTypes.object,
  token: PropTypes.string,
  reset_token: PropTypes.string,
  id_customer: PropTypes.number
}

export default AccountLostPasswordForm

import React from 'react'
import PropTypes from 'prop-types'
import { PageLayout, PageLink } from '../..'
import { t } from '../../../lib/locale'
import { page } from '../../../lib/page'
import Image from './image'

import './publiclayout.scss'

const PagePublicLayout = ({ children, className, lang, switcher, title }) => {
  return (
    <PageLayout className={`page login ${className ? className : ''}`} lang={lang} switcher={switcher} fullwidth={false} hideHeaderFooter={true}>
      <div className="wrapper d-flex">
  			<div className="main d-flex flex-column flex-md-row justify-content-around">
          <div className="banner">
            <div className="d-none d-md-block">
              <Image src="loginbanner.png" alt="Up North" />
            </div>
          	<div className="overlay">
              <PageLink to={t(page('homepage'), 'path', lang)}>
                <Image src="logo-white.png" className="logo-white" alt="Up North" />
                <Image src="logo-blue.png" className="logo-blue" alt="Up North" />
              </PageLink>
          		<h2>{title}</h2>
          	</div>
          </div>

          {children}
        </div>
      </div>
    </PageLayout>
  )
}

PagePublicLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  lang: PropTypes.object,
  switcher: PropTypes.object
}

export default PagePublicLayout

import React from 'react'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import { l } from '../../../lib/locale'
import { pageURL } from '../../../lib/page'

import './testimonials.scss'

const ContentBlockTestimonials = ({ lang }) => {
  return (
    <div className="testimonials text-center margin-bottom">
      <div className="container">
        <h3>{l('block-testimonials-title', lang)}</h3>
        <div className="row">
          <div className="col-md-4 testimonials-col-item">
            <div className="testimonials-col-item-image">
              <i className="fas fa-lg fa-star"></i>
              <i className="fas fa-lg fa-star"></i>
              <i className="fas fa-lg fa-star"></i>
              <i className="fas fa-lg fa-star"></i>
              <i className="fas fa-lg fa-star"></i>
            </div>
            <span>{l('block-testimonials-one-title', lang)}</span>
            <p>{l('block-testimonials-one-description', lang)}</p>
          </div>
          <div className="col-md-4 testimonials-col-item">
            <div className="testimonials-col-item-image">
              <i className="fas fa-lg fa-star"></i>
              <i className="fas fa-lg fa-star"></i>
              <i className="fas fa-lg fa-star"></i>
              <i className="fas fa-lg fa-star"></i>
              <i className="fas fa-lg fa-star"></i>
            </div>
            <span>{l('block-testimonials-two-title', lang)}</span>
            <p>{l('block-testimonials-two-description', lang)}</p>
          </div>
          <div className="col-md-4 testimonials-col-item">
            <div className="testimonials-col-item-image">
              <i className="fas fa-lg fa-star"></i>
              <i className="fas fa-lg fa-star"></i>
              <i className="fas fa-lg fa-star"></i>
              <i className="fas fa-lg fa-star"></i>
              <i className="fas fa-lg fa-star"></i>
            </div>
            <span>{l('block-testimonials-three-title', lang)}</span>
            <p>{l('block-testimonials-three-description', lang)}</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 text-center">
            <PageLink to={pageURL('customer-reviews', lang)} className="btn-medium-secondary">
              {l('button-readmore', lang)} <i className={`fas fa-chevron-right ml-1`}></i>
            </PageLink>
          </div>
        </div>
      </div>
    </div>
  )
}

ContentBlockTestimonials.propTypes = {
  lang: PropTypes.object
}

export default ContentBlockTestimonials

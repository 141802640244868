import React from 'react'
import PropTypes from 'prop-types'

import './assets/custom.scss'

const PageTheme = ({ children }) => {
  return <>{children}</>
}

PageTheme.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageTheme

import React from 'react'
import PropTypes from 'prop-types'
import SKUSelectorCombination from './view_combination'
import SKUSelectorSingle from './view_single'
import SKUSelectorVoucher from './view_voucher'

const SKUSelector = (props) => {
  if (props.product.is_voucher) {
    return <SKUSelectorVoucher {...props} />
  } else if (props.product.is_combination) {
    return <SKUSelectorCombination {...props} />
  } else {
    return <SKUSelectorSingle {...props} />
  }
}

SKUSelector.propTypes = {
  product: PropTypes.object,
  sku: PropTypes.object,
  attributes: PropTypes.array,
  attribute_groups: PropTypes.array,
  lang: PropTypes.object,
  onChange: PropTypes.func,
  giftlist: PropTypes.object
}

export default SKUSelector

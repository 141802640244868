import React from 'react'
import PropTypes from 'prop-types'
import { PagePublicLayout, PageLink, SessionLoginForm } from '../..'
import { page } from '../../../lib/page'
import { t, l } from '../../../lib/locale'

const SessionLoginPage = ({ lang, switcher }) => {
  const onLogin = async () => {
  }

  return (
    <PagePublicLayout className="page login" lang={lang} switcher={switcher} title={l('login-introduction', lang)}>
      <div className="content d-flex flex-column justify-content-between login-right">
        <SessionLoginForm lang={lang} onSuccess={onLogin} showLinks={true} />

        <div className="create-account d-md-flex justify-content-md-end align-items-md-center">
          <p>{l('login-no-account-yet', lang)}</p>
          <PageLink to={t(page('createaccount'), 'path', lang)} className="btn-medium-light no-account-btn">
            {l('button-create-account', lang)}
          </PageLink>
        </div>
      </div>
    </PagePublicLayout>
  )
}

SessionLoginPage.propTypes = {
  lang: PropTypes.object,
  switcher: PropTypes.object
}

export default SessionLoginPage

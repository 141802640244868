import React from 'react'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import { l } from '../../../lib/locale'
import { pageURL } from '../../../lib/page'
import Image from './image'

import './banner.scss'

const ContentBlockBanner = ({ lang }) => {
  return (
    <>
      <div className="container banner">
        <h2 className="title underline mt-3 mb-4 d-inline-block">{l('giftlist-banner-title', lang)}</h2>

        <div className="row">
          <div className="col-12">
            <Image src="promobanner_bg.png" alt={l('block-banner-title', lang)} />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-8 pt-3">
            <h2>{l('block-banner-title', lang)}</h2>
            <p>{l('block-banner-paragraph', lang)}</p>
          </div>
          <div className="col-md-4 text-right">
            <PageLink to={pageURL('giftlist-home', lang)} className="btn-large-secondary d-inline-block">
              {l('block-banner-button', lang)} <i className={`fas fa-chevron-right ml-1`}></i>
            </PageLink>
          </div>
        </div>
      </div>
    </>
  )
}

ContentBlockBanner.propTypes = {
  lang: PropTypes.object
}

export default ContentBlockBanner

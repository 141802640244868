import React from 'react'
import PropTypes from 'prop-types'
import { PageLink, SKUPromoLabel } from '../..'
import { productURL } from '../../../lib/product'
import { skuURL } from '../../../lib/sku'
import { l } from '../../../lib/locale'
import ProductListTileImage from './image'

const ProductListTileViewSingle = ({ product, sku, lang, giftlist, cartOnly, navigation, className, add2cart, add2giftlist, disabled, adding, inView, showSKU, customer, cartrules, promo }) => {
  let url = giftlist
    ? `?id=${sku.id_giftlist}&item=${sku.id_giftlist_product}`
    : showSKU
      ? skuURL(sku, lang)
      : productURL(sku, lang)

  const product_cart_rules = (cartrules && !giftlist)
    ? cartrules.filter(i =>
      (product.available_for_order && i.rule_group_type === 'products' && i.items && i.items.includes(+product.id_product) && i.rule_group_quantity <= 2)
      || (product.available_for_order && i.rule_group_type === 'manufacturers' && i.items && i.items.includes(+product.id_manufacturer))
    )
    : null

  const product_cart_rule = product_cart_rules ? product_cart_rules[0] : null

  let price = sku.sellPrice
  let product_cart_rule_applied = false
  if (giftlist) {
    if (+product.reduction_percent > 0) {
      promo = {
        reduction_type: "percentage",
        reduction: product.reduction_percent/100
      }
    } else if (+product.reduction_amount > 0) {
      promo = {
        reduction_type: "amount",
        reduction: product.reduction_amount
      }
    }
  }
  if (promo) {
    if (promo.reduction_type === "percentage") price = price * (1 - promo.reduction)
    if (promo.reduction_type === "amount") price = price - promo.reduction
  } else {
    if (product_cart_rule && product_cart_rule.rule_group_quantity === 1) {
      if (+product_cart_rule.reduction_percent > 0) {
        price = price * (1 - product_cart_rule.reduction_percent/100)
        product_cart_rule_applied = true
      }
    }
  }

  return (
    <PageLink to={url}>
      <div className="product-photo single">
        <div className="overlay">
          <div className="actions d-flex justify-content-center align-items-center">
            <button className="btn btn-primary btn-product-action clickable" disabled={!sku._links.add_to_cart || disabled} onClick={add2cart}>
              <i className={`fas ${adding === sku.id_sku ? 'fa-spin fa-spinner' : 'fa-cart-plus'}`}></i>
            </button>
            {!cartOnly && !giftlist && process.env.GATSBY_FEATURE_GIFTLIST && customer && (
              <button className="btn btn-primary btn-product-action clickable" disabled={disabled} onClick={add2giftlist}>
                <i className={`fas ${adding === sku.id_sku ? 'fa-spin fa-spinner' : 'fa-gift'}`}></i>
              </button>
            )}
            {!cartOnly && !giftlist && process.env.GATSBY_FEATURE_WISHLIST && (
              <button className="btn btn-primary btn-product-action clickable" disabled={disabled}>
                <i className={`fas fa-heart`}></i>
              </button>
            )}
          </div>
        </div>

        {cartOnly ? '' : giftlist ? (
          <div className="product-labels">
            <span className="product-labels-promo mr-1">{l(`field-giftlist-priority-${product.priority}`, lang)}</span>
            {promo && promo.reduction_type === "percentage" ? <span className="product-labels-promo mr-1">-{Number(promo.reduction * 100).toFixed(0)}%</span> : ''}
            {promo && promo.reduction_type === "amount" ? <span className="product-labels-promo mr-1">-{Number(promo.reduction).toFixed(2)}€</span> : ''}
          </div>
        ) : (
          <div className="product-labels">
            {product_cart_rules && product_cart_rules.map(cr => <SKUPromoLabel key={`product-cart-rule-${cr.id_cart_rule}`} cartrule={cr} product={product} sku={sku} lang={lang} />)}
            {!sku._links.add_to_cart ? <span className="product-labels-promo mr-1">OUT OF STOCK</span> : ''}
            {sku._links.add_to_cart && sku.new ? <span className="product-labels-new mr-1">NIEUW</span> : ''}
          </div>
        )}

        <ProductListTileImage product={product} sku={sku} lang={lang} inView={inView} />
      </div>
      <div>
        <div className="brand">
          <span>{sku.manufacturer_hidden ? " " : sku.manufacturer_name}</span>
          {promo || product_cart_rule_applied ? (
            <span className="float-right mr-1">
              <strike>&euro; {Number(sku.sellPrice).toFixed(2)}</strike>
            </span>
          ) : ''}
        </div>
        <div className="name-price row no-gutters">
          <div className="col-8 name">
            <h3>{sku[`name_${lang.suffix}`]}{sku.id_product_attribute ? `: ${sku[`combination_${lang.suffix}`]}` : ''}</h3>
          </div>
          <div className="col-4 price">
            <h3>&euro; {Number(price).toFixed(2)}</h3>
          </div>
        </div>
      </div>
    </PageLink>
  )
}

ProductListTileViewSingle.propTypes = {
  product: PropTypes.object.isRequired,
  sku: PropTypes.object,
  lang: PropTypes.object,
  giftlist: PropTypes.bool,
  cartOnly: PropTypes.bool,
  navigation: PropTypes.object,
  className: PropTypes.string,
  add2cart: PropTypes.func,
  add2giftlist: PropTypes.func,
  disabled: PropTypes.bool,
  adding: PropTypes.any,
  inView: PropTypes.bool
}

export default ProductListTileViewSingle

import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'

require('intersection-observer')

const InstagramBlock = ({ title, items, lang }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  // @todo use gatsby image ?

  return (
    <div className="instagram-block container mb-5">
      <h4 className="title underline mb-3 d-inline-block">{title}</h4>

      <div className="row no-gutters" ref={ref}>
        {inView && (
          <>
            <div className="col-12 col-md-3 d-none d-md-block">
              <div className="row no-gutters">
                <div className="col-12 p-1">
                  <img className="img-fluid w-100" src={items[1].thumbnails[1].src} alt={items[0].caption} />
                </div>
                <div className="col-12 p-1">
                  <img className="img-fluid w-100" src={items[2].thumbnails[1].src} alt={items[0].caption} />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-1">
              <img className="img-fluid w-100" src={items[0].thumbnails[3].src} alt={items[0].caption} />
            </div>
            <div className="col-12 col-md-3 d-none d-md-block">
              <div className="row no-gutters">
                <div className="col-12 p-1">
                  <img className="img-fluid w-100" src={items[3].thumbnails[1].src} alt={items[0].caption} />
                </div>
                <div className="col-12 p-1">
                  <img className="img-fluid w-100" src={items[4].thumbnails[1].src} alt={items[0].caption} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

InstagramBlock.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  lang: PropTypes.object
}

export default InstagramBlock

import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../../../lib/locale'
import { productURL, productBrandURL } from '../../../lib/product'

const ProductStructuredData = ({ lang, product }) => {
  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": t(product, 'name', lang),
    "image": product._embedded && product._embedded.images
      ? product._embedded.images.map(({ id_image }) => `${process.env.GATSBY_CDN_URL}/p/${id_image.toString().split('').join('/')}/${id_image}-large_default.jpg`)
      : [],
    "description": t(product, 'description_short', lang) ? t(product, 'description_short', lang).replace(/<[^>]*>?/gm, '') : t(product, 'name', lang),
    "productID": product.id_product,
    "sku": `${product.id_product}-${product.id_product_attribute}`,
    "gtin13": product.ean13 ? product.ean13 : "",
    "offers": {
      "@type": "Offer",
      "url": `${process.env.GATSBY_SITE_URL}${productURL(product, lang)}`,
      "priceCurrency": "EUR",
      "price": Number(product.sellPrice).toFixed(2),
      // "priceValidUntil": "2020-11-05", // @todo
      "itemCondition": "https://schema.org/NewCondition",
      "availability": product.isAvailableNow // @todo add more values, see https://schema.org/ItemAvailability
        ? "https://schema.org/InStock"
        : product.isAvailableLater
          ? "https://schema.org/PreOrder"
          : "https://schema.org/OutOfStock",
      "seller": {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Up North",
        "url": process.env.GATSBY_SITE_URL,
      }
    }
  }

  if (!product.manufacturer_hidden) {
    structuredData["brand"] = {
      "@context": "https://schema.org",
      "@type": "Brand",
      "name": product.manufacturer_name,
      "url": `${process.env.GATSBY_SITE_URL}${productBrandURL(product, lang)}`,
      "logo": `${process.env.GATSBY_CDN_URL}/m/${product.id_manufacturer}.jpg`
    };
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
  )
}

ProductStructuredData.propTypes = {
  lang: PropTypes.object,
  product: PropTypes.object
}

export default ProductStructuredData

import React from 'react'
import PropTypes from 'prop-types'
// import { PageLink } from '../..'

const BrandSKUListHeader = ({ brand, lang, navigation }) => {
  if (navigation && navigation.page === 0) {
    const description = brand[`description_${lang.suffix}`]

    if (description) {
      return (
        <div className="row my-1">
          <div className="col-12">
            <h3>Over {brand.name}</h3>
            {brand[`description_${lang.suffix}`]}
          </div>
        </div>
      )
    }
  }
  return ''
}

BrandSKUListHeader.propTypes = {
  brand: PropTypes.object,
  lang: PropTypes.object,
  navigation: PropTypes.object
}

export default BrandSKUListHeader

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { context } from '../../../store'
// import { CartSideBar, AccountAddressDeleteSideBar, CartCountrySelectorSideBar } from '../..'

const PageSideBarLeft = ({ lang }) => {
  const { store, layoutProvider } = useContext(context)
  const { showsidebarleft } = store && store.layout
    ? store.layout
    : { showsidebarleft: false }

    const close = (e) => {
      if (e) e.preventDefault()
      layoutProvider.close()
    }

  if (showsidebarleft) {
    return (
      <div className="un-modal un-modal-left" >
        <div className="d-flex">
          <button className="un-modal-dark-part" onClick={close}>x</button>
          <div className="un-modal-content">

          </div>
        </div>
      </div>
    )
  } else {
    return ''
  }
}

PageSideBarLeft.propTypes = {
  lang: PropTypes.object
}

export default PageSideBarLeft

import React, { useState, useContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
import { PageLink } from '../..'
import { context } from '../../../store'
import { t, l } from '../../../lib/locale'
import callAPI from '../../../lib/callAPI'

const AccountGiftListItemsListTile = ({ item, appProvider, store, lang, refresh }) => {
  const [deleting, setDeleting] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [priority, setPriority] = useState(item.priority)

  const removeItem = async (e) => {
    if (e) e.preventDefault()
    setDeleting(true)
    await appProvider.removeGiftlistItem(item)
    await refresh()
    setDeleting(false)
  }

  const changePriority = async (e) => {
    if (e) e.preventDefault()
    setPriority(e.target.value)
    item.priority = +e.target.value
    await appProvider.updateGiftlistItem(item)
    await refresh()
  }

  const { disabled } = store

  return (
    <div className="col-sm-6 col-md-4 mb-2">
      <div className="card">
        <div className="card-header">
          <span className="badge badge-secondary mr-1">{item.quantity}</span>
          {t(item, 'name', lang)} <b>&euro;{Number(item.sellPrice).toFixed(2)}</b>
          {+item.reduction_percent > 0 ? <span className="badge badge-secondary ml-1">-{item.reduction_percent}%</span> : ''}
          {+item.reduction_amount > 0 ? <span className="badge badge-secondary ml-1">-{item.reduction_amount}€</span> : ''}
        </div>
        {item.id_default_image && (
          <img
            className="card-img-top"
            src={`${process.env.GATSBY_CDN_URL}/p/${item.id_default_image.toString().split('').join('/')}/${item.id_default_image}-medium_default.jpg`}
            alt={t(item, 'name', lang)} />
        )}
        <div className="card-body">
          <form>
            <select className="form-control" value={priority} onChange={changePriority} onBlur={changePriority} disabled={disabled}>
              <option value="0">{l(`field-giftlist-priority-0`, lang)}</option>
              <option value="1">{l(`field-giftlist-priority-1`, lang)}</option>
              <option value="2">{l(`field-giftlist-priority-2`, lang)}</option>
              <option value="3">{l(`field-giftlist-priority-3`, lang)}</option>
              <option value="4">{l(`field-giftlist-priority-4`, lang)}</option>
            </select>
          </form>
        </div>
        <div className="card-footer text-right">
          {item && item._links && item._links.remove ? (
            <button className="btn btn-outline-danger" disabled={disabled} onClick={removeItem}>
              <i className={`fas ${deleting ? 'fa-spin fa-spinner' : 'fa-trash-alt'}`}></i>
            </button>
          ) : ''}
        </div>
      </div>
    </div>
  )
}

const AccountGiftListItemsList = ({ giftlist, lang, refresh }) => {
  const { appProvider, store } = useContext(context)

  const items = giftlist && giftlist._embedded && giftlist._embedded.items
    ? giftlist._embedded.items.filter(i => +i.quantity > 0).sort((a,b) => +b.id_giftlist_product - +a.id_giftlist_product)
    : null

  return (
    <>
      <div className="row mb-3">
        <div className="col-4">
          <PageLink to={`/nl/mijn-wenslijsten`} className="btn btn-link">
            <i className="fas fa-chevron-left"></i> Mijn wenslijsten
          </PageLink>
        </div>
        <div className="col-8">
          <h3>{giftlist.name}</h3>
        </div>
      </div>

      <div className="row giftlist-items">
        {items && items.length > 0 ? items.map(item => (
          <AccountGiftListItemsListTile
            refresh={refresh}
            item={item}
            appProvider={appProvider}
            store={store}
            lang={lang}
            key={`giftlist-item-${item.id_giftlist_product}`} />
        )) : (
          <div className="col-12">
            <div className="alert alert-info">
              Geen items op je wenslijst.
            </div>
          </div>
        )}
      </div>
    </>
  )
}

AccountGiftListItemsList.propTypes = {
  giftlist: PropTypes.object,
  lang: PropTypes.object,
  refresh: PropTypes.func
}

export default AccountGiftListItemsList

import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import { context } from '../../../store'
import { page } from '../../../lib/page'
import { t, l } from '../../../lib/locale'

const SessionLoginForm = ({ lang, showLinks, onSuccess }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState()

  const { store, sessionProvider, cartProvider } = useContext(context)
  const { login, disabled } = store && store.session
    ? store.session
    : { login: false, disabled: false }

  const updateEmail = (e) => {
    setEmail(e.target.value)
  }
  const updatePassword = (e) => {
    setPassword(e.target.value)
  }

  const onLogin = async (e) => {
    setError(null)
    e.preventDefault()
    sessionProvider.login(email, password).then(async result => {
      if (result && result.customer) {
        await cartProvider.refresh()
        onSuccess()
      }
    }).catch(e => {
      if (e.name === 'NotFoundError') {
        setError(l('error-login-failed', lang))
      }
    })
  }

  return (
    <form onSubmit={onLogin}>
      <div className="inputs">
        {error && (
          <div className="field">
            <div className="alert alert-danger">
              {error}
            </div>
          </div>
        )}
        <div className="field">
          <p>{l('field-email', lang)}</p>
          <input type="text" name="email" value={email} onChange={updateEmail} disabled={disabled} />
        </div>
        <div className="field">
          <p>{l('field-password', lang)}</p>
          <input type="password" name="password" value={password} onChange={updatePassword} className="password" disabled={disabled} />
        </div>
        {showLinks && (
          <div className="forgot-pw-link d-inline-block mr-3">
            <PageLink to={t(page('lostpassword'), 'path', lang)}>{l('login-link-passwordlost', lang)}</PageLink>
          </div>
        )}
        <button type="submit" className="btn-medium-secondary d-inline-block login-btn" disabled={disabled}>
          <i className={`fas ${login ? 'fa-spin fa-spinner' : 'fa-chevron-right'}`}></i> {l('button-login', lang)}
        </button>
      </div>
    </form>
  )
}

SessionLoginForm.propTypes = {
  lang: PropTypes.object,
  showLinks: PropTypes.bool,
  onSuccess: PropTypes.func
}

export default SessionLoginForm

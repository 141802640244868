import React from 'react'
import PropTypes from 'prop-types'
import { ProductListTile } from '../..'
import { l } from '../../../lib/locale'

const ProductCarousel = ({ lang, products, title }) => {
  const showNav = false // @todo finish implementation of carousel

  return (
    <div className="product-list">
      <div className="container product-list-home">
        <h2 className="title underline mb-3 d-block">{title}</h2>

        {showNav && (
          <button className="clickable btn btn-link product-list-arrow-left" title={l('list-previous', lang)}>
            <i className="fas fa-chevron-left"></i>
          </button>
        )}

        <div className="row">
          {products.sort((a,b) => b.id_product - a.id_product).slice(0,4).map(product => (
            <ProductListTile
              product={product}
              sku={product._embedded.skus.find(sku => +sku.id_product_attribute === +product.id_product_attribute)}
              lang={lang}
              className="product col-12 col-md-4 col-lg-3 clickable"
              key={`product-carousel-${product.id_product}`} />
          ))}
        </div>

        {showNav && (
          <button className="clickable btn btn-link product-list-arrow-right" title={l('list-next', lang)}>
            <i className="fas fa-chevron-right"></i>
          </button>
        )}
      </div>
    </div>
  )
}

ProductCarousel.propTypes = {
  lang: PropTypes.object
}

export default ProductCarousel

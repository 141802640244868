const normalize = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const categoryURL = (category, lang) => {
  if (lang.id_lang === 1) {
    return `/${lang.suffix}/products/${category.id_category}-${normalize(category[`link_rewrite_${lang.suffix}`].replace(' ', '-'))}`
  } else if (lang.id_lang === 4) {
    return `/${lang.suffix}/products/${category.id_category}-${normalize(category[`link_rewrite_${lang.suffix}`].replace(' ', '-'))}`
  }
}

module.exports = { categoryURL }

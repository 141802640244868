import React from 'react'
import PropTypes from 'prop-types'
import { PageLink, SKUGiftListSelector } from '../..'
import { l, t } from '../../../lib/locale'
import { page, pageURL } from '../../../lib/page'
import { skuURL } from '../../../lib/sku'

const GiftListSideBar = ({ close, store, cartProvider, layoutProvider, data, lang }) => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3>{l('giftlist', lang)}</h3>
        </div>
        <button onClick={close} className="btn btn-link clickable">
          <i className="fas fa-times fa-lg"></i>
        </button>
      </div>

      {data && data.event === 'error' && (
        <div className="alert alert-danger mt-3">
            <p>{l(`error-giftlist-${data.error}`, lang)}</p>
          {data && data.product && (
            <p>
              {l(`info-cart-${data.error}`, lang)}
            </p>
          )}
        </div>
      )}

      {data && data.event === 'addItem' && (
        <>
          <SKUGiftListSelector
            product={data && data.product ? data.product : null}
            sku={data && data.sku ? data.sku : null}
            id_lang={lang}
            inline={true} />
        </>
      )}

      {data && data.event === 'success' && (
        <>
          <div className="row">
            <div className="col-12">
              <div className="alert alert-success">
                <p>{l('msg-added-to-giftlist', lang)}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <PageLink to={`${pageURL('my-giftlist-items', lang)}?id=${data.id_giftlist}`} className="btn btn-secondary" onClick={close}>
                {l('giftlist-goto-items', lang)}
              </PageLink>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

GiftListSideBar.propTypes = {
  close: PropTypes.func,
  store: PropTypes.object,
  data: PropTypes.object,
  lang: PropTypes.object
}

export default GiftListSideBar

import React, { useState } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { PageLink } from "../.."
import { l } from "../../../lib/locale"

const { pages } = require("../../../config/pages.json")
const nextstep = pages.find(i => i.id === "checkout-step-payment")
const currentstep = pages.find(i => i.id === "checkout-step-giftwrapping")

const CheckoutBlockGiftWrapping = ({
  lang,
  active,
  index,
  store,
  cartProvider,
}) => {
  const { cart, disabled } =
    store && store.cart ? store.cart : { cart: null, disabled: false }

  const [saving, setSaving] = useState(false)
  const [gift, setGift] = useState(cart.gift)
  const [message, setMessage] = useState(cart.gift_message || "")

  const saveGiftWrapping = async e => {
    e.preventDefault()
    setSaving(true)
    // only set message when this is a gift
    const messageText = gift ? message : ""
    await cartProvider.updateGiftWrapping(cart, gift, messageText, lang)
    await cartProvider.getCheckout()
    setSaving(false)
    navigate(nextstep[`path_${lang.suffix}`])
  }

  return (
    <div className="content">
      <PageLink
        className="form-header d-flex align-items-center clickable"
        disabled={cart.step_shipping ? false : true}
        to={currentstep[`path_${lang.suffix}`]}
      >
        <h3>
          <span className="form-header-step">{index}</span>
          {l("checkout-giftwrapping", lang)}
        </h3>
      </PageLink>
      {active && (
        <form onSubmit={saveGiftWrapping}>
          <div className="form-content open" id="wrapping">
            <div className="inputs container">
              <div className="d-flex justify-content-between align-items-end">
                <div className="alert alert-danger w-100">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: l("checkout-giftwrapping-sales", lang),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="checkboxes">
              <div className="row mb-3">
                <div className="col-12">
                  <input
                    className="mr-1"
                    type="checkbox"
                    id="gift"
                    value="1"
                    checked={gift === 1}
                    onChange={e => setGift(e.target.checked ? 1 : 0)}
                  />
                  <label htmlFor="gift">
                    {l("checkout-giftwrapping-yes", lang)}
                  </label>
                </div>
              </div>
            </div>
            {gift ? (
              <div className="inputs container">
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    <p>{l("checkout-giftwrapping-message", lang)}</p>
                  </div>
                  <div>
                    <p className="optional">{l("Optional", lang)}</p>
                  </div>
                </div>
                <textarea
                  rows="7"
                  value={message}
                  onChange={e =>
                    setMessage(e.target.value.replace(/[^\x00-\x7F]/g, ""))
                  }
                ></textarea>
              </div>
            ) : null}
            <div className="d-flex justify-content-md-end">
              <button
                className="btn btn-medium-secondary next-btn"
                type="submit"
                disabled={disabled}
              >
                <i
                  className={`fas ${
                    saving ? "fa-spin fa-spinner" : "fa-chevron-right"
                  }`}
                ></i>{" "}
                {l("button-continue", lang)}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

CheckoutBlockGiftWrapping.propTypes = {
  lang: PropTypes.object,
  active: PropTypes.bool,
  index: PropTypes.number,
  store: PropTypes.object,
  cartProvider: PropTypes.object,
}

export default CheckoutBlockGiftWrapping

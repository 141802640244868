import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, navigate } from 'gatsby'
import { PageLink, SearchInlineForm, PageTopBanner } from '../..'
import { context } from '../../../store'
import { t, l } from '../../../lib/locale'
import ImageLogo from './imagelogo'

import './menu.scss'
import './mobile-menu.scss'

const { pages } = require('../../../config/pages.json')
const homePage = pages.find(i => i.id === 'homepage')
const cartPage = pages.find(i => i.id === 'cart')
const accountPage = pages.find(i => i.id === 'my-account')
const gitlistPage = pages.find(i => i.id === 'giftlist-home')

const { topmenu } = require('../../../config/menus.json')

const languages = [
  { id_lang: 1, suffix: 'en' },
  { id_lang: 4, suffix: 'nl' }
]

const IDX_INVISIBLE_MENU = -1

const PageHeader = ({ lang, switcher, disableSearch }) => {
  const { store, layoutProvider } = useContext(context)
  const { cart } = store && store.cart ? store.cart : { cart: null, disabled: false }

  const [openMenu, setMenuOpen] = useState(false)
  const [visibleSubMenuIdx, setVisibleSubMenuIdx] = useState(IDX_INVISIBLE_MENU)
  const [visibleMenuIdx, setVisibleMenuIdx] = useState(IDX_INVISIBLE_MENU)

  const cartCount = (cart && cart._embedded && cart._embedded.items && cart._embedded.items.length > 0)
    ? cart._embedded.items.map(i => +i.quantity).reduce((a,b) => +a + +b, 0)
    : 0

  const showCart = (e) => {
    e.preventDefault()
    layoutProvider.showCartResult()
  }

  const toggleMobileMenu = (e) => {
    e.preventDefault()
    if (openMenu) {
      setMenuOpen(false)
      closeMenu()
    } else {
      setMenuOpen(true)
    }
  }

  const showMenu = (e, i, idx) => {
    setVisibleMenuIdx(idx)
  }

  const closeMenu = (e) => {
    setVisibleMenuIdx(IDX_INVISIBLE_MENU)
    setVisibleSubMenuIdx(IDX_INVISIBLE_MENU)
  }

  const openMobileSubMenu = (e, idx, allShopCategory) => {
    if (openMenu) {
      if (allShopCategory.edges.find(({ node }) => +node.id_category === +idx)) {
        e.preventDefault()
        setVisibleSubMenuIdx(idx)
      }
    }
  }

  const backToRootMobileMenu = (e) => {
    e.preventDefault()
    setVisibleSubMenuIdx(IDX_INVISIBLE_MENU)
  }

  const onLanguageSelected = (e) => {
    const id_lang = e.target.value
    const l = languages.find(l => +l.id_lang === +id_lang)
    navigate(switcher[`path_${l.suffix}`])
  }

  const sortByPosition = (a,b) => {
    return +b.node.position - +a.node.position
  }

  return (
    <StaticQuery
      query={graphql`
        query PageHeaderQuery {
          allShopCategory {
            edges {
              node {
                ...CategoryItem
              }
            }
          }
        }
      `}
      render={({ allShopCategory }) => (
        <menu className={`${openMenu ? 'open' : ''}`}>
          <PageTopBanner lang={lang} switcher={switcher} />

          <div className={`main-menu d-flex flex-row justify-content-between ${openMenu ? 'mm-visible' : ''}`}>
            <div className="left d-flex flex-row">
              <button className="hamburger align-self-center btn btn-link px-1 mr-1" onClick={toggleMobileMenu}>
                <i className={`fas fa-lg ${openMenu ? 'fa-times' : 'fa-bars'}`}></i>
              </button>
              <div className="logo m-2">
                <PageLink to={homePage[`path_${lang.suffix}`]}>
                  <ImageLogo mobile={true} alt="Up North" />
                  <ImageLogo mobile={false} alt="Up North" />
                </PageLink>
              </div>
            </div>
            <div className="items d-flex flex-row justify-content-between align-items-center align-items-lg-end">
              {process.env.GATSBY_FEATURE_GIFTLIST && (
                <PageLink className="item" to={gitlistPage[`path_${lang.suffix}`]}>
                  <i className="fas fa-lg fa-gift mr-1"></i>
                  <p>{gitlistPage[`title_${lang.suffix}`]}</p>
                </PageLink>
              )}
              {process.env.GATSBY_FEATURE_WISHLIST && (
                <div className="item">
                  <i className="fas fa-lg fa-heart mr-1"></i>
                  <p>Wishlist</p>
                </div>
              )}
              <PageLink className="item" to={accountPage[`path_${lang.suffix}`]}>
                <i className="fas fa-lg fa-smile-wink mr-1"></i>
                <p>{accountPage[`title_${lang.suffix}`]}</p>
              </PageLink>
              <PageLink className="item" to={cartPage[`path_${lang.suffix}`]} onClick={showCart}>
                <div className="item-badge mr-2">
                  <span className="badge badge-light">{cartCount}</span>
                  <i className="fas fa-lg fa-shopping-bag"></i>
                </div>
                <p>{cartPage[`title_${lang.suffix}`]}</p>
              </PageLink>
            </div>
          </div>

          <SearchInlineForm lang={lang} disableInline={disableSearch} />

          <div className={`second-menu ${openMenu ? 'open' : 'closed'}`} onMouseLeave={closeMenu} role="menu" tabIndex={0}>
            <div id="secondmenu">
              <div className={`main mm-visible d-flex flex-column flex-md-row justify-content-md-between ${visibleSubMenuIdx > 0 ? 'mm-invisible' : 'mm-visible'}`}>
                {topmenu.map((i,idx) => {
                  if (i[`url_${lang.suffix}`]) {
                    return (
                      <a href={i[`url_${lang.suffix}`]} target="blank" className={`item d-flex justify-content-between ${i.id_category ? 'item-category' : ''} ${i.break ? 'item-break' : ''}`} key={`sub-menu-items-${idx}`} onClick={e => openMobileSubMenu(e, i.id_category, allShopCategory)} onMouseEnter={e => showMenu(e, i, idx)}>
                        <p>{i[`title_${lang.suffix}`]}</p>
                        <i className="fas fa-chevron-right ml-md-1 d-md-none"></i>
                      </a>
                    )
                  }

                  return (
                    <PageLink to={i[`path_${lang.suffix}`]} className={`item d-flex justify-content-between ${i.id_category ? 'item-category' : ''} ${i.break ? 'item-break' : ''}`} key={`sub-menu-items-${idx}`} onClick={e => openMobileSubMenu(e, i.id_category, allShopCategory)} onMouseEnter={e => showMenu(e, i, idx)}>
                      <p>{i[`title_${lang.suffix}`]}</p>
                      <i className="fas fa-chevron-right ml-md-1 d-md-none"></i>
                    </PageLink>
                  )
                })}
              </div>

              <div className={`third-menu ${visibleMenuIdx !== IDX_INVISIBLE_MENU ? 'visible': ''}`} id="thirdmenu">
                {topmenu.map((i,idx) => {
                  if (i.id_category && +i.id_category > 0) {
                    const children = allShopCategory.edges.filter(({ node }) => +node.id_parent === +i.id_category)

                    if (children && children.length > 0) {
                      return (
                        <div key={`third-menu-${idx}`} className={`main flex-column flex-md-row justify-content-md-between ${idx === visibleMenuIdx ? 'd-flex' : 'd-none'}`}>
                          {children.map(child => {
                            const subchildren = allShopCategory.edges.filter(({ node }) => +node.id_parent === +child.node.id_category)

                            return (
                              <div key={`third-child-${child.node.id_category}`} className="item d-flex justify-content-between">
                                <ul>
                                  <li>
                                    <PageLink className="third-menu-title" to={child.node[`path_${lang.suffix}`]}>
                                      {child.node[`name_${lang.suffix}`]}
                                    </PageLink>
                                  </li>

                                  {subchildren.map(subchild => (
                                    <li key={`third-subchild-${subchild.node.id_category}`}>
                                      <PageLink className="" to={subchild.node[`path_${lang.suffix}`]}>
                                        {subchild.node[`name_${lang.suffix}`]}
                                      </PageLink>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )
                          })}
                        </div>
                      )
                    }
                  }
                  return null
                })}
              </div>
            </div>

            {openMenu ? (
              <div className={`sub ${openMenu && visibleSubMenuIdx ? 'mm-visible' : 'mm-invisible'}`}>
                {allShopCategory.edges.sort(sortByPosition).find(({ node }) => +node.id_category === +visibleSubMenuIdx) ? (
                  <>
                    <PageLink className="back d-flex" to={`/`} onClick={backToRootMobileMenu}>
                      <i className="fas fa-chevron-left mt-1 mr-2"></i>
                      <p>{l('menu-back-to-category', lang)}</p>
                    </PageLink>

                    <PageLink className="d-flex" to={t(allShopCategory.edges.find(({ node }) => +node.id_category === +visibleSubMenuIdx).node, 'path', lang)} onClick={closeMenu}>
                      <h5>{l('menu-everything-from-category', lang)} {t(allShopCategory.edges.find(({ node }) => +node.id_category === +visibleSubMenuIdx).node, 'name', lang)}</h5>
                      <i className="fas fa-chevron-right mt-1 ml-auto"></i>
                    </PageLink>

                    {allShopCategory.edges.sort(sortByPosition).filter(({ node }) => +node.id_parent === +visibleSubMenuIdx) ? allShopCategory.edges.filter(({ node }) => +node.id_parent === +visibleSubMenuIdx).map(({ node: subchild }) => (
                      <PageLink className="d-flex" key={`subchild-mobile-${subchild.id_category}`} to={subchild[`path_${lang.suffix}`]} onClick={closeMenu}>
                        <p>{subchild[`name_${lang.suffix}`]}</p>
                        <i className="fas fa-chevron-right mt-1 ml-auto"></i>
                      </PageLink>
                    )) : ''}
                  </>
                ) : ''}
              </div>
            ) : null}

            <div className="buttons d-flex flex-row justify-content-between">
              <div className="icons">
                <a href="https://www.facebook.com/shopupnorthcom/" target="_blank" rel="noreferrer" className="mx-1">
                  <i className="fab fa-2x fa-facebook-square"></i>
                </a>
                <a href="https://www.instagram.com/shopupnorth_com/" target="_blank" rel="noreferrer" className="mx-1">
                  <i className="fab fa-2x fa-instagram-square"></i>
                </a>
              </div>
              <div className="language">
                <select name="language" id="language" value={lang.id_lang} onChange={e => onLanguageSelected(e)} onBlur={e => onLanguageSelected(e)}>
                  {languages.map(l => (
                    <option key={`switch-language-${l.id_lang}`} value={l.id_lang}>{l.suffix.toUpperCase()}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

        </menu>
      )}
    />
  )
}

PageHeader.propTypes = {
  lang: PropTypes.object,
  switcher: PropTypes.object,
  disableSearch: PropTypes.bool
}

export default PageHeader

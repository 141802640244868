import React, { useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { CheckoutStepIdentification } from '../..'
import { context } from '../../../store'

const { pages } = require('../../../config/pages.json')
const stepCart = pages.find(i => i.id === 'cart')
const stepGuest = pages.find(i => i.id === 'checkout-step-guest')
const stepShipping = pages.find(i => i.id === 'checkout-step-shipping')
// const stepGiftWrapping = pages.find(i => i.id === 'checkout-step-giftwrapping')
const stepPayment = pages.find(i => i.id === 'checkout-step-payment')

const CheckoutRouter = ({ lang }) => {
  const { cartProvider } = useContext(context)

  useEffect(() => {
    const validate = async () => {
      const result = await cartProvider.getCheckout()

      if (result) {
        if (result.changed) {
          navigate(stepCart[`path_${lang.suffix}`])
        }
        else if (result.actions) {
          if (result.actions.includes('IDENTIFICATION')) {
            // do nothing
          } else if (result.actions.includes('INFO')) {
            navigate(stepGuest[`path_${lang.suffix}`])
          } else if (result.actions.includes('CARRIER')) {
            navigate(stepShipping[`path_${lang.suffix}`])
          } else if (result.actions.includes('INVOICE_ADDRESS')) {
            navigate(stepPayment[`path_${lang.suffix}`])
          } else if (result.actions.includes('DELIVERY_ADDRESS')) {
            navigate(stepShipping[`path_${lang.suffix}`])
          } else if (result.actions.length === 0) {
            navigate(stepPayment[`path_${lang.suffix}`])
          }
        }
      }
    }

    validate()
  }, [cartProvider, lang])

  return (
    <CheckoutStepIdentification lang={lang} />
  )
}

CheckoutRouter.propTypes = {
  lang: PropTypes.object
}

export default CheckoutRouter

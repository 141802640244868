import React from 'react'
import PropTypes from 'prop-types'
import { CheckoutStep } from '../..'

const CheckoutStepShipping = ({ lang }) => {
  return (<>
    <CheckoutStep lang={lang} isShipping={true}/>
  </>
  )
}

CheckoutStepShipping.propTypes = {
  lang: PropTypes.object
}

export default CheckoutStepShipping

import React from 'react'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import Image from './image'

import './contenthighlightblock.scss'

const ContentHighLightBlock = ({ lang, title, description, buttonLink, buttonTitle, images }) => {
  return (
    <div className="content-highlight-block container">
      <div className="margin-bottom">
        <h3 className="title underline mb-3 d-inline-block">{title}</h3>
        <div className="row no-gutters">
          <div className="col-6 col-md-3 p-1">
            <Image src={images[0]} alt={description} />
          </div>
          <div className="col-6 col-md-3 p-1">
            <Image src={images[1]} alt={description} />
          </div>
          <div className="col-6 col-md-3 p-1">
            <Image src={images[2]} alt={description} />
          </div>
          <div className="col-6 col-md-3 p-1">
            <Image src={images[3]} alt={description} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <h4 className="mt-4">{description}</h4>
          </div>
          <div className="col-md-4">
            <PageLink to={buttonLink} className="btn-medium-secondary float-md-right">
              {buttonTitle} <i className={`fas fa-chevron-right ml-1`}></i>
            </PageLink>
          </div>
        </div>
      </div>
    </div>
  )
}

ContentHighLightBlock.propTypes = {
  lang: PropTypes.object
}

export default ContentHighLightBlock

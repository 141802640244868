import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
// import { PageLayout } from '../..'
import { context } from '../../../store'
import { l } from '../../../lib/locale'

const AccountProfile = ({ lang }) => {
  const { store, sessionProvider } = useContext(context)
  const { customer, disabled, updatingprofile } = store && store.session
    ? store.session
    : { customer: null, disabled: false, updatingprofile: false }

  const [gender, setGender] = useState(customer ? customer.id_gender : 0)
  const [firstname, setFirstname] = useState(customer ? customer.firstname : '')
  const [lastname, setLastname] = useState(customer ? customer.lastname : '')
  const [email, setEmail] = useState(customer ? customer.email : '')

  const save = async (e) => {
    e.preventDefault()
    await sessionProvider.updateProfile(gender, firstname, lastname, email)
  }

  return (
    <section className="account-block">
      <form onSubmit={save}>
        <h3>{l('account-profile', lang)}</h3>
        <div className="checkboxes">
          <p>{l('field-gender', lang)}</p>
          <div>
            <input className="un-cb" type="checkbox" id="woman" checked={+gender === 2} onChange={e => setGender(2)} />
            <label htmlFor="woman">{l('field-gender-ms', lang)}</label>
          </div>
          <div>
            <input className="un-cb" type="checkbox" id="man" checked={+gender === 1} onChange={e => setGender(1)} />
            <label htmlFor="man">{l('field-gender-mr', lang)}</label>
          </div>
        </div>
        <div className="inputs">
          <div className="d-lg-flex">
            <div className="field">
              <p>{l('field-firstname', lang)}</p>
              <input value={firstname} onChange={e => setFirstname(e.target.value)} />
            </div>
            <div className="field">
              <p>{l('field-lastname', lang)}</p>
              <input value={lastname} onChange={e => setLastname(e.target.value)} />
            </div>
          </div>
          <div className="field">
            <p>{l('field-email', lang)}</p>
            <input value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <button className="btn-medium-secondary save-btn" type="submit" disabled={disabled}>
            <i className={`fas ${updatingprofile ? 'fa-spin fa-spinner' : 'fa-check'}`}></i> {l('button-save-changes', lang)}
          </button>
        </div>
      </form>
    </section>
  )
}

AccountProfile.propTypes = {
  lang: PropTypes.object
}

export default AccountProfile

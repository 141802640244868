import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SKUSelector } from '../..'

const CartSelectorSideBar = ({ close, store, data, lang }) => {
  const { product, sku, attributes, attribute_groups } = data
  const [selected, setSelected] = useState(sku)

  const onSKUSelected = (sku) => {
    setSelected(sku)
  }

  return (
    <div className="d-flex flex-column">
      <SKUSelector
        product={product}
        sku={selected}
        attributes={attributes}
        attribute_groups={attribute_groups}
        onChange={onSKUSelected}
        lang={lang} />
    </div>
  )
}

CartSelectorSideBar.propTypes = {
  close: PropTypes.func,
  store: PropTypes.object,
  data: PropTypes.object,
  lang: PropTypes.object
}

export default CartSelectorSideBar

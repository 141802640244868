import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { PageLink } from '../..'
import { context } from '../../../store'
import { l } from '../../../lib/locale'
import { pageURL } from '../../../lib/page'

const OrderConfirmation = ({ lang }) => {
  const { store, cartProvider } = useContext(context)
  const [order, setOrder] = useState()
  const [loading, setLoading] = useState(true)

  const { customer } = store && store.session
    ? store.session
    : { customer: null }

  let id_order = ''
  if (typeof window !== 'undefined') {
    const qs = queryString.parse(window.location.search)
    id_order = qs.id ? qs.id : ''
  }

  useEffect(() => {
    const load = async () => {
      try {
        const order = await cartProvider.getOrder(id_order)
        if (order) {
          setOrder(order)

          if (order) {
            if (window && window.ga) {
              // see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#action-data

              if (order._embedded.items) {
                order._embedded.items.forEach(i => {
                  window.ga('ec:addProduct', {
                    'id': i.id_sku,
                    'name': i.name,
                    // 'category': 'Apparel',
                    'brand': i.manufacturer_name,
                    // 'variant': 'black',
                    'price': `${Number(i.unit_price_tax_incl).toFixed(2)}`,
                    'quantity': i.quantity
                  })
                })
              }

              window.ga('ec:setAction', 'purchase', {
                id: `${order.reference}`,
                affiliation: 'Up North',
                revenue: `${Number(order.total_paid_real).toFixed(2)}`,
                tax: `${Number(order.total_tax).toFixed(2)}`,
                shipping: `${Number(order.total_shipping).toFixed(2)}`
              })
              window.ga('send', 'pageview')
            }
          }
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }

    load()
  }, [id_order])

  if (loading) {
    return (
      <div className="completed">
        <i className="fas fa-spin fa-spinner fa-2x"></i>
      </div>
    )
  } else if (order) {
    return (
      <div className="completed">
        <h2>{l('order-confirmation-title', lang).replace('{reference}', order.reference)}</h2>
        <p>{l('order-confirmation-more', lang)}</p>
        {customer && (
          <PageLink to={pageURL('my-orders', lang)} className="btn-medium-secondary">
            {l('order-confirmation-button', lang)}
          </PageLink>
        )}
      </div>
    )
  } else {
    return (
      <div className="completed">
        <div className="alert alert-danger">
          {l('error-order-not-found', lang)}
        </div>
      </div>
    )
  }
}

OrderConfirmation.propTypes = {
  lang: PropTypes.object
}

export default OrderConfirmation

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { PageLink } from '../..'
import { l } from '../../../lib/locale'
import { pageURL } from '../../../lib/page'
import { brandURL } from '../../../lib/brand'

import './brands.scss'

const filter_brands = [49,50,70,23,34,167,169,71,73,148,136,185,67,176,171,35,11,81,10,22,83,27,96]

const ContentBlockBrands = ({ lang }) => {
  const data = useStaticQuery(graphql`
    query {
      allShopBrand(filter: {active: {eq: 1}}) {
        edges {
          node {
            id
            id_manufacturer
            name
          }
        }
      }
    }
  `)

  const brands = data && data.allShopBrand && data.allShopBrand.edges
  const selected_brands = brands
    ? brands.filter(i => filter_brands.includes(+i.node.id_manufacturer)).map(i => i.node)
    : null

  return (
    <div className="brands text-center margin-bottom">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>{l('block-brands-title', lang)}</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>{l('block-brands-pre', lang)}{selected_brands.map((i,j) => {
              return (
                <><PageLink to={brandURL(i, lang)} key={`ContentBlockBrands-${i.id_manufacturer}`}>
                  {i.name}
                </PageLink>{j+1 < selected_brands.length ? ', ' : ''}</>
              )
            })}{l('block-brands-post', lang)}</h4>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 text-center">
            <PageLink to={pageURL('brands', lang)} className="btn-medium-secondary">
              {l('block-brands-button', lang)} <i className={`fas fa-chevron-right ml-1`}></i>
            </PageLink>
          </div>
        </div>
      </div>
    </div>
  )
}

ContentBlockBrands.propTypes = {
  lang: PropTypes.object
}

export default ContentBlockBrands

import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { SessionLoginForm, PageLink } from '../..'
import { l } from '../../../lib/locale'
import { pageURL } from '../../../lib/page'
import { context } from '../../../store'

const CheckoutStepIdentification = ({ lang }) => {
  const { cartProvider } = useContext(context)
  const [loading, setLoading] = useState(false)

  const handleLogin = async () => {
    setLoading(true)
    // await cartProvider.initCheckout(lang, navigate, true)
    await cartProvider.setCheckoutIdentification(lang, navigate)
  }

  const initCheckout = async (e) => {
    if (e) e.preventDefault()
    setLoading(true)
    // await cartProvider.initCheckout(lang, navigate, true)
    await cartProvider.setCheckoutIdentification(lang, navigate)
  }

  return (
    <div className="content">
      <div className="row">
        <div className="title col-12">
          <h2>{l('checkout-identification-choose', lang)}</h2>
        </div>

        <div className="co-login col-12 col-md-6 d-flex flex-column justify-content-between">
          <h2>{l('checkout-identification-login', lang)}</h2>
          <SessionLoginForm lang={lang} onSuccess={handleLogin} showLinks={true} />
        </div>

        <div className="co-guest col-12 col-md-6 d-flex flex-column justify-content-between">
          <div className="guest-content mt-5 mt-md-0">
            <h2>{l('checkout-identification-guest', lang)}</h2>
            <p>{l('checkout-identification-guest-reason', lang)}</p>
          </div>
          <div className="guest-button">
            <button className="btn-medium-secondary btn-checkout" onClick={initCheckout}>
              <i className={`fas ${loading ? 'fa-spin fa-spinner' : 'fa-chevron-right'}`}></i> {l('checkout-identification-guest-button', lang)}
            </button>
          </div>
        </div>
      </div>

      <div className="no-account row mt-3">
        <div className="col-12">
          {l('login-no-account-yet', lang)}
          <PageLink to={pageURL('createaccount', lang)} className="btn btn-link">{l('button-create-account', lang)}</PageLink>
        </div>
      </div>
    </div>
  )
}

CheckoutStepIdentification.propTypes = {
  lang: PropTypes.object
}

export default CheckoutStepIdentification

import React, { useState, useEffect, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { ProductList, PageBreadcrumb } from '../..'
import { context } from '../../../store'
import callAPI from '../../../lib/callAPI'
import { navigate } from 'gatsby';

const ITEMS_PER_PAGE = [
  20,
  60,
  200,
  100000
]
const DEFAULT_SORTMODE_DESC = 1

const updateQueryParams = (page, lang, newParams = {}) => {
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search)

    const currentParams = {
      page: searchParams.get('page'),
      sortBy: searchParams.get('sortBy'),
      sortMode: searchParams.get('sortMode'),
      q: searchParams.get('q'),
    }
    
    const mergedParams = Object.assign(currentParams, newParams)

    if (mergedParams.page) searchParams.set('page', mergedParams.page)
    if (mergedParams.sortBy) searchParams.set('sortBy', mergedParams.sortBy)
    if ([0,1].includes(mergedParams.sortMode)) searchParams.set('sortMode', mergedParams.sortMode)
    if (mergedParams.q) searchParams.set('q', mergedParams.q)

    if (searchParams.size > 0) {
      navigate(`${page[`path_${lang.suffix}`]}?${searchParams.toString()}`)
    }
  }
}

const SearchPage = ({ page, products, attributes, attribute_groups, lang, navigation }) => {
  const { store } = useContext(context)

  let initialQuery = ''
  let initialSortBy = 'date_add'
  let initialSortMode = DEFAULT_SORTMODE_DESC

  if (typeof window !== 'undefined') {
    const qs = queryString.parse(window.location.search)
    initialQuery = qs.q ? qs.q : ''
    initialSortBy = qs.sortBy ? qs.sortBy : initialSortBy
    initialSortMode = qs.sortMode && [0,1].includes(+qs.sortMode) ? +qs.sortMode : DEFAULT_SORTMODE_DESC
  }

  const [results, setResults] = useState([])
  const [promos, setPromos] = useState(null)
  const [total, setTotal] = useState(0)
  const [sortBy, setSortBy] = useState(initialSortBy)
  const [sortMode, setSortMode] = useState(initialSortMode)
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE[1])
  const prevSortBy = useRef(initialSortBy)
  const prevSortMode = useRef(initialSortMode)

  useEffect(() => {
    const search = async () => {
      if (store.app.initialized) {
        try {
          const params = {
            search: initialQuery,
          }

          if (itemsPerPage > 0) {
            params.count = itemsPerPage
          }

          let pageNb = 1
          if (navigation && navigation.page > 0) {
            pageNb = navigation.page

            if (prevSortBy.current !== sortBy) {
              pageNb = 1
            }

            params.start = (pageNb - 1) * itemsPerPage
          }

          if (prevSortBy.current !== sortBy || prevSortMode.current !== sortMode) {
            // update search params when switching sorter or sortMode
            updateQueryParams(page, lang, {
              page: pageNb,
              sortBy: sortBy,
              sortMode: sortMode,
              q: initialQuery
            })
          }

          if ([0,1].includes(sortMode)) {
            params.sortMode = sortMode
            prevSortMode.current = sortMode
          }

          if (sortBy) {
            params.sortBy = sortBy
            prevSortBy.current = sortBy
          }

          const result = await callAPI(store, store.app.endpoints.search, params)

          if (result && result._embedded && result._embedded.items) {
            const results = result._embedded.items

            setTotal(result.total > 0 ? result.total : 0)
            setResults( results && results.length > 0 ? results : [] )
            setPromos( result && result._embedded && result._embedded.promos ? result._embedded.promos : null )

            return true
          }
          setResults([])
          return false
        } catch (e) {
          // @todo log error ?
          setResults([])
        }
      }
    }

    search()
  }, [initialQuery, lang, products, store, sortBy, sortMode, itemsPerPage])

  return (
    <div className="product-list-4 product-list-breadcrumbs">
      <PageBreadcrumb
        lang={lang}
        current={page}
        items={[ 'homepage' ]}
        />

      <ProductList
        title={page[`title_${lang.suffix}`]}
        total={total}
        products={results}
        promos={promos}
        attributes={attributes}
        attribute_groups={attribute_groups}
        navigation={navigation}
        lang={lang}
        sortBy={sortBy} setSortBy={setSortBy}
        sortMode={sortMode} setSortMode={setSortMode}
        itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage}
        availableItemsPerPage={ITEMS_PER_PAGE}
        keyPrefix={`search-result`}
        showSKU={true} />
    </div>
  )
}

SearchPage.propTypes = {
  page: PropTypes.object,
  products: PropTypes.array,
  attributes: PropTypes.array,
  attribute_groups: PropTypes.array,
  lang: PropTypes.object
}

export default SearchPage

import React, { useState, useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import dateFormat from 'dateformat'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { context } from '../../../store'
import { l } from '../../../lib/locale'
import { PageLink } from '../..'

const MIN_SEARCH_LENGTH = 2

const GiftListSearchBlock = ({ lang, setSelected }) => {
  const { appProvider, store } = useContext(context)

  let initialQuery = ''
  if (typeof window !== 'undefined') {
    const qs = queryString.parse(window.location.search)
    initialQuery = qs.search ? qs.search : ''
  }

  const [query, setQuery] = useState(initialQuery)
  const [result, setResult] = useState()

  useEffect(() => {
    const load = async () => {
      if (store.app.initialized && initialQuery && initialQuery.length > MIN_SEARCH_LENGTH) {
        const result = await appProvider.searchGiftlist(initialQuery)
        setResult(result)
      } else {
        setResult(null)
      }
    }

    load()
  }, [appProvider, initialQuery, store.app.initialized])

  const search = async (e) => {
    if (e) e.preventDefault()
    navigate(`?search=${query}`)
    if (query && query.length > MIN_SEARCH_LENGTH) {
      const result = await appProvider.searchGiftlist(query)
      setResult(result)
    } else {
      setResult(null)
    }
  }

  return (
    <>
      <form className="giftlist-search" method="GET" onSubmit={search}>
        <input placeholder={l('giftlist-placeholder-search-list', lang)} value={query} onChange={e => setQuery(e.target.value)} />
        <button className="btn-large-secondary button" type="submit">
          {l('button-search', lang)}
        </button>
      </form>

      {result && result._embedded && result._embedded.giftlists ? (
        <section className="giftlist mb-5">
          <div className="row">
            <div className="header col-md-8 d-md-flex justify-content-md-between">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <p>{l('field-listname', lang)}</p>
                  </div>
                  <div className="col-md-6">
                    <p>{l('field-parents', lang)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <hr className="divider" />
          </div>
          <div className="container gift">

            {result._embedded.giftlists.map(i => (
              <div className="row" key={`giftlist-${i.id_giftlist}`}>
                <div className="listdata col col-md-8 d-md-flex align-items-md-center">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 listname d-flex align-items-center">
                        <p>{i.name}</p>
                      </div>
                      <div className="col-md-6 firstname d-flex align-items-center">
                        <p>{i.name_parents}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 xbuttons d-flex flex-md-column justify-content-between justify-content-md-end align-items-end">
                  <PageLink to={`?id=${i.id_giftlist}`} className="btn-medium-light to-list" onClick={e => setSelected(i.id_giftlist)}>
                    {l('button-goto-list', lang)}
                  </PageLink>
                </div>
              </div>
            ))}

          </div>
          <hr />
        </section>
      ) : (
        <></>
      )}
    </>
  )
}

GiftListSearchBlock.propTypes = {
  lang: PropTypes.object,
  setSelected: PropTypes.func
}

export default GiftListSearchBlock

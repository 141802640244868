const normalize = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const brandURL = (brand, lang) => {
  if (lang.id_lang === 1) {
    return `/${lang.suffix}/brands/${brand.id_manufacturer}-${normalize(brand.name.replace(' ', '-'))}`
  } else if (lang.id_lang === 4) {
    return `/${lang.suffix}/merken/${brand.id_manufacturer}-${normalize(brand.name.replace(' ', '-'))}`
  }
}

module.exports = { brandURL }

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { context } from '../../../store'
import {
  CartSideBar, AccountAddressDeleteSideBar, CartCountrySelectorSideBar,
  CartSelectorSideBar, AccountAddressSideBar, VoucherSideBar,
  GiftListSideBar } from '../..'

const PageSideBarRight = ({ lang }) => {
  const { store, layoutProvider, sessionProvider, cartProvider } = useContext(context)
  const {
    showsidebarright, cartresult, addcombination, addressdelete, addressadd,
    countryselector, addvoucher, giftlistresult, data
  } = store && store.layout
    ? store.layout
    : {
      showsidebarright: false, cartresult: null, addcombination: null,
      addressdelete: null, countryselector: null, addressadd: null
    }

  const close = (e) => {
    // if (e) e.preventDefault()

    if (store && store.layout && store.layout.close) {
      store.layout.close()
    } else {
      layoutProvider.close()
    }
  }

  return (
    <div className={`un-modal un-modal-right ${showsidebarright ? 'un-modal-show' : ''}`}>
      <div className="d-flex">
        <button className="un-modal-dark-part" onClick={close}>x</button>
        <div className="un-modal-content">
          {cartresult && (
            <CartSideBar
              close={close}
              store={store}
              cartProvider={cartProvider}
              layoutProvider={layoutProvider}
              data={cartresult}
              lang={lang} />
          )}
          {addcombination && (
            <CartSelectorSideBar
              close={close}
              store={store}
              data={addcombination}
              lang={lang} />
          )}
          {addressdelete && (
            <AccountAddressDeleteSideBar
              close={close}
              store={store}
              data={addressdelete}
              provider={sessionProvider}
              lang={lang} />
          )}
          {countryselector && (
            <CartCountrySelectorSideBar
              close={close}
              store={store}
              data={countryselector}
              provider={cartProvider}
              lang={lang} />
          )}
          {addressadd && (
            <AccountAddressSideBar
              close={close}
              store={store}
              data={countryselector}
              provider={cartProvider}
              id_country={data && data.id_country ? data.id_country : null}
              id_carrier={data && data.id_carrier ? data.id_carrier : null}
              refresh={data && data.refresh ? data.refresh : null}
              lang={lang} />
          )}
          {addvoucher && (
            <VoucherSideBar
              close={close}
              store={store}
              data={addvoucher}
              provider={cartProvider}
              lang={lang} />
          )}
          {giftlistresult && (
            <GiftListSideBar
              close={close}
              store={store}
              data={giftlistresult}
              lang={lang} />
          )}
        </div>
      </div>
    </div>
  )
}

PageSideBarRight.propTypes = {
  lang: PropTypes.object
}

export default PageSideBarRight

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ImageLogo = ({ alt, mobile }) => {
  const data = useStaticQuery(graphql`
    query {
      desktopImage: file(base: {eq: "logo_menu.png"}) {
        childImageSharp {
          fixed(width: 250, height: 60) {
            originalName
            ...GatsbyImageSharpFixed
          }
        }
      }
      mobileImage: file(base: {eq: "logo_menu.png"}) {
        childImageSharp {
          fixed(width: 225, height: 54) {
            originalName
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  if (mobile) {
    return <Img fixed={data.mobileImage.childImageSharp.fixed} alt={alt} className="d-block d-md-none" />
  } else {
    return <Img fixed={data.desktopImage.childImageSharp.fixed} alt={alt} className="d-none d-md-block" />
  }
}

ImageLogo.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  mobile: PropTypes.bool
}

export default ImageLogo

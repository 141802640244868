import React from 'react'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import { l } from '../../../lib/locale'
import Image from './image'

import './contenthighlightbullets.scss'

const ContentHighLightBullets = ({ title, description, buttonLink, buttonTitle, highlights, lang }) => {
  const items = process.env.GATSBY_HOMEPAGE_CATEGORY_BRANDS.split(',').map(id => {
    return highlights.find(j => +j.id_manufacturer === +id)
  })

  return (
    <div className="content-highlight-bullets margin-bottom">
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h3 className="">{l(title, lang)}</h3>
            <p className="">{l(description, lang)}</p>
          </div>
          <div className="col-md-3">
            <PageLink to={buttonLink} className="btn-medium-secondary">
              {l(buttonTitle, lang)} <i className={`fas fa-chevron-right ml-1`}></i>
            </PageLink>
          </div>
        </div>

        <div className="container content-highlight-bullets-col">
          <div className="row">
            {items.map(i => {
              if (i) {
                return (
                  <div className="col-6 col-md-2 text-center content-highlight-bullets-col-item" key={`category-bullet-highlight-${i.id_manufacturer}`}>
                    <PageLink to={i.link}>
                      <Image src={i.image} />
                      <span>{i.title}</span>
                    </PageLink>
                  </div>
                )
              } else {
                return ''
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

ContentHighLightBullets.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonTitle: PropTypes.string,
  highlights: PropTypes.array,
  lang: PropTypes.object
}

export default ContentHighLightBullets

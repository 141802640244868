import React from 'react'
import PropTypes from 'prop-types'
import { CheckoutStep } from '../..'

const CheckoutStepPayment = ({ lang }) => {
  return (
    <CheckoutStep lang={lang} isPayment={true} />
  )
}

CheckoutStepPayment.propTypes = {
  lang: PropTypes.object
}

export default CheckoutStepPayment

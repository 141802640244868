import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { PageLink } from "../.."
import { l } from "../../../lib/locale"
import { formatAddressOption } from "../../../lib/address"
import { context } from "../../../store"
import useScript from "../../../hooks/useScript"

const { pages } = require("../../../config/pages.json")
const nextstep = pages.find(i => i.id === "checkout-step-giftwrapping")
const currentstep = pages.find(i => i.id === "checkout-step-shipping")

/**
 *
 * @see https://sendcloud.github.io/spp-integration-example/ for sendcloud popup
 *
 */

const CheckoutBlockShipping = ({
  lang,
  active,
  index,
  store,
  cartProvider,
}) => {
  const { layoutProvider } = useContext(context)

  useScript("https://code.jquery.com/jquery-2.0.0.js")
  useScript("https:////embed.sendcloud.sc/spp/1.0.0/api.min.js")

  const { checkout, cart } = store.cart
  const options =
    checkout && checkout._embedded ? checkout._embedded.delivery_options : null
  const countries =
    checkout && checkout._embedded
      ? checkout._embedded.countries
      : cart && cart._embedded
      ? cart._embedded.countries
      : null
  const { disabled } = cart

  const [carrier, setCarrier] = useState(checkout.cart.id_carrier)
  const [updatingCountry, setUpdatingCountry] = useState(false)
  const [updatingCarrier, setUpdatingCarrier] = useState(false)
  const [id_address_delivery, setAddressDelivery] = useState(
    cart.id_address_delivery
  )
  const [servicePoint, setServicePoint] = useState(
    cart._embedded && cart._embedded.service_points
      ? JSON.parse(cart._embedded.service_points[0].details)
      : null
  )

  useEffect(() => {
    if (!active) return

    return () => {
      // check if the sendcloud map is open and close it
      const sendcloudMap = document.querySelector(
        "#sendcloudshipping_service_point_map"
      )
      if (active && sendcloudMap) {
        window.sendcloud.servicePoints.close()
      }
    }
  }, [active])

  const saveCarrier = async e => {
    if (e) e.preventDefault()

    const option = options.find(o => o.id_carrier === carrier)
    if (option) {
      setUpdatingCarrier(true)
      const opts = {
        id_carrier: carrier,
        id_address_delivery: id_address_delivery ? id_address_delivery : 0,
        service_point: servicePoint,
      }
      await cartProvider.updateCarrier(cart, opts, lang)
      await cartProvider.getCheckout()
      setUpdatingCarrier(false)
      navigate(nextstep[`path_${lang.suffix}`])
    }
  }

  const updateCountry = async e => {
    setUpdatingCountry(true)
    setAddressDelivery("")
    await cartProvider.updateCountry(cart, e.target.value, lang)
    await cartProvider.getCheckout()
    setUpdatingCountry(false)
  }

  const updateDeliveryAddress = async e => {
    setAddressDelivery(e.target.value)
  }

  const onServicePointSelected = servicePointObject => {
    setServicePoint(servicePointObject)
  }

  const showServicePointsDialog = e => {
    if (e) e.preventDefault()

    const country = checkout._embedded.countries.find(
      c => +c.id_country === +cart.id_country
    )

    var config = {
      // API key is required, replace it below with your API key
      apiKey: "afc64f6464464316a41ae921bce5a8e4",
      // Country is required
      country: country.iso_code, // string - (e.g. nl, fr, be, us)
      // Postal code is not required, although we recommend it
      postalCode: "", // string
      // Language is also not required. defaults to "en" - (available options en, fr, nl, de)
      language: lang && lang.suffix ? lang.suffix : "nl", // string (e.g. en, fr, nl, de)
      // you can filter service points by carriers as well.
      carriers: +cart.id_country !== 3 ? "dpd" : "bpost", // comma separated string (e.g. "postnl,bpost,dhl")
    }

    if (typeof window !== "undefined" && window.sendcloud) {
      window.sendcloud.servicePoints.open(
        // first arg: config object
        config,
        onServicePointSelected,
        // // second arg: success callback function
        // function(servicePointObject) {
        //     resultElem.innerHTML = JSON.stringify(servicePointObject, null, 4);
        //     postResultElem.style.display = 'block';
        //     resultElem.style.display = 'block';
        // },
        // third arg: failure callback function
        // this is also called with ["Closed"] when the SPP window is closed.
        function (errors) {
          errors.forEach(function (error) {})
        }
      )
    }
  }

  const showAddressForm = e => {
    if (e) e.preventDefault()
    layoutProvider.showAddressCreate(cart.id_country, carrier, address => {
      setAddressDelivery(address.id_address)
    })
  }

  let isNotValid = !carrier || +carrier <= 0
  if (carrier) {
    const option = options.find(o => o.id_carrier === carrier)
    if (option) {
      if (option.requires_address && !id_address_delivery) {
        isNotValid = true
      } else if (option.requires_pickup_point && !servicePoint) {
        isNotValid = true
      }
    } else {
      isNotValid = true
    }
  }

  const hideInvalid = i => {
    if (i.requires_giftlist && !cart.is_giftlist) {
      return false
    }
    if (i.requires_country && +i.requires_country > 0) {
      if (+i.requires_country !== +cart.id_country) {
        return false
      }
    }
    return true
  }

  return (
    <div className="content">
      <PageLink
        className="form-header d-flex align-items-center clickable"
        disabled={cart.step_info ? false : true}
        to={currentstep[`path_${lang.suffix}`]}
      >
        <h3>
          <span className="form-header-step">{index}</span>
          {l("checkout-shipping", lang)}
        </h3>
      </PageLink>
      {active && (
        <form onSubmit={saveCarrier}>
          <div className="form-content" id="delivery">
            <div className="checkboxes">
              <div className="row mb-3">
                <div className="col-md-6">
                  <p className="mt-2">{l("checkout-shipping-select", lang)}</p>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i
                          className={`fas ${
                            updatingCountry
                              ? "fa-spin fa-spinner"
                              : "fa-globe-europe"
                          }`}
                        ></i>
                      </span>
                    </div>
                    <select
                      className="form-control"
                      onBlur={updateCountry}
                      onChange={updateCountry}
                      value={cart.id_country}
                      disabled={disabled}
                    >
                      {countries
                        ? countries.map(country => (
                            <option
                              value={country.id_country}
                              key={`country-${country.id_country}`}
                            >
                              {country[`name_${lang.suffix}`]}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {options.filter(hideInvalid).map(option => (
              <div className="checkboxes" key={`carrier-${option.id_carrier}`}>
                <div className="row mb-3">
                  <div className="col-12">
                    <input
                      className="mr-1"
                      type="checkbox"
                      id={`carrier-${option.id_carrier}`}
                      value={option.id_carrier}
                      checked={+option.id_carrier === +carrier}
                      onChange={e => setCarrier(option.id_carrier)}
                      disabled={disabled}
                    />
                    <label htmlFor={`carrier-${option.id_carrier}`}>
                      {option.name} {option[`delay_${lang.suffix}`]}
                      {option.eco ? (
                        <span className="ml-2 delivery-option-eco">
                          <i className="fas fa-leaf"></i>
                        </span>
                      ) : null}
                      <span className="badge badge-light ml-2">
                        {option.is_free || +option.price === 0
                          ? l("free", lang)
                          : `€${Number(option.price).toFixed(2)}`}
                      </span>
                    </label>
                  </div>
                </div>

                {option.requires_address && +option.id_carrier === +carrier ? (
                  <div className="inputs container">
                    <div className="row">
                      <div className="field col-12 col-md-4 col-lg-3 pt-2">
                        {l("checkout-shipping-choose-address", lang)}
                      </div>
                      <div className="col-12 col-md-8 col-lg-9">
                        {checkout._embedded.addresses &&
                        checkout._embedded.addresses.filter(
                          a => a.id_country === cart.id_country
                        ).length > 0 ? (
                          <div className="input-group mr-2">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className={`fas fa-map-marked`}></i>
                              </span>
                            </div>
                            <select
                              className="form-control"
                              onBlur={updateDeliveryAddress}
                              onChange={updateDeliveryAddress}
                              value={id_address_delivery}
                              disabled={disabled}
                            >
                              <option value="">&nbsp;</option>
                              {checkout._embedded.addresses
                                .filter(a => a.id_country === cart.id_country)
                                .map(address => (
                                  <option
                                    value={address.id_address}
                                    key={`address-${address.id_address}`}
                                  >
                                    {formatAddressOption(address)}
                                  </option>
                                ))}
                            </select>
                            <div className="input-group-append">
                              <button
                                className="btn btn-outline-secondary"
                                onClick={showAddressForm}
                              >
                                <i className="fas fa-plus"></i>
                                <span className="d-none d-md-inline-block ml-1">
                                  {l("button-create-address", lang)}
                                </span>
                              </button>
                            </div>
                          </div>
                        ) : (
                          <button
                            className="btn btn-outline-secondary"
                            onClick={showAddressForm}
                          >
                            <i className="fas fa-plus"></i>{" "}
                            {l("button-create-address", lang)}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {option.requires_pickup_point &&
                +option.id_carrier === +carrier ? (
                  <div className="inputs container">
                    <div className="row">
                      <div className="field col-12 col-md-6">
                        <div className="card">
                          <div className="card-body">
                            {servicePoint ? (
                              <>
                                <b>{servicePoint.name}</b>
                                <br />
                                {servicePoint.street}{" "}
                                {servicePoint.house_number}
                                <br />
                                {servicePoint.postal_code} {servicePoint.city}
                              </>
                            ) : (
                              l("checkout-shipping-no-pickup-point", lang)
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="field col-12 col-md-6 text-center">
                        <button
                          className="btn btn-outline-secondary"
                          onClick={showServicePointsDialog}
                        >
                          <i className="fas fa-map-marker-alt"></i>{" "}
                          {l("checkout-shipping-select-pickup-point", lang)}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}

            <div className="d-flex justify-content-md-end">
              <button
                className="btn btn-medium-secondary next-btn"
                type="submit"
                disabled={isNotValid || disabled}
              >
                <i
                  className={`fas ${
                    updatingCarrier ? "fa-spin fa-spinner" : "fa-chevron-right"
                  }`}
                ></i>{" "}
                {l("button-continue", lang)}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

CheckoutBlockShipping.propTypes = {
  lang: PropTypes.object,
  active: PropTypes.bool,
  index: PropTypes.number,
  store: PropTypes.object,
  cartProvider: PropTypes.object,
}

export default CheckoutBlockShipping

import React from 'react'
import PropTypes from 'prop-types'
// import { PageLink } from '../..'

const SKUSideBarFilter = ({ lang }) => {
  return (
    <></>
  )
}

SKUSideBarFilter.propTypes = {
  lang: PropTypes.object
}

export default SKUSideBarFilter

const l = (key, lang) => {
  const locale = require(`../../locale/${lang.suffix}.json`)
  if (locale[key]) return locale[key]
  if (typeof locale[key] !== 'undefined' && locale[key].length === 0) return locale[key]
  return `#${key}#`
}

const t = (object, key, lang) => {
  return object[`${key}_${lang.suffix}`]
}

module.exports = { l, t }

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CartAddSKUButton, WishlistAddSKUButton } from '../..'
import { l } from '../../../lib/locale'

const OUT_OF_STOCK_DEFAULT = 2
const OUT_OF_STOCK_ALLOW = 1
const OUT_OF_STOCK_DISALLOW = 0

const explicit_out_of_stock = [OUT_OF_STOCK_ALLOW, OUT_OF_STOCK_DISALLOW]

const SKUSelectorVoucher = ({ product, sku, lang }) => {
  const [amount, setAmount] = useState('')
  const [message, setMessage] = useState('')

  const out_of_stock = explicit_out_of_stock.includes(product.out_of_stock)
    ? product.out_of_stock
    : explicit_out_of_stock.includes(product.manufacturer_out_of_stock)
      ? product.manufacturer_out_of_stock
      : OUT_OF_STOCK_DEFAULT

  const out_of_stock_allowed = out_of_stock !== OUT_OF_STOCK_DISALLOW

  return (
    <>
      <div className="product-detail-right-extra-voucher">
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <span className="input-group-text">&euro;</span>
          </div>
          <input type="text" className="form-control" placeholder={l('voucher-placeholder-amount', lang)} value={amount} onChange={e => setAmount(e.target.value)} />
        </div>
        <textarea
          className="form-control mb-2"
          rows="5"
          placeholder={l('voucher-placeholder-message', lang)}
          value={message}
          onChange={e => setMessage(e.target.value)}
          maxLength="250" />
      </div>

      <div className="product-detail-right-actions">
        <CartAddSKUButton
          sku={sku}
          lang={lang}
          invalid={!amount}
          amount={+amount > 0 ? +amount : 0}
          message={message}
          className="btn-large-secondary" />
        <WishlistAddSKUButton
          sku={sku}
          lang={lang} />
      </div>
    </>
  )
}

SKUSelectorVoucher.propTypes = {
  product: PropTypes.object,
  sku: PropTypes.object,
  lang: PropTypes.object
}

export default SKUSelectorVoucher

import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { context } from '../../../store'
import { l } from '../../../lib/locale'
import { PageLink } from '../..'

const AccountAddressForm = ({ address, lang, cancel, default_id_country }) => {
  const { store, sessionProvider } = useContext(context)

  const { disabled, creatingaddress, updatingaddress } = store && store.session
    ? store.session
    : { disabled: false, creatingaddress: false, updatingaddress: null }

  const [alias, setAlias] = useState(address ? address.alias : '')
  const [firstname, setFirstname] = useState(address ? address.firstname : '')
  const [lastname, setLastname] = useState(address ? address.lastname : '')
  const [company, setCompany] = useState(address ? address.company : '')
  const [vat_number, setVatNumber] = useState(address ? address.vat_number : '')
  const [address1, setAddress1] = useState(address ? address.address1 : '')
  const [address2, setAddress2] = useState(address ? address.address2 : '')
  const [city, setCity] = useState(address ? address.city : '')
  const [postcode, setPostcode] = useState(address ? address.postcode : '')
  const [id_country, setCountry] = useState(address ? address.id_country : default_id_country ? default_id_country : '')

  const isUpdate = address && address.id_address ? true : false

  const save = async (e) => {
    e.preventDefault()

    if (address) {
      address.alias = alias
      address.firstname = firstname
      address.lastname = lastname
      address.company = company
      address.vat_number = vat_number
      address.address1 = address1
      address.address2 = address2
      address.city = city
      address.postcode = postcode
      address.id_country = id_country

      await sessionProvider.updateAddress(address)
      cancel()
    } else {
      const address = {
        alias: alias,
        firstname: firstname,
        lastname: lastname,
        company: company,
        vat_number: vat_number,
        address1: address1,
        address2: address2,
        city: city,
        postcode: postcode,
        id_country: id_country
      }

      const newAddress = await sessionProvider.createAddress(address)
      cancel(newAddress)
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query AccountAddressFormQuery {
          allShopCountry(filter: {active: {eq: 1}}) {
            edges {
              node {
                id_country
                id
                name_en
                name_nl
              }
            }
          }
        }
      `}
      render={({ allShopCountry }) => (
        <div className="add-address">
          {isUpdate ? (
            <h2>{l('address-change', lang)}</h2>
          ) : (
            <h2>{l('address-create', lang)}</h2>
          )}

          <form onSubmit={save}>
            <div className="inputs">
              <div className="row">
                <div className="field col-12">
                  <p>{l('field-alias', lang)}</p>
                  <input className="form-control" value={alias} onChange={e => setAlias(e.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="row">
                <div className="field col-12 col-md-6">
                  <p>{l('field-firstname', lang)}</p>
                  <input className="form-control" value={firstname} onChange={e => setFirstname(e.target.value)} disabled={disabled} />
                </div>
                <div className="field col-12 col-md-6">
                  <p>{l('field-lastname', lang)}</p>
                  <input className="form-control" value={lastname} onChange={e => setLastname(e.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="row">
                <div className="field col-12 col-md-6">
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <p>{l('field-company-name', lang)}</p>
                    </div>
                    <div>
                      <p className="optional">{l('Optional', lang)}</p>
                    </div>
                  </div>
                  <input className="form-control" value={company} onChange={e => setCompany(e.target.value)} disabled={disabled} />
                </div>
                <div className="field col-12 col-md-6">
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <p>{l('field-vat-number', lang)}</p>
                    </div>
                    <div>
                      <p className="optional">{l('Optional', lang)}</p>
                    </div>
                  </div>
                  <input className="form-control" value={vat_number} onChange={e => setVatNumber(e.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="row">
                <div className="field col-12 col-md-8">
                  <p>{l('field-address', lang)}</p>
                  <input className="form-control" value={address1} onChange={e => setAddress1(e.target.value)} disabled={disabled} />
                </div>
                <div className="field col-12 col-md-4">
                  <p>{l('field-address2', lang)}</p>
                  <input className="form-control" value={address2} onChange={e => setAddress2(e.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="row">
                <div className="field col-12 col-md-8">
                  <p>{l('field-city', lang)}</p>
                  <input className="form-control" value={city} onChange={e => setCity(e.target.value)} disabled={disabled} />
                </div>
                <div className="field col-12 col-md-4">
                  <p>{l('field-postcode', lang)}</p>
                  <input className="form-control" value={postcode} onChange={e => setPostcode(e.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="field">
                <p>{l('field-country', lang)}</p>
                <select className="form-control" value={id_country} onChange={e => setCountry(e.target.value)} onBlur={e => setCountry(e.target.value)}>
                  <option value="">&nbsp;</option>
                  {allShopCountry.edges.map(({ node }) => node).filter(c => {
                    if (+default_id_country > 0 && +c.id_country === +default_id_country) return true
                    else if (!default_id_country) return true
                    return false
                  }).map(c => (
                    <option value={c.id_country} key={`country-${c.id_country}`}>{c[`name_${lang.suffix}`]}</option>
                  ))}
                </select>
              </div>
            </div>

            <button className="btn-medium-secondary center-text add-btn" disabled={disabled} type="submit">
              <i className={`fas ${creatingaddress || updatingaddress ? 'fa-spin fa-spinner' : 'fa-plus'}`}></i> {isUpdate ? l('button-save-changes', lang) : l('button-create', lang)}
            </button>
            <PageLink to={'?'} className="btn-medium-secondary center-text add-btn" disabled={disabled} onClick={cancel}>
              <i className={`fas fa-times mr-1`}></i>{l('button-cancel', lang)}
            </PageLink>
          </form>
        </div>
      )}
    />
  )
}

AccountAddressForm.propTypes = {
  address: PropTypes.object,
  lang: PropTypes.object,
  cancel: PropTypes.func
}

export default AccountAddressForm

import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { context } from "../../../store"
import { page } from "../../../lib/page"
import { t, l } from "../../../lib/locale"

const ERR_INVALID_EMAIL = "error-checkout-INVALID_EMAIL"

const AccountCreateForm = ({ lang, switcher }) => {
  const [step, setStep] = useState(1)
  const [gender, setGender] = useState("")
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [newsletter, setNewsletter] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [terms, setTerms] = useState(false)
  const [error, setError] = useState(null)

  const { store, sessionProvider, cartProvider } = useContext(context)
  const { disabled } =
    store && store.session ? store.session : { disabled: false }

  let valid = false
  if (step === 1) {
    if (email) valid = true
  } else if (step === 2) {
    if (gender && lastname && firstname) valid = true
  } else if (step === 3) {
    if (password && terms && privacy) valid = true
  }

  const goToNext = async e => {
    setError(null)
    if (e) e.preventDefault()
    if (valid) {
      if (step >= 1 && step <= 2) setStep(step + 1)
      else {
        const account = {
          email,
          password,
          gender,
          firstname,
          lastname,
          newsletter,
          lang,
        }
        try {
          const result = await sessionProvider.create(account)
          if (result) {
            await cartProvider.refresh()
            navigate(t(page("my-account"), "path", lang))
          }
        } catch (e) {
          if (e.type === "INVALID_EMAIL") setError(ERR_INVALID_EMAIL)
        }
      }
    }
  }

  const onCheck = e => {
    setError(null)
    if (e.target.name === "newsletter") setNewsletter(e.target.checked)
    if (e.target.name === "gdpr") setPrivacy(e.target.checked)
    if (e.target.name === "terms") setTerms(e.target.checked)
  }

  const goToPrevious = e => {
    setError(null)
    if (e) e.preventDefault()
    if (step >= 2 && step <= 3) setStep(step - 1)
    else setStep(1)
  }

  return (
    <div className="content d-flex flex-column justify-content-between login-right">
      <form onSubmit={goToNext}>
        <div className="inputs">
          {step === 1 ? (
            <>
              <h2>{l("create-account-step1", lang)}</h2>
              <div className="field">
                <p>{l("create-account-email", lang)}</p>
                <input value={email} onChange={e => setEmail(e.target.value)} />
              </div>
            </>
          ) : (
            ""
          )}
          {step === 2 ? (
            <>
              <h2>{l("create-account-step2", lang)}</h2>
              <div className="checkboxes">
                <p>{l("field-gender", lang)}</p>
                <div>
                  <input
                    className="un-cb login-checkbox"
                    type="checkbox"
                    id="woman"
                    checked={gender === 1}
                    onChange={e => setGender(1)}
                  />
                  <label htmlFor="woman">{l("field-gender-ms", lang)}</label>
                </div>
                <div>
                  <input
                    className="un-cb login-checkbox"
                    type="checkbox"
                    id="man"
                    checked={gender === 2}
                    onChange={e => setGender(2)}
                  />
                  <label htmlFor="man">{l("field-gender-mr", lang)}</label>
                </div>
              </div>
              <div className="field">
                <p>{l("field-firstname", lang)}</p>
                <input
                  value={firstname}
                  onChange={e => setFirstname(e.target.value)}
                />
              </div>
              <div className="field">
                <p>{l("field-lastname", lang)}</p>
                <input
                  value={lastname}
                  onChange={e => setLastname(e.target.value)}
                />
              </div>
            </>
          ) : (
            ""
          )}
          {step === 3 ? (
            <>
              <h2>{l("create-account-step3", lang)}</h2>
              <div className="field">
                <p>
                  {l("create-account-password", lang)} *
                  {l("label-mandatory", lang)}
                </p>
                <input
                  type="password"
                  className="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div className="checkboxes">
                <div>
                  <input
                    className="un-cb login-checkbox"
                    type="checkbox"
                    id="newsletter"
                    name="newsletter"
                    checked={newsletter}
                    onClick={onCheck}
                    style={{ verticalAlign: "top " }}
                  />
                  <label htmlFor="newsletter">
                    {l("account-newsletter-subscribe", lang)}.
                  </label>
                </div>
                <div>
                  <input
                    className="un-cb login-checkbox"
                    type="checkbox"
                    id="gdpr"
                    name="gdpr"
                    checked={privacy}
                    onClick={onCheck}
                    style={{ verticalAlign: "top " }}
                  />
                  <label className="login-checkbox-text" htmlFor="gdpr">
                    {l("create-account-accept-gdpr", lang)} *
                    {l("label-mandatory", lang)}
                  </label>
                </div>
                <div>
                  <input
                    className="un-cb login-checkbox"
                    type="checkbox"
                    id="terms"
                    name="terms"
                    checked={terms}
                    onClick={onCheck}
                    style={{ verticalAlign: "top " }}
                  />
                  <label className="login-checkbox-text" htmlFor="terms">
                    {l("checkout-agree-to", lang)}{" "}
                    {l("checkout-link-terms", lang)} {l("and", lang)}{" "}
                    {l("checkout-link-returnpolicy", lang)}. *
                    {l("label-mandatory", lang)}
                  </label>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="buttons d-md-flex">
            <button
              className={`${
                !valid || disabled ? "btn-medium-light" : "btn-medium-secondary"
              } login-btn`}
              type="submit"
              disabled={!valid || disabled}
            >
              {l("button-confirm", lang)}
            </button>
            {step > 1 ? (
              <button
                className="btn btn-link previous"
                onClick={goToPrevious}
                disabled={disabled}
              >
                {l("list-previous", lang)}
              </button>
            ) : (
              ""
            )}
          </div>

          {error && (
            <div className="alert alert-danger mt-2">{l(error, lang)}</div>
          )}
        </div>
      </form>
    </div>
  )
}

AccountCreateForm.propTypes = {
  lang: PropTypes.object,
  switcher: PropTypes.object,
}

export default AccountCreateForm

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PageLink, SKUAvailabilityLabel } from '../..'
import { skuURL } from '../../../lib/sku'
import { l } from '../../../lib/locale'

// @todo use correct url for giftlist item

const CartOverviewLineItem = ({ cartProvider, layoutProvider, item, disabled, lang }) => {
  const [quantity, setQuantity] = useState(+item.quantity)

  const updateLocalQuantity = (e) => {
    setQuantity(+e.target.value)
  }

  const updateRemoteQuantity = async (e) => {
    const error = await cartProvider.updateItem(item, quantity, lang)

    if (error) {
      setQuantity(item.quantity)
      layoutProvider.showCartResult({
        event: 'error',
        error: error.name,
        product: error.data.product
      })
    }
  }

  const removeItem = async (e) => {
    e.preventDefault()
    cartProvider.removeItem(item, lang)
  }

  return (
    <div className={`row shopping-bag-item`} key={`shopping-bag-item-${item.id}`}>
      <div className="col-3">
        <PageLink to={item.id_wishlist_product ? `/nl/wenslijsten/schenken?id=${item.id_wishlist}&item=${item.id_wishlist_product}` : skuURL(item, lang)}>
          {item.id_default_image ? (
            <img
              className="shopping-bag-item-img"
              src={`${process.env.GATSBY_CDN_URL}/p/${item.id_default_image.toString().split('').join('/')}/${item.id_default_image}-medium_default.jpg`}
              alt={item[`name_${lang.suffix}`]} />
          ) : (
            <img src="" alt={item[`name_${lang.suffix}`]} />
          )}
        </PageLink>
      </div>
      <div className="col-9">
        <div className="d-flex justify-content-between">
          <h4>{item.manufacturer_hidden ? "" : item.manufacturer_name}</h4>
        </div>

        {process.env.GATSBY_FEATURE_WISHLIST && (
          <svg className="shopping-bag-item-wishlist" width="31" height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.3466 7.45724C28.9771 5.41167 27.9116 3.21444 25.2244 2.13916L25.0824 2.08277C23.986 1.66174 22.8099 1.48907 21.6388 1.57721C19.1557 1.76193 16.941 3.22027 15.5857 5.30862L15.5001 5.4389L15.4146 5.30862C14.0535 3.22027 11.8446 1.76193 9.36148 1.57721C8.19035 1.48907 7.01424 1.66174 5.91785 2.08277L5.77591 2.13916C3.08867 3.21444 2.02311 5.41167 1.65366 7.45724C1.15199 10.23 1.90644 13.0689 3.5184 15.379C7.01842 20.4131 14.0515 26.3146 15.3796 27.4171C15.4113 27.4433 15.4512 27.4577 15.4924 27.4577C15.5335 27.4577 15.5734 27.4433 15.6051 27.4171C16.941 26.3146 23.9663 20.417 27.4663 15.379C29.0938 13.0689 29.8483 10.23 29.3466 7.45724Z" stroke="#1B234B" strokeWidth="2" strokeMiterlimit="10"></path>
          </svg>
        )}

        <div className="d-flex justify-content-between">
          <h5>{item[`name_${lang.suffix}`]}{item.combination ? ` - ${item.combination}` : ''}</h5>
          {item.removed ? null : (
            <button className="btn btn-link btn-sm" disabled={disabled} onClick={removeItem}>
              <i className="fas fa-times"></i>
            </button>
          )}
        </div>

        <div className="d-flex">
          <p>&euro; {Number(item.sellPrice_tax_incl).toFixed(2)}</p>
          {+item.applied_reduction_quantity > 0 ? (
            <p><span className="badge badge-secondary ml-1">{item.required_reduction_quantity}+{item.applied_reduction_quantity}</span></p>
          ) : +item.applied_reduction_amount > 0 ? (
            <p><span className="badge badge-secondary ml-1">-{Number(item.applied_reduction_percent).toFixed(2)}&euro;</span></p>
          ) : +item.applied_reduction_percent > 0 ? (
            <p><span className="badge badge-secondary ml-1">-{Number(item.applied_reduction_percent).toFixed(0)}%</span></p>
          ) : ''}
          {item.giftlist_name ? (
            <p className="ml-3">
              <i className="fas fa-gift mr-1"></i> {item.giftlist_name}
            </p>
          ) : ''}
        </div>

        {item.removed ? (
          <div className="d-flex">
            <h5 className="badge badge-danger"><i className="fas fa-times"></i> {l('label-cartitem-removed', lang)}</h5>
          </div>
        ) : (
          <>
            <div className="d-flex">
              <SKUAvailabilityLabel sku={item} lang={lang} />
            </div>

            {!item.id_wishlist_product && (
              <div className="product-detail-right-similar-item-actions">
                <label htmlFor="quantity">{l('field-quantity', lang)}</label>
                <input type="number" id="quantity" placeholder="Aantal" value={quantity} onChange={updateLocalQuantity} onBlur={updateRemoteQuantity} disabled={disabled || item.removed} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

CartOverviewLineItem.propTypes = {
  cartProvider: PropTypes.object,
  layoutProvider: PropTypes.object,
  item: PropTypes.object,
  disabled: PropTypes.bool,
  lang: PropTypes.object
}

export default CartOverviewLineItem

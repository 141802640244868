import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import { l } from '../../../lib/locale'

const VoucherSideBar = ({ close, store, data, lang, provider }) => {
  const { cart, disabled, addingvoucher = false } = store && store.cart
    ? store.cart
    : { cart: null, disabled: false, addingvoucher: false }

  const [code, setCode] = useState('')
  const [error, setError] = useState()

  const cancel = (e) => {
    if (e) e.preventDefault()
    close()
  }

  const addVoucher = async (e) => {
    if (e) e.preventDefault()
    setError(null)
    const error = await provider.addVoucher(cart, code, () => { close() })
    if (error) {
      setError(error)
    }
  }

  const updateCode = (e) => setCode(e.target.value)

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center">
        <h3>{l('cart-have-promo-code', lang)}</h3>
        <button onClick={close} className="btn btn-link clickable">
          <i className="fas fa-times fa-lg"></i>
        </button>
      </div>

      <div className="article-to-be-deleted">
        <form className="" onSubmit={addVoucher}>
          <div className="row mb-5">
            <div className="field col-12">
              <label className="col-form-label" htmlFor="code">{l('field-vouchercode', lang)}</label>
            </div>
            <div className="field col-12">
              <input name="code" className="form-control" value={code} onChange={updateCode} disabled={disabled} />
            </div>
          </div>

          <button className="btn-medium-secondary center-text add-btn" disabled={disabled} type="submit">
            <i className={`fas ${addingvoucher ? 'fa-spin fa-spinner' : 'fa-plus'}`}></i> {l('button-add-voucher', lang)}
          </button>
          <PageLink to={'?'} className="btn-medium-secondary center-text add-btn" disabled={disabled} onClick={cancel}>
            <i className={`fas fa-times mr-1`}></i>{l('button-cancel', lang)}
          </PageLink>

          {error ? (
            <div className="row mt-3">
              <div className="col-12">
                <div className="alert alert-danger">
                  {l('error-add-voucher', lang)}
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  )
}

VoucherSideBar.propTypes = {
  close: PropTypes.func,
  store: PropTypes.object,
  data: PropTypes.any,
  lang: PropTypes.object
}

export default VoucherSideBar

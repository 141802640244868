import React, { useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { context } from '../../../store'
import { t, l } from '../../../lib/locale'

import { PageFooter, PageHeader, PageTheme, PageSideBarRight } from '../..'

// @todo
const title = 'Up North'
const defaultMeta = [
  // {
  //   property: 'p:domain_verify',
  //   content: '3edeb0476d512dd382503f6b07f4ebfe' // @todo move to config ? should be different per domain ?
  // }
]

const PageLayout = ({ children, lang, switcher, className, hideHeaderFooter, fullwidth, disableSearch }) => {
  const seo = switcher

  const { store, appProvider } = useContext(context)
  const { showsidebarright } = store && store.layout
    ? store.layout
    : { showsidebarright: false }

  const meta = [
    {
      name: `description`,
      content: seo && t(seo, 'meta_description', lang) ? t(seo, 'meta_description', lang) : t(seo, 'description_short', lang),
    },
    {
      name: `keywords`,
      content: seo && t(seo, 'meta_keywords', lang) ? t(seo, 'meta_keywords', lang) : '',
    },
    {
      property: `og:title`,
      content: seo && t(seo, 'meta_title', lang) ? t(seo, 'meta_title', lang) : t(seo, 'title', lang),
    },
    {
      property: `og:description`,
      content: seo && t(seo, 'meta_description', lang)
        ? t(seo, 'meta_description', lang)
        : seo && t(seo, 'description_short', lang)
          ? t(seo, 'description_short', lang)
          : t(seo, 'title', lang),
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: l('cart-summary', lang),
    },
    {
      name: `twitter:title`,
      content: seo && t(seo, 'meta_title', lang) ? t(seo, 'meta_title', lang) : t(seo, 'title', lang),
    },
    {
      name: `twitter:description`,
      content: seo && t(seo, 'meta_description', lang)
        ? t(seo, 'meta_description', lang)
        : seo && t(seo, 'description_short', lang)
          ? t(seo, 'description_short', lang)
          : t(seo, 'title', lang),
    },
  ]

  // set language in store
  useEffect(() => {
    appProvider.setLanguage(lang)
  }, [])

  // useEffect(() => {
  //   if (typeof window !== 'undefined' && process.env.GATSBY_REDIRECT_WWW) {
  //     const url = window.location.href
  //     if (url.includes('www.')) {
  //       window.location.replace(url.replace('www.', '').replace('http://', 'https://'))
  //     }
  //   }
  // }, [])

  return (
    <PageTheme>
      <Helmet
        htmlAttributes={{
          lang: lang.suffix
        }}
        bodyAttributes={{
          class: showsidebarright ? 'sidebar' : ''
        }}
        title={t(seo, 'meta_title', lang) ? t(seo, 'meta_title', lang) : t(seo, 'title', lang)}
        titleTemplate={`%s | ${title}`}
        meta={defaultMeta.concat(meta)}
      >
        <link rel="preconnect" href={process.env.GATSBY_API} />
        <link rel="preconnect" href={process.env.CDN} />
      </Helmet>
      {!hideHeaderFooter && (
        <>
          <PageSideBarRight lang={lang} />
          <PageHeader lang={lang} switcher={switcher} disableSearch={disableSearch} />
        </>
      )}
      <section>
        {fullwidth ? children : (
          <div className={`container ${className ? className : ''}`}>
            {children}
          </div>
        )}
      </section>
      {!hideHeaderFooter && (
        <PageFooter lang={lang} />
      )}
    </PageTheme>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.object,
  switcher: PropTypes.object,
  className: PropTypes.string,
  hideHeaderFooter: PropTypes.bool,
  fullwidth: PropTypes.bool,
  disableSearch: PropTypes.bool
}

export default PageLayout

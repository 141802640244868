import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { context } from '../../../store'
import { l } from '../../../lib/locale'

const CartAddSKUButton = ({ sku, title, lang, className, classButton, invalid, amount, message, giftlist }) => {
  const { store, cartProvider, layoutProvider } = useContext(context)
  const { disabled, adding } = store && store.cart
    ? store.cart
    : { disabled: false, adding: false }

  const initialized = store.app.initialized

  const add2cart = async (e) => {
    e.preventDefault()
    const quantity = 1
    const error = await cartProvider.addItem(sku, quantity, lang, { amount: amount, message: message }, () => {
      layoutProvider.showCartResult({
        event: 'addItem',
        sku: sku,
        quantity: quantity
      })
    })
    if (error) {
      layoutProvider.showCartResult({
        event: 'error',
        error: error.name,
        product: error.data.product
      })
    }
  }

  const enabled = (sku && sku._links.add_to_cart && !invalid)

  return (
    <button className={`${classButton || 'btn-add-to-cart'} ${className}`} disabled={disabled || !enabled || !initialized} onClick={add2cart}>
      <i className={`mr-1 fas ${enabled && sku && adding === sku.id_sku ? 'fa-spin fa-spinner' : enabled ? 'fa-cart-plus' : 'fa-times'}`}></i>
      {giftlist ? `${l('button-addtocart-giftlist', lang)} ${giftlist.name}` : l(title || 'button-addtocart', lang)}
    </button>
  )
}

CartAddSKUButton.propTypes = {
  sku: PropTypes.object,
  lang: PropTypes.object,
  className: PropTypes.string,
  invalid: PropTypes.bool,
  amount: PropTypes.number,
  message: PropTypes.string,
  giftlist: PropTypes.object
}

export default CartAddSKUButton

import React, { useState, useContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
import { PageLink } from '../..'
import { context } from '../../../store'
import callAPI from '../../../lib/callAPI'
import { l } from '../../../lib/locale'

const AccountOrdersList = ({ setSelected, lang }) => {
  const { store } = useContext(context)
  const [result, setResult] = useState()

  const selectOrder = (order) => {
    setSelected(order.id_order)
  }

  const fetchOrderList = useCallback(
    () => {
      return callAPI(store, store.app.endpoints.my_orders)
    },
    [store]
  )

  useEffect(() => {
    const load = async () => {
      const result = await fetchOrderList()
      setResult(result)
    }
    load()
  }, [fetchOrderList])

  return (
    <section className="giftlist">
      {result && result._embedded && result._embedded.orders ? (
        <>
          <div className="row">
        		<div className="header col-md-10 d-md-flex justify-content-md-between">
        			<div className="container">
        				<div className="row">
        					<div className="col-md-3">
        						<p>{l('orders-reference', lang)}</p>
        					</div>
        					<div className="col-md-3">
        						<p>{l('orders-date', lang)}</p>
        					</div>
                  <div className="col-md-2">
        						<p>{l('orders-amount', lang)}</p>
        					</div>
                  <div className="col-md-4">
        						<p>{l('orders-state', lang)}</p>
        					</div>
        				</div>
        			</div>
        		</div>
        	</div>

        	<div className="row ">
        		<hr className="divider" />
        	</div>

        	<div className="container gift">
            {result._embedded.orders.map(o => {
              const orderstate = result._embedded.orderstates.find(s => +s.id_order_state === +o.current_state)

              return (
                <React.Fragment key={`order-${o.id_order}`}>
                  <div className="row">
              			<div className="listdata col col-md-10 d-md-flex align-items-md-center">
              				<div className="container">
              					<div className="row">
              						<div className="col-md-3 firstname d-flex align-items-center">
              							<p>{o.reference}</p>
              						</div>
              						<div className="col-md-3 created-at d-flex align-items-center">
              							<p>{dateFormat(o.date_add, 'mm/dd/yyyy HH:MM')}</p>
              						</div>
                          <div className="col-md-2 created-at d-flex align-items-center">
              							<p>&euro; {Number(o.total_paid).toFixed(2)}</p>
              						</div>
                          <div className="col-md-4 created-at d-flex align-items-center">
              							<p>{orderstate[`name_${lang.suffix}`]}</p>
              						</div>
              					</div>
              				</div>
              			</div>
                    {process.env.GATSBY_FEATURE_ORDERDETAILS && (
                      <div className="col-12 col-md-2 buttons">
                        <PageLink to={`?id=${o.id_order}`} className="btn-medium-secondary change" onClick={e => selectOrder(o)}>
                          <i className={`fas fa-chevron-right`}></i> details
                        </PageLink>
                      </div>
                    )}
              		</div>
              		<hr />
                </React.Fragment>
              )
            })}
        	</div>
        </>
      ) : (
        <div className="alert"></div>
      )}
    </section>
  )
}

AccountOrdersList.propTypes = {
  setSelected: PropTypes.func,
  lang: PropTypes.object
}

export default AccountOrdersList

import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { l } from '../../../lib/locale'
import { context } from '../../../store'

const NewsletterSubscriptionForm = ({ lang }) => {
  const store = useContext(context)
  const { appProvider } = store

  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const subscribe = (e) => {
    if (e) e.preventDefault()
    appProvider.newsletterSubscribe(email, firstName, lastName, lang.id_lang)
    setSuccess(true)
  }

  return (
    <form onSubmit={subscribe}>
      <h4>{l('Stay up to date!', lang)}</h4>
      {success ? (
        <p className="newsletter-success-message">
          {l('msg-newsletter-subscribed', lang)}
        </p>
      ) : (
        <>
          <div className="row">
            <div className="col-md-6">
              <div className="">
                <input className="form-control" value={firstName} placeholder={l('field-firstName-placeholder', lang)} onChange={e => setFirstName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="">
                <input className="form-control" value={lastName} placeholder={l('field-lastName-placeholder', lang)} onChange={e => setLastName(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <input className="form-control" value={email} placeholder={l('field-email-placeholder', lang)} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="col-md-6">
              <button className="btn btn-primary btn-block" type="submit">
                {l('button-newsletter-subscribe', lang)}
              </button>
            </div>
          </div>
        </>
      )}
    </form>
  )
}

NewsletterSubscriptionForm.propTypes = {
  lang: PropTypes.object
}

export default NewsletterSubscriptionForm

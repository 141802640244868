import React from 'react'
import PropTypes from 'prop-types'
import { PageLink, SKUPromoLabel } from '../..'
import { productURL } from '../../../lib/product'
import { l } from '../../../lib/locale'
import ProductListTileImage from './image'

const ProductListTileViewCombination = ({ product, sku, lang, giftlist, cartOnly, navigation, className, add2cart, add2giftlist, disabled, adding, inView, customer, cartrules }) => {
  const product_cart_rules = cartrules
    ? cartrules.filter(i =>
      (product.available_for_order && i.rule_group_type === 'products' && i.items && i.items.includes(+product.id_product) && i.rule_group_quantity <= 2)
      || (product.available_for_order && i.rule_group_type === 'manufacturers' && i.items && i.items.includes(+product.id_manufacturer))
    )
    : null

  return (
    <PageLink to={giftlist ? `?id=${product.id_giftlist}&item=${product.id_giftlist_product}` : productURL(product, lang)}>
      <div className="product-photo combination">
        <div className="overlay">
          <div className="actions d-flex justify-content-center align-items-center">
            <button className="btn btn-primary btn-product-action clickable" disabled={!product._links.add_to_cart || disabled} onClick={add2cart}>
              <i className={`fas fa-cart-plus`}></i>
            </button>
            {false && !cartOnly && !giftlist && process.env.GATSBY_FEATURE_GIFTLIST && customer && (
              <button className="btn btn-primary btn-product-action clickable" disabled={disabled} onClick={add2giftlist}>
                <i className={`fas fa-gift`}></i>
              </button>
            )}
            {false && !cartOnly && !giftlist && process.env.GATSBY_FEATURE_WISHLIST && (
              <button className="btn btn-primary btn-product-action clickable" disabled={disabled}>
                <i className={`fas fa-heart`}></i>
              </button>
            )}
          </div>
        </div>

        {cartOnly ? '' : (
          <div className="product-labels">
            {product_cart_rules && product_cart_rules.map(cr => <SKUPromoLabel key={`product-cart-rule-${cr.id_cart_rule}`} cartrule={cr} product={product} sku={sku} lang={lang} />)}
            {product.new ? <span className="product-labels-new">NIEUW</span> : ''}
          </div>
        )}

        <ProductListTileImage product={product} sku={sku} lang={lang} inView={inView} />
      </div>
      <div>
        <div className="brand">
          <span>{product.manufacturer_hidden ? " " : product.manufacturer_name}</span>
          <span className="float-right mr-1">
            <i>{l('price-from', lang)}</i>
          </span>
        </div>
        <div className="name-price row no-gutters">
          <div className="col-8 name">
            <h3>{product[`name_${lang.suffix}`]}</h3>
          </div>
          <div className="col-4 price">
            <h3><i>&euro; {Number(product.sellPrice).toFixed(2)}</i></h3>
          </div>
        </div>
      </div>
    </PageLink>
  )
}

ProductListTileViewCombination.propTypes = {
  product: PropTypes.object.isRequired,
  sku: PropTypes.object,
  lang: PropTypes.object,
  giftlist: PropTypes.bool,
  cartOnly: PropTypes.bool,
  navigation: PropTypes.object,
  className: PropTypes.string,
  add2cart: PropTypes.func,
  add2giftlist: PropTypes.func,
  disabled: PropTypes.bool,
  adding: PropTypes.any,
  inView: PropTypes.bool
}

export default ProductListTileViewCombination

import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { context } from '../../../store'
import { l } from '../../../lib/locale'

const AccountNewsletters = ({ lang }) => {
  const { store, sessionProvider } = useContext(context)
  const { disabled, subscribing, unsubscribing } = store;

  const [newsletter, setNewsletter] = useState()

  useEffect(() => {
    const initialize = async () => {
      const result = await sessionProvider.getNewsletter()
      setNewsletter(result)
    }
    initialize()
  }, [sessionProvider])

  const toggleNewsletter = async (e) => {
    e.preventDefault()
    if (newsletter) {
      if (e.target.checked) {
        const result = await sessionProvider.subscribeNewsletter(newsletter)
        setNewsletter(result)
      } else {
        const result = await sessionProvider.unsubscribeNewsletter(newsletter)
        setNewsletter(result)
      }
    }
  }

  if (newsletter) {
    return (
      <section className="account-block">
        <h3>{l('account-newsletters', lang)}</h3>
        <div className="checkboxes">
          <div>
            {subscribing || unsubscribing ? (
              <i className="fas fa-spin fa-spinner"></i>
            ) : (
              <input className="un-cb" type="checkbox" id="newsletter" checked={newsletter.subscribed} disabled={disabled} onChange={toggleNewsletter} />
            )}

            <label htmlFor="newsletter">{l('account-newsletter-subscribe', lang)}</label>
          </div>
        </div>
      </section>
    )
  } else {
    return ''
  }
}

AccountNewsletters.propTypes = {
  lang: PropTypes.object
}

export default AccountNewsletters

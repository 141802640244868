import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { PageLink, SKUSelector, PageBreadcrumb, SKUGiftListSelector, SKUPromoLabel, ProductInlineListItem } from '../..'
import { l, t } from '../../../lib/locale'
import { pageURL } from '../../../lib/page'
import { productURL, productBrandURL, stripStyleTag } from '../../../lib/product'
import { skuURL } from '../../../lib/sku'
import { context } from '../../../store'
import callAPI from '../../../lib/callAPI'
import { campaigns } from '../../../config/campaigns.json'

// @todo
// default no image ?

const SKUPage = (props) => {
  const { attributes, attribute_groups, lang, isGiftlist, giftlist, reduction, productfamily, productfamilyitems } = props
  const { store } = useContext(context)

  const [product, setProduct] = useState(props.product)
  const [sku, setSKU] = useState(props.sku)
  const [items, setItems] = useState(productfamilyitems)

  useEffect(() => {
    callAPI(store, product._links.self).then(result => {
      if (result && +result.id_product === +product.id_product) {
        result._embedded = props.product._embedded
        setProduct(result)
      }
    })
  }, [product.id_product, lang.id_lang])

  useEffect(() => {
    callAPI(store, sku._links.self).then(result => {
      if (result && +result.id_product_attribute === +sku.id_product_attribute) {
        result._embedded = props.sku._embedded
        setSKU(result)
      }
    })
  }, [sku.id_product_attribute, lang.id_lang])

  useEffect(() => {
    if (productfamily && productfamily._links && productfamily._links.items) {
      callAPI(store, productfamily._links.items).then(result => {
        if (result && result._embedded) setItems(result._embedded.skus)
      })
    }
  }, [productfamily, lang.id_lang])

  const [activeTabAbout, setActiveTabAbout] = useState('description')
  const [activeImage, setActiveImage] = useState(sku ? sku.id_default_image : product.id_default_image)

  const showActiveTabAbout = (e, tab) => {
    if (e) e.preventDefault()
    setActiveTabAbout(tab)
  }

  const showImage = (e, image) => {
    if (e) e.preventDefault()
    setActiveImage(image.id_image)
  }

  const onSKUSelected = (sku) => {
    if (sku) {
      navigate(skuURL(sku, lang))
    } else {
      navigate(productURL(product, lang))
    }
  }


  let sellPrice = sku.sellPrice
  // if (promo) {
  //   if (promo.reduction_type === "percentage") sellPrice = sellPrice * (1 - promo.reduction)
  //   // if (promo.reduction_type === "amount") sellPrice = sellPrice * (1 - promo.reduction) // @todo
  // }

  const breadcrumbs = ['homepage']
  if (isGiftlist) breadcrumbs.push('giftlist-buy')

  const cartrules = store.app.cartrules || null
  const product_cart_rules = cartrules
    ? cartrules.filter(i => (
      (i.rule_group_type === 'products' && i.items && i.items.includes(+sku.id_product) && i.rule_group_quantity <= 2)
      || (i.rule_group_type === 'manufacturers' && i.items && i.items.includes(+sku.id_manufacturer)))
    )
    : null
  const active_cart_rules = cartrules
    ? cartrules.filter(i =>
      (i.rule_group_type === '' || i.rule_group_type === null)
    )
    : null

  let promo = null
  let promoPrice = null
  let promoFlag = null

  if (isGiftlist) {
    if (+sku.reduction_percent > 0) {
      promoPrice = +sku.sellPrice * (1 - +sku.reduction_percent/100)
      promoFlag = `- ${Number(sku.reduction_percent).toFixed(0)}%`
    } else if (+sku.reduction_amount > 0) {
      promoPrice = +sku.sellPrice - +sku.reduction_amount
      promoFlag = `- €${Number(sku.reduction_amount).toFixed(2)}`
    }
  } else {
    if (product && product._embedded && product._embedded.promos && product._embedded.promos.length > 0) {
      promo = product._embedded.promos[0]
      if (promo.reduction_type === 'amount') {
        promoPrice = +sku.sellPrice - +promo.reduction
        promoFlag = `- €${Number(promo.reduction).toFixed(2)}`
      } else if (promo.reduction_type === 'percentage') {
        promoPrice = +sku.sellPrice * (1 - +promo.reduction)
        promoFlag = `- ${Number(promo.reduction * 100).toFixed(0)}%`
      }
    } else if (reduction) {
      if (reduction.reduction_type === 'percentage') {
        promo = true
        promoPrice = +sku.sellPrice * (1 - reduction.reduction)
        promoFlag = `- ${Number(reduction.reduction * 100).toFixed(0)}%`
      }
    } else if (product_cart_rules) {
      // @todo
      const product_cart_rule = product_cart_rules[0]
      if (product_cart_rule && +product_cart_rule.reduction_percent > 0 && product_cart_rule.rule_group_quantity === 1) {
        promoPrice = +sku.sellPrice * (1 - product_cart_rule.reduction_percent/100)
        promoFlag = `- ${Number(product_cart_rule.reduction_percent).toFixed(0)}%`
      }
    }
  }

  let banner = null, campaign = null
  if (campaigns && campaigns.length > 0) {
    if (product_cart_rules && product_cart_rules.length > 0) {
      product_cart_rules.forEach(rule => {
        const c = campaigns.find(c => c.code === rule.code || c.code === rule.display_code)
        if (c) {
          campaign = c
          banner = campaign.banner
        }
      })
    }
    if (active_cart_rules && active_cart_rules.length > 0) {
      active_cart_rules.forEach(rule => {
        const c = campaigns.find(c => c.code === rule.code || c.code === rule.display_code)
        if (c) {
          campaign = c
          banner = campaign.banner
        }
      })
    }
  }

  return (
    <div className="sku-page product-list-breadcrumbs">
      {isGiftlist ? (
        <PageLink className="btn btn-link" to={`${pageURL('giftlist-buy', lang)}?id=${giftlist.id_giftlist}`}>
          <i className="fas fa-chevron-left mx-2"></i>
          Terug naar: {giftlist.name}
        </PageLink>
      ) : (
        <PageBreadcrumb
          lang={lang}
          current={{ path_en: skuURL(sku, lang), path_nl: skuURL(sku, lang), title_en: sku.name_en, title_nl: sku.name_nl , ...sku }}
          items={breadcrumbs} />
      )}

      <div className="row product-detail">
        <div className="col-md-6 product-detail-left">
          <div className="product-detail-right-title-mobile">
            <div className="h1">{product[`name_${lang.suffix}`]} - {sku[`combination_${lang.suffix}`]}</div>
            {sku.manufacturer_hidden ? "" : (
              <div className="h2"><PageLink to={productBrandURL(product, lang)}>{sku.manufacturer_name}</PageLink></div>
            )}
            {product.is_voucher
              ? null
              : promo || (product_cart_rules && product_cart_rules.length > 0 && +promoPrice > 0)
                ? (
                  <>
                    <h4 className="d-inline-block mx-2"><strike>&euro; {Number(sku.sellPrice).toFixed(2)}</strike></h4>
                    <h3 className="d-inline-block mx-2">&euro; {Number(promoPrice).toFixed(2)}</h3>
                    <h4 className="d-inline-block">
                      <span className="badge badge-secondary">{promoFlag}</span>
                    </h4>
                  </>
                ) : (
                  <h3>&euro; {Number(sku.sellPrice).toFixed(2)}</h3>
                )
            }
          </div>

          {isGiftlist ? (
            <div className="product-labels">
            </div>
          ) : (
            <div className="product-labels">
              {product_cart_rules && product_cart_rules.map(cr => <SKUPromoLabel key={`product-cart-rule-${cr.id_cart_rule}`} cartrule={cr} product={product} sku={sku} lang={lang} />)}
              {!sku._links.add_to_cart ? <span className="product-labels-promo mr-1">OUT OF STOCK</span> : ''}
              {sku._links.add_to_cart && sku.new ? <span className="product-labels-new mr-1">NIEUW</span> : ''}
              {sku.on_sale ? <span className="product-labels-promo mr-1">PROMO</span> : ''}
              {promo && promo.reduction_type === "percentage" ? <span className="product-labels-promo mr-1">-{Number(promo.reduction * 100).toFixed(0)}%</span> : ''}
              {promo && promo.reduction_type === "amount" ? <span className="product-labels-promo mr-1">-{Number(promo.reduction).toFixed(2)}€</span> : ''}
            </div>
          )}

          {activeImage ? (
            <img
              className="img-fluid"
              src={`${process.env.GATSBY_CDN_URL}/p/${activeImage.toString().split('').join('/')}/${activeImage}-large_default.jpg?v=${process.env.GATSBY_BUILD_VERSION}`}
              alt={sku[`name_${lang.suffix}`]} />
          ) : sku.id_default_image && sku.id_default_image > 0 ? (
            <img
              className="img-fluid"
              src={`${process.env.GATSBY_CDN_URL}/p/${sku.id_default_image.toString().split('').join('/')}/${sku.id_default_image}-large_default.jpg?v=${process.env.GATSBY_BUILD_VERSION}`}
              alt={sku[`name_${lang.suffix}`]} />
          ) : null}

          {sku._embedded && sku._embedded.images && sku._embedded.images.length > 0 && (
            <div className="row product-detail-left-slider-images">
              {sku._embedded.images.slice(0,3).map(image => (
                <div className="col-4" key={`product-detail-left-slider-image-${image.id_image}`}>
                  <button className="btn btn-link p-0" onClick={e => showImage(e, image)}>
                    <img
                      className="img-fluid"
                      src={`${process.env.GATSBY_CDN_URL}/p/${image.id_image.toString().split('').join('/')}/${image.id_image}-home_default.jpg?v=${process.env.GATSBY_BUILD_VERSION}`}
                      alt={sku[`name_${lang.suffix}`]} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="col-md-6 product-detail-right">
          <div className="product-detail-right-title">
            <h1>{product[`name_${lang.suffix}`]}{sku[`combination_${lang.suffix}`] ? ` - ${sku[`combination_${lang.suffix}`]}` : ''}</h1>
            {sku.manufacturer_hidden ? "" : (
              <h2><PageLink to={productBrandURL(product, lang)}>{sku.manufacturer_name}</PageLink></h2>
            )}
            {product.is_voucher ? null : (isGiftlist && promoPrice) ? (
              <>
                <h4 className="d-inline-block mx-2"><strike>&euro; {Number(sku.sellPrice).toFixed(2)}</strike></h4>
                <h3 className="d-inline-block mx-2">&euro; {Number(promoPrice).toFixed(2)}</h3>
                <h4 className="d-inline-block">
                  <span className="badge badge-secondary">{promoFlag}</span>
                </h4>
              </>
            ) : promo || (product_cart_rules && product_cart_rules.length > 0 && +promoPrice > 0) ? (
              <>
                <h4 className="d-inline-block mx-2"><strike>&euro; {Number(sku.sellPrice).toFixed(2)}</strike></h4>
                <h3 className="d-inline-block mx-2">&euro; {Number(promoPrice).toFixed(2)}</h3>
                <h4 className="d-inline-block">
                  <span className="badge badge-secondary">{promoFlag}</span>
                </h4>
              </>
            ) : (
              <h3>&euro; {Number(sku.sellPrice).toFixed(2)}</h3>
            )}
          </div>

          <SKUSelector
            product={product}
            sku={sku}
            attributes={attributes}
            attribute_groups={attribute_groups}
            lang={lang}
            onChange={onSKUSelected}
            giftlist={giftlist} />

          {campaign && banner && banner.small && (
            <div className="product-detail-right-banner mb-4">
              {banner.small.image && (
                <img src={banner.small.source} className="" alt={campaign.code} />
              )}
            </div>
          )}

          <div className="product-detail-right-description">
            <div dangerouslySetInnerHTML={{ __html: stripStyleTag(sku[`description_short_${lang.suffix}`]) }} />
            {process.env.GATSBY_FEATURE_PRODUCT_FEATURES && (
              <>
                <ul>
                  <li><span>Materiaal:</span> Metaal</li>
                  <li><span>Afmetingen:</span> 45cmx80xm</li>
                  <li><span>Productkenmerk:</span> Een beschrijving</li>
                  <li><span>Materiaal:</span> Een beschrijving</li>
                </ul>
                <a href="/">Bekijk alle productkenmerken</a>
              </>
            )}
          </div>

          <SKUGiftListSelector
            product={product}
            sku={sku}
            lang={lang} />

          {productfamily ? (
            <div className="product-detail-right-family mt-4">
              <h3>{l('more-from', lang)} <PageLink to={t(productfamily, 'link', lang)}>{t(productfamily, 'name', lang)}</PageLink></h3>
              {items && items.filter(i => +i.id_product != +product.id_product).sort((a,b) => +b.id_product - +a.id_product).map(i => (
                <ProductInlineListItem
                  key={`productfamily-${i.id_product}-${i.id_product_attribute}`}
                  sku={i}
                  lang={lang}
                  attributes={attributes}
                  attribute_groups={attribute_groups} />
              ))}
            </div>
          ) : ''}

        </div>
      </div>

      <div className="product-about">
        <div className="row product-about-title">
          <button className="col-md-3 btn bnt-link" onClick={e => showActiveTabAbout(e, 'description')}>
            <h4 className={`only-desktop ${activeTabAbout === 'description' ? 'product-about-title-active' : 'product-about-title-not-active'}`}>
              {l('product-about-description', lang)}
            </h4>
          </button>
          {process.env.GATSBY_FEATURE_PRODUCT_COLLECTION && (
            <button className="col-md-3 btn bnt-link" onClick={e => showActiveTabAbout(e, 'collection')}>
              <h4 className={`only-desktop ${activeTabAbout === 'collection' ? 'product-about-title-active' : 'product-about-title-not-active'}`}>
                {l('product-about-collection', lang)}
              </h4>
            </button>
          )}
          {sku.manufacturer_hidden ? "" : (
            <PageLink className="col-md-3 btn bnt-link" to={productBrandURL(product, lang)}>
              <h4 className={`only-desktop ${activeTabAbout === 'brand' ? 'product-about-title-active' : 'product-about-title-not-active'}`}>
                {l('product-about-brand', lang)}
              </h4>
            </PageLink>
          )}
        </div>

        <div className="row product-about-title only-mobile">
          <h4 className="col-12 product-about-title-active">{l('product-about-description', lang)}</h4>
        </div>
        <div className={`row ${activeTabAbout === 'description' ? '' : 'd-none'}`}>
          <div className="col-12" dangerouslySetInnerHTML={{ __html: stripStyleTag(product[`description_${lang.suffix}`]) }} />
        </div>

        {process.env.GATSBY_FEATURE_PRODUCT_COLLECTION && (
          <>
            <div className="row product-about-title only-mobile">
              <h4 className="col-12 product-about-title-active">{l('product-about-collection', lang)}</h4>
            </div>
            <div className={`row ${activeTabAbout === 'collection' ? '' : 'd-none'}`}>
            </div>
          </>
        )}

        {sku.manufacturer_hidden ? "" : (
          <div className="row product-about-title only-mobile">
            <PageLink className="col-md-3 btn bnt-link" to={productBrandURL(product, lang)}>
              <h4 className="col-12 product-about-title-active">{l('product-about-brand', lang)}</h4>
            </PageLink>
          </div>
        )}
      </div>
    </div>
  )
}

SKUPage.propTypes = {
  product: PropTypes.object.isRequired,
  sku: PropTypes.object.isRequired,
  attributes: PropTypes.array,
  attribute_groups: PropTypes.array,
  lang: PropTypes.object,
  isGiftlist: PropTypes.bool,
  giftlist: PropTypes.object
}

export default SKUPage

import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PageLayout } from '../..'
import { context } from '../../../store'

const CheckoutLayout = (props) => {
  const { store, cartProvider } = useContext(context)
  const { cart, checkout } = store && store.cart
    ? store.cart
    : { cart: null, checkout: null }

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const initialize = async () => {
      if (store.app.initialized && !checkout) {
        setLoading(true)
        await cartProvider.getCheckout()
        setLoading(false)
      }
    }

    initialize()
  }, [store.app.initialized])

  if (loading) {
    return null
  } else if (props.allowClosedCart && checkout) {
    return (
      <PageLayout className="checkout" {...props}>
        {props.children}
      </PageLayout>
    )
  } else if (cart && checkout) {
    return (
      <PageLayout className="checkout" {...props}>
        {props.children}
      </PageLayout>
    )
  } else {
    return null
  }
}

CheckoutLayout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.object,
  switcher: PropTypes.object,
  className: PropTypes.string,
  allowClosedCart: PropTypes.bool
}

export default CheckoutLayout

import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { PageLink, PageBreadcrumb } from '../..'
import { t } from '../../../lib/locale'
import { brandURL } from '../../../lib/brand'

import './brandlist.scss'

require('intersection-observer')

const BrandItem = ({ brand, lang }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  return (
    <PageLink to={brandURL(brand, lang)} key={`brand-${brand.id_manufacturer}`}>
      <div className="card card-brand" ref={ref}>
        {inView && (
          <img
            src={`${process.env.GATSBY_CDN_URL}/m/${brand.id_manufacturer}.jpg`}
            className="card-img-top"
            alt={brand.name} />
        )}
      </div>
    </PageLink>
  )
}

const BrandListPage = ({ brands, lang, page, breadcrumbs }) => {
  return (
    <div className="product-list-4 product-list-breadcrumbs">
      <PageBreadcrumb lang={lang} current={page} items={breadcrumbs} />

      <div className="row product-list-topbar align-items-end">
        <div className="col-12">
          <h2>{t(page, 'title', lang)}</h2>
        </div>
      </div>

      <div className="card-deck card-deck-brands mt-2">
        {brands ? brands.sort((a,b) => a.name.localeCompare(b.name)).map(brand => {
          if (brand.hidden || !brand.active) return ''
          else return (
            <BrandItem brand={brand} lang={lang} key={`brand-item-${brand.id_manufacturer}`} />
          )
        }) : ''}
      </div>
    </div>
  )
}

BrandListPage.propTypes = {
  brands: PropTypes.array,
  lang: PropTypes.object,
  page: PropTypes.object,
  breadcrumbs: PropTypes.array
}

export default BrandListPage

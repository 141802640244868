import React, { useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { PageLink } from '../..'
import { context } from '../../../store'
import { l, t } from '../../../lib/locale'
import { productURL } from '../../../lib/product'
import { skuURL } from '../../../lib/sku'
import callAPI from '../../../lib/callAPI'

import './searchinlineform.scss'

const { pages } = require('../../../config/pages.json')
const searchPage = pages.find(i => i.id === 'search')

const SearchInlineFormView = ({ products, disableInline, lang }) => {
  const { store } = useContext(context)

  let initialQuery = ''
  if (typeof window !== 'undefined') {
    const qs = queryString.parse(window.location.search)
    initialQuery = qs.q ? qs.q : ''
  }

  const [isVisible, setVisible] = useState(false)
  const [query, setQuery] = useState(initialQuery)
  const [results, setResults] = useState([])
  const [promos, setPromos] = useState(null)

  const clear = (e) => {
    setVisible(false)
    setQuery('')
    setResults([])
  }

  const close = (e) => {
    setVisible(false)
  }

  useEffect(() => {
    const search = async () => {
      if (query && query.length > 2) {
        try {
          const result = await callAPI(store, store.app.endpoints.search, { search: query, count: 3 })

          if (result && result._embedded && result._embedded.items) {
            const results = result._embedded.items
            setResults( results && results.length > 0 ? results : [] )
            setPromos( result && result._embedded && result._embedded.promos ? result._embedded.promos : null )
            setVisible(true)
            return true
          }
        } catch (e) {
          // @todo log error ?
          setResults([])
          setVisible(false)
        }
      }
      setVisible(false)
      return false
    }

    search()
  }, [query, lang, store])

  const goToSearch = (e) => {
    if (e) e.preventDefault()
    setVisible(false)
    setResults([])
    navigate(`${searchPage[`path_${lang.suffix}`]}?q=${query}`)
  }

  const fnAddPromoPrice = (sku) => {
    if (promos && promos.length > 0) {
      const promo = promos.find(p => +p.id_product === +sku.id_product)
      if (promo) {
        if (promo.reduction_type === 'amount' && +promo.reduction_tax === 1) {
          sku.promoPrice = +sku.sellPrice - +promo.reduction
          sku.reduction_amount = +promo.reduction
          sku.reduction_type = promo.reduction_type
        }
        else if (promo.reduction_type === 'percentage' && +promo.reduction_tax === 1) {
          sku.promoPrice = +sku.sellPrice * (1 - +promo.reduction)
          sku.reduction_amount = +sku.sellPrice - +sku.promoPrice
          sku.reduction_type = promo.reduction_type
        }
      }
    }
    return sku
  }

  return (
    <form className="search d-flex justify-content-lg-end" onSubmit={goToSearch}>

      <div className="input input-group">
        <div className="input-group-prepend">
          <button className="btn btn-primary" type="submit">
            <i className="fas fa-search"></i>
          </button>
        </div>
        <input type="text" className="form-control" placeholder={l('search-placeholder', lang)} value={query} onChange={e => setQuery(e.target.value.toLowerCase())} />
        <div className="input-group-append">
          <button className="btn btn-outline-secondary" type="button" onClick={clear}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>

      <div className={`dropdown-menu tetten ${!disableInline && isVisible ? 'd-none d-md-block show' : 'd-none'}`}>
        <div className="results d-flex flex-column">
          {results ? results.map(fnAddPromoPrice).map(sku => {
            return (
              <React.Fragment key={`result-dropdown-item-${sku.id_product}-${sku.id_product_attribute}`}>

                <div className="result dropdown-item">
                  <PageLink to={skuURL(sku, lang)} className=" d-flex align-items-center clickable">
                    <div>
                      {sku.id_default_image ? (
                        <img
                          src={`${process.env.GATSBY_CDN_URL}/p/${sku.id_default_image.toString().split('').join('/')}/${sku.id_default_image}-large_default.jpg?v=${process.env.GATSBY_BUILD_VERSION}`}
                          alt={t(sku, 'name', lang)} />
                      ) : (
                        <div className="no-image" />
                      )}
                    </div>
                    <div className="info">
                      {sku.manufacturer_hidden ? "" : (
                        <div className="brand">
                          <p>{sku.manufacturer_name}</p>
                        </div>
                      )}
                      <div className="name">
                        <p>{t(sku, 'name', lang)}{t(sku, 'combination', lang) ? ` - ${t(sku, 'combination', lang)}` : ''}</p>
                      </div>
                      {sku.is_voucher ? null : (
                        <div className="price">
                          {+sku.reduction_amount > 0 ? (
                            <>
                              <p><small><strike>&euro;{Number(sku.sellPrice).toFixed(2)}</strike></small> &euro;{Number(sku.promoPrice).toFixed(2)} <span className="badge badge-secondary">- &euro;{Number(sku.reduction_amount).toFixed(2)}</span></p>
                            </>
                          ) : (
                            <>
                              <p>&euro;{Number(sku.sellPrice).toFixed(2)}</p>
                            </>
                          )}

                        </div>
                      )}
                    </div>
                  </PageLink>
                </div>
                <div className="dropdown-divider"></div>
              </React.Fragment>
            )
          }) : ''}

          <PageLink to={`${searchPage[`path_${lang.suffix}`]}?q=${query}`} onClick={close} className="dropdown-item all-results d-flex justify-content-center clickable">
            {l('search-all-results', lang)}
          </PageLink>
        </div>
      </div>
    </form>
  )
}

const SearchInlineForm = ({ lang, disableInline }) => {
  return (
    <SearchInlineFormView
      lang={lang}
      disableInline={disableInline} />
  )
}

SearchInlineForm.propTypes = {
  lang: PropTypes.object,
  disableInline: PropTypes.bool
}

export default SearchInlineForm

import React from 'react'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import { l } from '../../../lib/locale'
import { pageURL } from '../../../lib/page'
import Image from './image'

import './contentblock.scss'

const ContentBlock = ({ title, paragraphs, image, buttonLink, buttonTitle, imageLeft, lang }) => {
  const imageBlock = (
    <div className={`col-md-6 order-1 ${imageLeft ? 'order-md-1' : 'order-md-2 mb-3 mb-md-0'}`}>
      <div className="content-block-wrapper-image">
        <Image src={image} alt={title} />
        <div className="bg-block-content-block-right"></div>
      </div>
    </div>
  )

  return (
    <div className="content-block margin-bottom">
      <div className="row content-block-wrapper">
        {imageBlock}
        <div className={`col-md-6 order-2 ${imageLeft ? 'order-md-2' : 'order-md-1'} mb-3`}>
          <h3>{title}</h3>
          {paragraphs.map((p,idx) => (
            <p dangerouslySetInnerHTML={{ __html: p }} key={`content-block-paragraph-${idx}`} />
          ))}
          {buttonTitle && (
            <p>
              <PageLink to={pageURL(buttonLink, lang)} className="btn-medium-secondary">
                {l(buttonTitle, lang)} <i className={`fas fa-chevron-right ml-1`}></i>
              </PageLink>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

ContentBlock.propTypes = {
  title: PropTypes.string,
  paragraphs: PropTypes.array,
  image: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonTitle: PropTypes.string,
  imageLeft: PropTypes.bool,
  lang: PropTypes.object
}

export default ContentBlock

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { PageLink } from '../..'
import { l } from '../../../lib/locale'
import { pageURL } from '../../../lib/page'
import { categoryURL } from '../../../lib/category'
import Image from './image'

import './highlightblocks.scss'

const ContentHighLightBlocks = ({ title, highlights, lang }) => {
  const data = useStaticQuery(graphql`
    query {
      allShopCategory {
        edges {
          node {
            id
            id_category
            path_en
            path_nl
            link_rewrite_en
            link_rewrite_nl
          }
        }
      }
    }
  `)

  return (
    <div className="container mb-5">
      <div className="row content-block-wrapper">
        {highlights ? highlights.map((highlight, idx) => {
          const link = highlight.page
            ? pageURL(highlight.page, lang)
            : highlight.id_category && data.allShopCategory.edges.find(i => +i.node.id_category === +highlight.id_category)
              ? categoryURL(data.allShopCategory.edges.find(i => +i.node.id_category === +highlight.id_category).node, lang)
              : pageURL('home', lang)

          return (
            <div className="col-md-6 mb-5" key={`content-highlight-block-${idx}`}>
              <PageLink className="highlightblock" to={link}>
                <Image src={highlight.image} alt={l(highlight.title, lang)} />
                <div className="mt-2 highlightblocktext">
                  <h3>{highlight.title}</h3>
                </div>
              </PageLink>
            </div>
          )
        }) : null}
      </div>
    </div>
  )
}

ContentHighLightBlocks.propTypes = {
  title: PropTypes.string,
  highlights: PropTypes.array,
  lang: PropTypes.object
}

export default ContentHighLightBlocks

import React from 'react'
import PropTypes from 'prop-types'

const GiftListPasswordForm = ({ lang }) => {
  return (
    <div className="giftlist-search">
      <input placeholder="Zoek een lijst op wachtwoord" />
      <button className="btn-large-secondary  button">Zoek</button>
    </div>
  )
}

GiftListPasswordForm.propTypes = {
  sku: PropTypes.object.isRequired,
  lang: PropTypes.object
}

export default GiftListPasswordForm

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const PageLink = ({ children, to, className, onClick, disabled, onMouseEnter }) => {
  if (disabled) {
    return (
      <button className={className} disabled={true}>
        {children}
      </button>
    )
  } else {
    return (
      <Link to={to} className={className} onClick={onClick} onMouseEnter={onMouseEnter}>
        {children}
      </Link>
    )
  }
}

PageLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  onMouseEnter: PropTypes.func
}

export default PageLink

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { PageLink } from '../..'

const Image = ({ src, alt, className }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: allFile(filter: {relativeDirectory: {eq: "category"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 700) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const file = data.placeholderImage.edges.find(({ node }) => node.childImageSharp.fluid.originalName === src)
  if (file) {
    return <Img className={className} fluid={file.node.childImageSharp.fluid} />
  }
  return ''
}

const CategoryLevel1View = ({ category, parent, children, navigation, lang }) => {
  return (
    <div className="category-1-detail product-list-breadcrumbs">
      <h2>{category[`name_${lang.suffix}`]}</h2>
      <div className="row">
        {children.map(item => (
          <div className="col-md-4 category-1-detail-col-item" key={`category-item-${item.id_category}`}>
            <PageLink to={item[`path_${lang.suffix}`]} className="category-1-detail">
              <Image src={`${item.id_category}.jpg`} className="img-fluid w-100" alt={item[`name_${lang.suffix}`]} />
              <h4 className="text-center pt-2">{item[`name_${lang.suffix}`]}</h4>
            </PageLink>
          </div>
        ))}
      </div>
    </div>
  )
}

CategoryLevel1View.propTypes = {
  category: PropTypes.object.isRequired,
  parent: PropTypes.object,
  children: PropTypes.array,
  products: PropTypes.array,
  breadcrumbs: PropTypes.array,
  navigation: PropTypes.object,
  lang: PropTypes.object
}

export default CategoryLevel1View

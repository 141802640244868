import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { ProductNotFoundPage, PageBreadcrumb, ProductList } from '../..'
import { DEFAULT_LIST_PAGE } from '../../../constants'
import { l, t } from '../../../lib/locale'
import { context } from '../../../store'
import callAPI from '../../../lib/callAPI'
import { campaigns } from '../../../config/campaigns.json'
import { pages } from '../../../config/pages.json'


const ProductFamilyPage = (props) => {
  const { lang } = props
  const { store } = useContext(context)

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const [productfamily, setProductFamily] = useState(props.productfamily)
  const [items, setItems] = useState(props.items)

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    Promise.all([
      callAPI(store, productfamily._links.self),
      callAPI(store, productfamily._links.items)
    ]).then(all => {
      if (all[0]) {
        setProductFamily(all[0])
      }
      if (all[1]) {
        setItems(all[1]._embedded.skus)
      }
    })
  }, [productfamily.id_productfamily, lang.id_lang])

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const breadcrumbs = ['homepage']
  const navigation = { page: DEFAULT_LIST_PAGE }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  if (!productfamily || +productfamily.active === 0) {
    return (
      <ProductNotFoundPage lang={lang} />
    )
  }

  return (
    <div className="product-list-4 product-list-breadcrumbs">
      <PageBreadcrumb
        lang={lang}
        current={{ title_nl: productfamily.name_nl, title_en: productfamily.name_en, ...productfamily }}
        items={breadcrumbs} />

      <ProductList
        title={productfamily[`name_${lang.suffix}`]}
        total={items ? items.length : 0}
        products={items}
        attributes={props.attributes}
        attribute_groups={props.attribute_groups}
        navigation={navigation}
        lang={lang} />
    </div>
  )
}

ProductFamilyPage.propTypes = {
  productfamily: PropTypes.object.isRequired,
  lang: PropTypes.object
}

export default ProductFamilyPage

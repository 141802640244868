import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ src, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(base: {eq: "banner-20201114.jpg"}, relativeDirectory: {eq: "banner"}) {
        childImageSharp {
          fluid(maxWidth: 700) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (data.placeholderImage) {
    return <Img alt={alt} fluid={data.placeholderImage.childImageSharp.fluid} />
  }
  return ''
}

export default Image

import React from 'react'
import PropTypes from 'prop-types'

import { CategoryLevel1View, CategoryLevel2View, CategoryLevel3View } from '../..'

const CategoryPage = ({ category, parent, children, products, attributes, attribute_groups, breadcrumbs, navigation, lang }) => {
  if (category.is_level1) {
    return (
      <CategoryLevel1View
        category={category}
        parent={parent}
        children={children}
        products={products}
        attributes={attributes}
        attribute_groups={attribute_groups}
        breadcrumbs={breadcrumbs}
        navigation={navigation}
        lang={lang} />
    )
  } else if (category.is_level2) {
    return (
      <CategoryLevel2View
        category={category}
        parent={parent}
        children={children}
        products={products}
        attributes={attributes}
        attribute_groups={attribute_groups}
        breadcrumbs={breadcrumbs}
        navigation={navigation}
        lang={lang} />
    )
  } else if (category.is_level3) {
    return (
      <CategoryLevel3View
        category={category}
        parent={parent}
        children={children}
        products={products}
        attributes={attributes}
        attribute_groups={attribute_groups}
        breadcrumbs={breadcrumbs}
        navigation={navigation}
        lang={lang} />
    )
  }

  return null
}

CategoryPage.propTypes = {
  category: PropTypes.object.isRequired,
  parent: PropTypes.object,
  children: PropTypes.array,
  products: PropTypes.array,
  attributes: PropTypes.array,
  attribute_groups: PropTypes.array,
  breadcrumbs: PropTypes.array,
  navigation: PropTypes.object,
  lang: PropTypes.object
}

export default CategoryPage

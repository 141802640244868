const { pages } = require('../../config/pages.json')

const page = (id) => {
  const p = pages.find(i => i.id === id)
  if (p) return p
  return pages.find(i => i.id === 'homepage')
}

const pageURL = (id, lang) => {
  const p = page(id)
  return p[`path_${lang.suffix}`]
}

module.exports = {
  page,
  pageURL
}

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { l } from '../../../lib/locale'

const suggested_countries = null

const CartCountrySelectorSideBar = ({ close, store, data, lang, provider }) => {
  const { cart, disabled } = store && store.cart
    ? store.cart
    : { cart: null, disabled: false, updating: false }

  const changeCountry = (e, country) => {
    if (e) e.preventDefault()
    provider.updateCountry(cart, country.id_country, lang, () => {
      close()
    })
  }

  return (
    <StaticQuery
      query={graphql`
        query CartCountrySelectorSideBarQuery {
          allShopCountry {
            edges {
              node {
                id_country
                name_en
                name_nl
              }
            }
          }
        }
      `}
      render={({ allShopCountry }) => {
        return (
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h2>{l('change delivery country', lang)}</h2>
              </div>
              <button onClick={close} className="btn btn-link clickable">
                <i className="fas fa-times fa-lg"></i>
              </button>
            </div>
            <div className="country-switch mt-3">
              {suggested_countries && suggested_countries.length > 0 ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="modal-title">Voorgestelde landen</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 country-switch-items-item">
                      <button className="btn btn-link">Nederland</button>
                    </div>
                    <div className="col-md-6 country-switch-items-item">
                      <button className="btn btn-link">Nederland</button>
                    </div>
                    <div className="col-md-6 country-switch-items-item">
                      <button className="btn btn-link">Nederland</button>
                    </div>
                    <div className="col-md-6 country-switch-items-item">
                      <button className="btn btn-link">Nederland</button>
                    </div>
                  </div>
                </>
              ) : ''}
              <div className="row mt-3">
                <div className="col-12">
                  <h5 className="modal-title">{l('top-other-countries', lang)}</h5>
                </div>
              </div>
              <div className="row">
                {allShopCountry.edges.map(({ node }) => (
                  <div className="col-md-6 country-switch-items-item" key={`country-all-${node.id_country}`}>
                    <button className="btn btn-link" onClick={e => changeCountry(e, node)} disabled={disabled}>
                      {node[`name_${lang.suffix}`]}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

CartCountrySelectorSideBar.propTypes = {
  close: PropTypes.func,
  store: PropTypes.object,
  data: PropTypes.object,
  lang: PropTypes.object,
  provider: PropTypes.object
}

export default CartCountrySelectorSideBar

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useImage } from 'react-image'
import VisibilitySensor from 'react-visibility-sensor'
import { t } from '../../../lib/locale'

const ProductListTileImageView = ({ id_default_image, alt }) => {
  const { src } = useImage({
    srcList: [
      `${process.env.GATSBY_CDN_URL}/p/${id_default_image.split('').join('/')}/${id_default_image}-medium_default.jpg?v=${process.env.GATSBY_BUILD_VERSION}`
    ],
    useSuspense: false
  })

  return (
    <img
      src={src}
      alt={alt}
      loading="lazy"
      className="d-block"
      width="269px"
      height="349.7px" />
  )
}

const ProductListTileImage = ({ product, sku, lang, inView }) => {
  const [hasLoaded, setLoaded] = useState(false)

  const id_default_image = sku && sku.id_default_image
    ? sku.id_default_image.toString()
    : product && product.id_default_image
      ? product.id_default_image.toString()
      : ''
  const alt = sku
    ? t(sku, 'name', lang.suffix)
    : t(product, 'name', lang.suffix)

  const fallback_loading = (
    <div className="product-tile-no-image fa-3x">
      <i className="fas fa-spinner fa-spin"></i>
    </div>
  )

  const fallback_none = (
    <div className="product-tile-no-image fa-3x">
    </div>
  )

  const visibilityHandler = (isVisible) => {
    if (!hasLoaded && isVisible) setLoaded(true)
  }

  if (id_default_image && inView) {
    return <ProductListTileImageView id_default_image={id_default_image} alt={alt} />
    // return (
    //   <VisibilitySensor onChange={visibilityHandler}>
    //     {({ isVisible }) => {
    //       return (isVisible || hasLoaded)
    //         ? <ProductListTileImageView id_default_image={id_default_image} alt={alt} />
    //         : fallback_loading
    //     }}
    //   </VisibilitySensor>
    // )
  } else {
    return fallback_none
  }
}

ProductListTileImage.propTypes = {
  product: PropTypes.object.isRequired,
  sku: PropTypes.object,
  lang: PropTypes.object,
  inView: PropTypes.bool
}

export default ProductListTileImage

import React from 'react'
import PropTypes from 'prop-types'
import { CheckoutStep } from '../..'

const CheckoutStepGiftWrapping = ({ lang }) => {
  return (
    <CheckoutStep lang={lang} isGiftWrapping={true} />
  )
}

CheckoutStepGiftWrapping.propTypes = {
  lang: PropTypes.object
}

export default CheckoutStepGiftWrapping

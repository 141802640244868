import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const MastercardLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(base: {eq: "mastercard.png"}, relativeDirectory: {eq: "footer"}) {
        childImageSharp {
          fixed(height: 29) {
            originalName
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={data.placeholderImage.childImageSharp.fixed} />
}

export default MastercardLogo

import React from 'react'
import PropTypes from 'prop-types'
import { CheckoutStep } from '../..'

const CheckoutStepInfo = ({ lang }) => {
  return (
    <CheckoutStep lang={lang} isInfo={true} />
  )
}

CheckoutStepInfo.propTypes = {
  lang: PropTypes.object
}

export default CheckoutStepInfo

import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import { CartAddSKUButton } from '../..'
import { l, t } from '../../../lib/locale'

const OUT_OF_STOCK_DEFAULT = 2
const OUT_OF_STOCK_ALLOW = 1
const OUT_OF_STOCK_DISALLOW = 0

const explicit_out_of_stock = [OUT_OF_STOCK_ALLOW, OUT_OF_STOCK_DISALLOW]

const SKUSelectorSingle = ({ product, sku, lang, giftlist }) => {
  const out_of_stock_allowed = [OUT_OF_STOCK_ALLOW, OUT_OF_STOCK_DEFAULT].includes(+product.out_of_stock_allowed)

  return (
    <>
      <div className="product-detail-right-actions">
        <CartAddSKUButton sku={sku} lang={lang} giftlist={giftlist} className="btn-large-secondary" />

        {process.env.GATSBY_FEATURE_WISHLIST && (
          <button className="btn-add-to-wishlist">
            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.613 6.25444C24.3031 4.53881 23.4094 2.69596 21.1556 1.79411L21.0366 1.74682C20.117 1.3937 19.1306 1.24888 18.1483 1.3228C16.0658 1.47773 14.2083 2.70086 13.0716 4.45237L12.9998 4.56164L12.928 4.45237C11.7865 2.70086 9.93384 1.47773 7.85126 1.3228C6.86902 1.24888 5.88261 1.3937 4.96306 1.74682L4.84401 1.79411C2.5902 2.69596 1.6965 4.53881 1.38664 6.25444C0.965886 8.58001 1.59865 10.961 2.95061 12.8985C5.88611 17.1207 11.7848 22.0703 12.8987 22.9949C12.9253 23.017 12.9588 23.029 12.9933 23.029C13.0278 23.029 13.0613 23.017 13.0879 22.9949C14.2083 22.0703 20.1005 17.1239 23.036 12.8985C24.401 10.961 25.0337 8.58001 24.613 6.25444Z" stroke="#7C8486" strokeWidth="2" strokeMiterlimit="10"></path>
            </svg>
            <p>Voeg toe aan wishlist</p>
          </button>
        )}
      </div>

      {sku && !giftlist && (
        <div className="product-detail-right-extra-info">
          {sku._links.add_to_cart ? (
            <>
              {sku.stock_quantity > 0 ? (
                <>
                  <div className="product-detail-right-extra-info-item">
                    <span className="product-detail-right-extra-info-success"></span>
                    <p>{l('lbl-available-now', lang)}</p>
                  </div>
                  <div className="product-detail-right-extra-info-item">
                    <span className="product-detail-right-extra-info-success"></span>
                    <p>{l('lbl-delivery-now', lang)}</p>
                  </div>
                </>
              ) : out_of_stock_allowed ? (
                <>
                  <div className="product-detail-right-extra-info-item">
                    <span className="product-detail-right-extra-info-warning"></span>
                    {sku.available_date ? (
                      <p>{l('lbl-available-from', lang)} {dateFormat(new Date(sku.available_date), "dd/mm/yyyy")}</p>
                    ) : t(sku, 'available_later', lang) ? (
                      <p>{t(sku, 'available_later', lang)}</p>
                    ) : (
                      <p>{l('lbl-delivery-later', lang)}</p>
                    )}
                  </div>
                </>
              ) : (
                <div className="product-detail-right-extra-info-item">
                  <span className="product-detail-right-extra-info-error"></span>
                  <p>{l('lbl-available-not', lang)}</p>
                </div>
              )}
            </>
          ) : (
            <div className="product-detail-right-extra-info-item">
              <span className="product-detail-right-extra-info-error"></span>
              <p>{l('lbl-available-not', lang)}</p>
            </div>
          )}
        </div>
      )}
    </>
  )
}

SKUSelectorSingle.propTypes = {
  product: PropTypes.object,
  sku: PropTypes.object,
  lang: PropTypes.object,
  onChange: PropTypes.func,
  giftlist: PropTypes.object
}

export default SKUSelectorSingle

import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { context } from '../../../store'
import { useInView } from 'react-intersection-observer'
import { t } from '../../../lib/locale'

import ProductListTileViewSingle from './view_single'
import ProductListTileViewVoucher from './view_voucher'
import ProductListTileViewCombination from './view_combination'

import './productlisttile.scss'

require('intersection-observer')

const ProductListTile = (props) => {
  const { store, cartProvider, layoutProvider } = useContext(context)
  const { disabled, adding } = store && store.cart
    ? store.cart
    : { disabled: false, adding: false }

  let { customer } = store && store.session
    ? store.session
    : { customer: null }

  if (customer && customer.is_guest) customer = null

  const { product, sku, lang, attributes, attribute_groups, showSKU, position, giftlist, promo } = props

  const initialized = store.app.initialized
  const cartrules = store.app.cartrules || null

  useEffect(() => {
    if (window && window.ga) {
      const opts = {
        id: `${product.id_product}-${sku ? sku.id_product_attribute : '0'}`,
        name: t(product, 'name', lang),
        brand: product.manufacturer_name,
        // list: 'Search Results', // @todo ?
        position: position || 0
      }

      window.ga('ec:ec:addImpression', opts)
    }
  }, [product, sku, lang])

  const add2giftlist = async (e) => {
    e.preventDefault()

    // dont allow action if not initialized app state yet
    if (!initialized) return false

    if (product.is_voucher) {
      // @todo
    } else if (product.is_combination) {
      // @todo
    } else {
      layoutProvider.showGiftlistResult({
        event: 'addItem',
        product: product,
        sku: sku
      })
    }
  }

  const add2cart = async (e) => {
    e.preventDefault()

    // dont allow action if not initialized app state yet
    if (!initialized) return false

    if (product.is_voucher) {
      layoutProvider.showAddCombination2Cart({
        product: product,
        sku: sku
      })
    } else if (product.is_combination && !giftlist) {
      layoutProvider.showAddCombination2Cart({
        product: product,
        sku: sku,
        attributes: attributes,
        attribute_groups: attribute_groups
      })
    } else {
      const quantity = 1
      const error = await cartProvider.addItem(sku, quantity, lang, null, () => {
        layoutProvider.showCartResult({
          event: 'addItem',
          sku: sku,
          quantity: quantity
        })
      })

      if (error) {
        layoutProvider.showCartResult({
          event: 'error',
          error: error.name,
          product: error.data.product
        })
      }
    }
  }

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  if (!product || product === undefined) {
    return (<div className="product-list-tile-product-undefined"></div>)
  } else if (product.is_voucher) {
    return (
      <div ref={ref} className={props.className ? props.className : 'product col-12 col-md-4 col-lg-3 clickable'}>
        <ProductListTileViewVoucher
          inView={inView}
          layoutProvider={layoutProvider}
          disabled={disabled || !initialized}
          add2cart={add2cart}
          add2giftlist={add2giftlist}
          adding={adding}
          customer={customer}
          {...props} />
      </div>
    )
  } else if (product.is_combination && sku) {
    return (
      <div ref={ref} className={props.className ? props.className : 'product col-12 col-md-4 col-lg-3 clickable'}>
        <ProductListTileViewSingle
          inView={inView}
          layoutProvider={layoutProvider}
          disabled={disabled || !initialized}
          add2cart={add2cart}
          add2giftlist={add2giftlist}
          adding={adding}
          customer={customer}
          cartrules={cartrules}
          promo={promo}
          {...props} />
      </div>
    )
  } else if (product.is_combination) {
    return (
      <div ref={ref} className={props.className ? props.className : 'product col-12 col-md-4 col-lg-3 clickable'}>
        <ProductListTileViewCombination
          inView={inView}
          layoutProvider={layoutProvider}
          disabled={disabled || !initialized}
          add2cart={add2cart}
          add2giftlist={add2giftlist}
          adding={adding}
          customer={customer}
          cartrules={cartrules}
          promo={promo}
          {...props} />
      </div>
    )
  }

  return (
    <div ref={ref} className={props.className ? props.className : 'product col-12 col-md-4 col-lg-3 clickable'}>
      <ProductListTileViewSingle
        inView={inView}
        layoutProvider={layoutProvider}
        disabled={disabled || !initialized}
        add2cart={add2cart}
        add2giftlist={add2giftlist}
        adding={adding}
        customer={customer}
        cartrules={cartrules}
        promo={promo}
        {...props} />
    </div>
  )
}

ProductListTile.propTypes = {
  position: PropTypes.number,
  product: PropTypes.object.isRequired,
  sku: PropTypes.object,
  lang: PropTypes.object,
  giftlist: PropTypes.bool,
  cartOnly: PropTypes.bool,
  navigation: PropTypes.object,
  className: PropTypes.string,
  promo: PropTypes.object
}

export default ProductListTile

import React from 'react'
import PropTypes from 'prop-types'
import { l } from '../../../lib/locale'

const GiftListBlockService = ({ lang }) => {
  return (
    <div className="container service">
      <h3>{l('giftlist-service-title', lang)}</h3>
      <div className="row service-items">
        <div className="col-md-6 service-item">
          <i className="fas fa-clock fa-2x"></i>
          <h4>{l('giftlist-service-service-title', lang)}</h4>
          <p>{l('giftlist-service-service-description', lang)}</p>
        </div>
        <div className="col-md-6 service-item">
          <i className="fas fa-thumbs-up fa-2x"></i>
          <h4>{l('giftlist-service-products-title', lang)}</h4>
          <p>{l('giftlist-service-products-description', lang)}</p>
        </div>
      </div>

      <div className="row service-items">
        <div className="col-md-6 service-item">
          <i className="fas fa-truck fa-2x"></i>
          <h4>{l('giftlist-service-delivery-title', lang)}</h4>
          <p>{l('giftlist-service-delivery-description', lang)}</p>
        </div>
        <div className="col-md-6 service-item">
          <i className="fas fa-shopping-basket fa-2x"></i>
          <h4>{l('giftlist-service-buy-title', lang)}</h4>
          <p>{l('giftlist-service-buy-description', lang)}</p>
        </div>
      </div>
    </div>
  )
}

GiftListBlockService.propTypes = {
  lang: PropTypes.object
}

export default GiftListBlockService

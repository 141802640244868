import React from 'react'
import PropTypes from 'prop-types'
import Image from './image'
import { l } from '../../../lib/locale'

import './contentblockservice.scss'

const ContentBlockService = ({ lang, image, title }) => {
  return (
    <div className="content-block services">
      <div className="container">
        <div className="row content-block-wrapper">
          <div className="col-12">
            <h3 className="title underline mb-3">{l('block-service-title', lang)}</h3>
          </div>
        </div>

        <div className="row content-block-wrapper">
          <div className="col-md-6 text-center">
            <div className="row">
              <div className="col-12 service-item mb-3">
                <i className="fas fa-rocket-launch fa-2x"></i>
                <h4>{l('block-service-shipping-title', lang)}</h4>
                <p>{l('block-service-shipping-description', lang)}</p>
              </div>
              <div className="col-12 service-item mb-3">
                <i className="fas fa-undo-alt fa-2x"></i>
                <h4>{l('block-service-returns-title', lang)}</h4>
                <p>{l('block-service-returns-description', lang)}</p>
              </div>
            </div>
            <div className="col-12 service-item">
              <i className="fas fa-gifts fa-2x"></i>
              <h4>{l('block-service-giftwrapping-title', lang)}</h4>
              <p>{l('block-service-giftwrapping-description', lang)}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="content-block-wrapper-image">
              <Image src={image} alt={title} />
              <div className="bg-block-content-block-right"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ContentBlockService.propTypes = {
  lang: PropTypes.object
}

export default ContentBlockService

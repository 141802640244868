import React, { useState, useContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import { context } from '../../../store'
import { l } from '../../../lib/locale'
import { formatAddress } from '../../../lib/address'
import callAPI from '../../../lib/callAPI'

const AccountAddressList = ({ setSelected, lang, showCreate }) => {
  const { store, layoutProvider } = useContext(context)
  const { deletingaddress, disabled } = store && store.session
    ? store.session
    : { deletingaddress: null, disabled: false }

  const [result, setResult] = useState()

  const fetchAddressList = useCallback(
    () => {
      return callAPI(store, store.app.endpoints.my_addresses)
    },
    [store]
  )

  useEffect(() => {
    const load = async () => {
      const result = await fetchAddressList()
      setResult(result)
    }
    load()
  }, [store.session.deletingaddress, fetchAddressList])

  const confirmDelete = (e, address) => {
    e.preventDefault()
    layoutProvider.showAddressDelete(address)
  }

  return (
    <section className="giftlist">
      {result && result._embedded && result._embedded.addresses ? (
        <>
          <div className="row">
        		<div className="  header col-md-9 d-md-flex justify-content-md-between">
        			<div className="container">
        				<div className="row">
        					<div className="col-md-3">
        						<p>{l('address-alias', lang)}</p>
        					</div>
        					<div className="col-md-9">
        						<p>{l('address-address', lang)}</p>
        					</div>
        				</div>
        			</div>
        		</div>
        		<div className="col-12 col-md-3 add">
        			<div className="d-flex justify-content-md-end">
        				<PageLink to={`?create`} onClick={showCreate}>
                  <i className={`fas fa-plus`}></i> {l('button-create-address', lang)}
                </PageLink>
        			</div>
        		</div>
        	</div>

        	<div className="row ">
        		<hr className="divider" />
        	</div>

        	<div className="container gift">
            {result._embedded.addresses.map(a => (
              <React.Fragment key={`address-${a.id_address}`}>
                <div className="row">
            			<div className="listdata col col-md-9 d-md-flex align-items-md-center">
            				<div className="container">
            					<div className="row">
            						<div className="col-md-3 firstname d-flex align-items-center">
            							<p>{a.alias}</p>
            						</div>
            						<div className="col-md-9 created-at d-flex align-items-center">
            							<p>{formatAddress(a, lang)}</p>
            						</div>
            					</div>
            				</div>
            			</div>
            			<div className="gl-actions col d-flex justify-content-end col-md-1 order-md-last align-items-md-center">
            				<button className="delete clickable btn btn-outline-secondary" onClick={e => confirmDelete(e, a)} disabled={disabled}>
                      <i className={`fas ${+deletingaddress === +a.id_address ? 'fa-spin fa-spinner' : 'fa-trash'}`}></i>
                    </button>
            			</div>
            			<PageLink to={`?id=${a.id_address}`} className="col-12 col-md-2 buttons" disabled={disabled} onClick={e => setSelected(a)}>
            				<div className="btn-medium-secondary change">{l('button-edit', lang)}</div>
            			</PageLink>
            		</div>
            		<hr />
              </React.Fragment>
            ))}
        	</div>
        </>
      ) : (
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="p-1">{l('msg-no-addresses', lang)}</div>
          </div>
          <div className="col-12 col-md-4">
            <PageLink to={`?create`} onClick={showCreate} className="btn btn-link">
              <i className={`fas fa-plus`}></i> {l('button-create-address', lang)}
            </PageLink>
          </div>
        </div>
      )}
    </section>
  )
}

AccountAddressList.propTypes = {
  setSelected: PropTypes.func,
  lang: PropTypes.object,
  showCreate: PropTypes.func
}

export default AccountAddressList

import React, { useState, useContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
import { PageLink } from '../..'
import { context } from '../../../store'
import callAPI from '../../../lib/callAPI'

const AccountGiftListOrdersList = ({ giftlist, lang }) => {
  const { store } = useContext(context)

  const orders = giftlist && giftlist._embedded && giftlist._embedded.orders
    ? giftlist._embedded.orders.sort((a,b) => b.id_order - a.id_order)
    : null
  const orderlines = giftlist && giftlist._embedded && giftlist._embedded.orderlines
    ? giftlist._embedded.orderlines
    : null

  return (
    <React.Fragment>
      <div className="row mb-2">
        <div className="col-3">
          <PageLink to={`/nl/mijn-wenslijsten`} className="btn btn-link">
            <i className="fas fa-chevron-left"></i> terug naar je lijsten
          </PageLink>
        </div>
        <div className="col-6">
          <h3>{giftlist.name}</h3>
        </div>
        <div className="col-3">
          <PageLink to={`/nl/mijn-wenslijst?id=1`} className="btn btn-link">
            <i className="fas fa-chevron-right"></i> items op je lijst
          </PageLink>
        </div>
      </div>

      <div className="row">
        {orders && orders.length > 0 ? orders.map(order => (
          <div className="col-12 mb-2" key={`giftlist-order-${order.id_order}`}>
            <div className="card">
              <div className="card-header">
                <b>{order.customer_name ? order.customer_name : 'Iemand in de winkel'}</b> heeft op {dateFormat(new Date(order.date_add), "dd-mm-yyyy")} volgende items van jouw lijst besteld
              </div>

              <ul className="list-group list-group-flush">
                {orderlines && orderlines.filter(line => +line.id_order === +order.id_order && +line.id_giftlist_product > 0).map((line, k) => (
                  <li className="list-group-item" key={`line-${line.id_order_detail}-${k}`}>
                    {line.product_quantity}x {line.product_name} (€{Number(line.product_price).toFixed(2)})
                  </li>
                ))}
              </ul>

              {order.gift_message ? (
                <div className="card-body">
                  <b>Met de volgende boodschap:</b>
                  <p dangerouslySetInnerHTML={{ __html: order.gift_message }}></p>
                </div>
              ) : null}
            </div>
          </div>
        )) : (
          <div className="col-12 mb-2">
            <div className="alert alert-info">
              Nog geen bestellingen voor jouw lijst.
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

AccountGiftListOrdersList.propTypes = {
  giftlist: PropTypes.object,
  lang: PropTypes.object
}

export default AccountGiftListOrdersList

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ src, alt, className }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: allFile(filter: {relativeDirectory: {eq: "layout"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 700) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const file = data.placeholderImage.edges.find(({ node }) => node.childImageSharp.fluid.originalName === src)
  if (file) {
    return <Img className={className} alt={alt} fluid={file.node.childImageSharp.fluid} />
  }
  return ''
}

export default Image

import React from 'react'
import PropTypes from 'prop-types'
import { l } from '../../../lib/locale'

const SKUPromoLabel = ({ cartrule, product, sku, lang }) => {
  if (cartrule.reduction_percent && +cartrule.reduction_percent > 0) {
    console.log(cartrule);
    return (
      <span key={`product-cart-rule-${cartrule.id_cart_rule}`} className="product-labels-promo mr-1">
        {cartrule.display_code && cartrule.display_code === 'greenfriday' && (<i className="fas fa-leaf text-success mr-1"></i>)}
        {cartrule.rule_group_quantity > 1
          ? (
            <><i className="fas fa-badge-percent fa-2x mb-1"></i> STAPEL ACTIE</>
          )
          : (
          <>-{Number(cartrule.reduction_percent).toFixed(0)}%</>
          )
        }

      </span>
    )
  }

  // @todo show in correct language
  return (
    <span key={`product-cart-rule-${cartrule.id_cart_rule}`} className="product-labels-promo mr-1">
      {cartrule[`name_${lang.suffix}`]}
    </span>
  )
}

SKUPromoLabel.propTypes = {
  cartrule: PropTypes.object,
  product: PropTypes.object,
  sku: PropTypes.object,
  lang: PropTypes.object
}

export default SKUPromoLabel

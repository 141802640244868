import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PageLink, CartAddSKUButton, SKUAvailabilityLabel } from '../..'
import { context } from '../../../store'
import { t,l } from '../../../lib/locale'
// import { productURL } from '../../../lib/product'
import { skuURL } from '../../../lib/sku'

const ProductInlineListItem = ({ sku, attributes, attribute_groups, lang }) => {
  return (
    <div class="row inline-product my-1">
      <div className="col-3 col-md-2">
        <PageLink to={skuURL(sku, lang)}>
          {sku.id_default_image && +sku.id_default_image > 0 ? (
            <img
              className="img-fluid w-100"
              src={`${process.env.GATSBY_CDN_URL}/p/${sku.id_default_image.toString().split('').join('/')}/${sku.id_default_image}-medium_default.jpg`}
              alt={sku[`name_${lang.suffix}`]} />
          ) : (
            <div className="product-no-image"></div>
          )}
        </PageLink>
      </div>
      <div className="col-9 col-md-5 pt-2">
        <div className="">{t(sku, 'name', lang)}</div>
        {sku._links && sku._links.add_to_cart ? (
          <SKUAvailabilityLabel sku={{ quantity: 1, ...sku }} lang={lang} />
        ) : (
          <div className="product-detail-right-extra-info-item">
            <span className="product-detail-right-extra-info-error"></span>
            <p>{l('lbl-available-not', lang)}</p>
          </div>
        )}
      </div>
      <div className="col-3 col-md-2 pt-2">
        <span className="">&euro; {Number(sku.sellPrice).toFixed(2)}</span>
      </div>
      <div className="col-9 col-md-3">
        <CartAddSKUButton
          sku={sku}
          lang={lang}
          classButton="btn-inline-add-to-cart"
          className="btn-medium-secondary btn-small-secondary"
          title="button-addtocart-small" />
      </div>
    </div>
  )
}

ProductInlineListItem.propTypes = {
  sku: PropTypes.object.isRequired,
  lang: PropTypes.object
}

export default ProductInlineListItem

import React, { useState, useContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { context } from '../../../store'
import callAPI from '../../../lib/callAPI'
import { l } from '../../../lib/locale'

import { ProductListTile, SKUPage } from '../..'

const GiftListItemList = ({ lang, id_giftlist, id_giftlist_product }) => {
  const { store   } = useContext(context)

  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState()
  const [attributes, setAttributes] = useState()
  const [attribute_groups, setAttributeGroups] = useState()
  const [display_gate, setDisplayGate] = useState(false)
  const [gate, setGate] = useState('')

  const fetchGiftList = useCallback(
    (g) => {
      return callAPI(store, {
        href: store.app.endpoints.giftlist.href.replace('{id_giftlist}', id_giftlist) + `?gate=${g}`,
        method: store.app.endpoints.giftlist.method
      })
    },
    [store, id_giftlist]
  )

  const loadData = async (g) => {
    if (store && store.app && store.app.endpoints) {
      try {
        const result = await fetchGiftList(g)
        setResult(result)

        const result2 = await callAPI(store, store.app.endpoints.attributes)
        setAttributes(result2._embedded.attributes)

        const result3 = await callAPI(store, store.app.endpoints.attribute_groups)
        setAttributeGroups(result3._embedded.attributes)

        setDisplayGate(false)
        setLoading(false)
      } catch (e) {
        if (e && e.data && e.data.requires_gate) {
          setDisplayGate(true)
          setLoading(false)
        }
      }
    }
  }

  useEffect(() => {
    loadData(gate)
  }, [id_giftlist, fetchGiftList, store.app.endpoints])

  const handleGateForm = (e) => {
    e.preventDefault()
    loadData(gate)
  }

  if (loading) {
    return (
      <div className="giftlist-hero">
        <p>we halen de gegevens op ... even geduld</p>
      </div>
    )
  } else if (display_gate) {
    return (
      <div className="giftlist-hero">
        <form className="giftlist-search" method="GET" onSubmit={handleGateForm}>
          <p>Deze lijst is beschermd, gelieve het paswoord in te geven.</p>
          <input type="password" placeholder={l('field-password', lang)} value={gate} onChange={e => setGate(e.target.value)} />
          <button className="btn-large-secondary button" type="submit">
            {l('button-confirm', lang)}
          </button>
        </form>
      </div>
    )
  } else if (result && !result.active) {
    return (
      <div className="giftlist-hero">
        <p>Fijn dat je een kijkje komt nemen. Dit lijstje werd ondertussen afgesloten. </p>
      </div>
    )
  } else if (result && result._embedded && result._embedded.items && id_giftlist_product) {
    const sku = result && result._embedded && result._embedded.items
      ? result._embedded.items.find(i => +i.id_giftlist_product === +id_giftlist_product)
      : null

    let promo = null
    if (+sku.reduction_percent > 0) {
      promo = {
        reduction_type: "percentage",
        reduction: sku.reduction_percent/100
      }
    } else if (+sku.reduction_amount > 0) {
      promo = {
        reduction_type: "amount",
        reduction: sku.reduction_amount
      }
    }

    return (
      <SKUPage
        product={sku}
        sku={sku}
        attributes={attributes}
        attribute_groups={attribute_groups}
        lang={lang}
        isGiftlist={true}
        giftlist={result}
        reduction={promo} />
    )
  } else {
    const filter_items = (i) => {
      if (+i.quantity_left > 0) return true
      if (i.display_type === 'voucher') return true
      if (i.display_type === 'evoucher') return true
      return false
    }

    const fix_combinations = (i) => {
      if (i.combination) {
        i.combination_nl = i.combination
        i.combination_en = i.combination
      }
      return i
    }

    return (
      <>
        <div className="giftlist-hero">
          <h1>{l('giftlist-title', lang)} {result.name}</h1>
          {result.name_parents ? <p>{l('giftlist-parents', lang)}: {result.name_parents}</p> : ''}
          {result && result._embedded && (!result._embedded.items || +result._embedded.items.length === 0) ? l('giftlist-no-items', lang) : ''}
          {result.welcome_message ? <div dangerouslySetInnerHTML={{ __html: result.welcome_message }} /> : ''}
        </div>

        <div className="container">
          <div className="product-list-4 product-list-breadcrumbs">
            <div className="container">
              <div className="row">

                {result && result._embedded && result._embedded.items ? result._embedded.items.filter(filter_items).map(fix_combinations).sort((a,b) => a.priority - b.priority).map(item => {
                  return (
                    <ProductListTile
                      key={`giftlist-${result.id_giftlist}-${item.id_sku}`}
                      product={item}
                      sku={item}
                      attributes={attributes}
                      attribute_groups={attribute_groups}
                      lang={lang}
                      giftlist={true}
                      cartOnly={false} />
                  )
                }) : ''}

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

GiftListItemList.propTypes = {
  lang: PropTypes.object,
  id_giftlist: PropTypes.number,
  id_giftlist_product: PropTypes.number
}

export default GiftListItemList

import React from 'react'
import PropTypes from 'prop-types'

import { ProductList, PageBreadcrumb } from '../..'

const CategoryLevel2View = ({ category, parent, children, products, attributes, attribute_groups, breadcrumbs, navigation, lang }) => {

  return (
    <div className="product-list-4 product-list-breadcrumbs">
      <PageBreadcrumb
        lang={lang}
        current={{ title_nl: category.name_nl, title_en: category.name_en, ...category }}
        items={breadcrumbs} />

      <ProductList
        title={category[`name_${lang.suffix}`]}
        products={products}
        attributes={attributes}
        attribute_groups={attribute_groups}
        lang={lang}
        navigation={navigation}
        keyPrefix={`category-${category.id_category}-item`}
        sortBy="date_add"
        sortMode={0} />
    </div>
  )
}

CategoryLevel2View.propTypes = {
  category: PropTypes.object.isRequired,
  parent: PropTypes.object,
  children: PropTypes.array,
  products: PropTypes.array,
  attributes: PropTypes.array,
  attribute_groups: PropTypes.array,
  breadcrumbs: PropTypes.array,
  navigation: PropTypes.object,
  lang: PropTypes.object
}

export default CategoryLevel2View

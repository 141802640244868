import React, { useContext } from 'react'
import { navigate, StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { context } from '../../../store'
import { l } from '../../../lib/locale'

import './topbanner.scss'

const languages = [
  { id_lang: 1, suffix: 'en' },
  { id_lang: 4, suffix: 'nl' }
]

const PageTopBanner = ({ lang, switcher }) => {
  const { store, layoutProvider } = useContext(context)
  const { cart } = store && store.cart
    ? store.cart
    : { cart: null }

  const onLanguageSelected = (e) => {
    const id_lang = e.target.value
    const l = languages.find(l => +l.id_lang === +id_lang)
    navigate(switcher[`path_${l.suffix}`])
  }

  const changeCountry = (e, country) => {
    if (e) e.preventDefault()
    layoutProvider.showCountrySelector(country)
  }

  return (
    <StaticQuery
      query={graphql`
        query PageTopBannerQuery {
          allShopCountry {
            edges {
              node {
                id_country
                name_en
                name_nl
              }
            }
          }
          allShopDelivery {
            edges {
              node {
                delimiter1
                delimiter2
                id_carrier
                id_country
                id_delivery
                id_range_price
                id_zone
                price
                name
                delay_en
                delay_nl
                requires_address
                requires_giftlist
                requires_pickup_point
              }
            }
          }
        }
      `}
      render={({ allShopCountry, allShopDelivery }) => {
        let promo = null

        if (cart) {
          //console.log('PageTopBanner found cart', cart, allShopDelivery, allShopCountry)

          const delivery_treshold = allShopDelivery.edges.filter(
            ({ node: i }) => +i.id_country === +cart.id_country && +i.price === 0 && i.requires_address
          )

          const country = cart && cart.id_country
            ? allShopCountry.edges.find(({ node: i }) => +i.id_country === +cart.id_country)
            : allShopCountry.edges.find(({ node: i }) => +i.id_country === 3) // @todo make it constant for default country
          const hasFreeShipping = delivery_treshold && delivery_treshold.length > 0
          const remainingAmount = hasFreeShipping
            ? delivery_treshold[0].node.delimiter1 - cart.totals.total_paid_real
            : 0

          if (country) {
            // console.log('PageTopBanner found country', country)
            // console.log('PageTopBanner parameters', hasFreeShipping, remainingAmount, delivery_treshold)
            if (hasFreeShipping && remainingAmount > 0) {
              promo = (
                <>
                  {l('top-free-delivery-from-start', lang)} &euro;{Number(remainingAmount).toFixed(2)} {l('top-free-delivery-from-end', lang)}
                  <button className="clickable btn btn-link" onClick={e => changeCountry(e, country)}>
                    {country.node[`name_${lang.suffix}`]}
                    <i className="fas fa-chevron-down ml-1"></i>
                  </button>
                </>
              )
            } else if (hasFreeShipping) {
              promo = (
                <>
                  {l('top-free-delivery-to', lang)}
                  <button className="clickable btn btn-link" onClick={e => changeCountry(e, country)}>
                    {country.node[`name_${lang.suffix}`]}
                    <i className="fas fa-chevron-down ml-1"></i>
                  </button>
                </>
              )
            } else {
              const delimiter = cart ? +cart.totals.total_paid_real : 0
              const deliveries = allShopDelivery.edges.filter(({ node: i }) => {
                return +i.id_country === +cart.id_country
                  && i.requires_address
                  && i.delimiter1 <= delimiter
                  && i.delimiter2 >= delimiter
              })

              // console.log('PageTopBanner parameters2', delimiter, deliveries)
              if (deliveries && deliveries.length > 0) {
                promo = (
                  <>
                    &euro;{Number(deliveries[0].node.price).toFixed(2)} {l('top-delivery-to', lang)}
                    <button className="clickable btn btn-link" onClick={e => changeCountry(e, country)}>
                      {country.node[`name_${lang.suffix}`]}
                      <i className="fas fa-chevron-down ml-1 mt-1"></i>
                    </button>
                  </>
                )
              } else {
                promo = (
                  <>
                    {l('top-banner', lang)}
                  </>
                )
              }
            }
          }
        } else {
          promo = (
            <>
              {l('top-banner', lang)}
            </>
          )
        }

        return (
          <div className="attention-banner">
            <div className="container">
              <div className="row">
                <div className="col-3 text-left attention-banner-mobile">
                  <a href="https://www.facebook.com/shopupnorthcom/" target="_blank" rel="noreferrer" className="mx-1">
                    <i className="fab fa-lg fa-facebook"></i>
                  </a>
                  <a href="https://www.instagram.com/shopupnorth_com/" target="_blank" rel="noreferrer" className="mx-1">
                    <i className="fab fa-lg fa-instagram"></i>
                  </a>
                </div>

                <div className="col-sm-12 col-md-6 text-center">
                  {promo}
                </div>

                <div className="col-3 text-right attention-banner-mobile">
                  <select name="language" id="language" value={lang.id_lang} onChange={e => onLanguageSelected(e)} onBlur={e => onLanguageSelected(e)}>
                    {languages.map(l => (
                      <option key={`switch-language-${l.id_lang}`} value={l.id_lang}>{l.suffix.toUpperCase()}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

PageTopBanner.propTypes = {
  lang: PropTypes.object,
  switch: PropTypes.object
}

export default PageTopBanner

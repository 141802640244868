import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
// import { navigate } from '@reach/router'
import { navigate } from 'gatsby'
import { context } from '../../../store'
import { pageURL } from '../../../lib/page'

const ID_GENDER_MALE = 1
const ID_GENDER_FEMALE = 2

const AccountGiftListForm = ({ lang }) => {
  const { store, appProvider } = useContext(context)

  const [name, setName] = useState('')
  const [welcome_message, setWelcome] = useState('')
  const [name_parents, setNameParents] = useState('')
  const [gender, setGender] = useState('')
  const published = '1'

  const [valid, setValid] = useState(false)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)

  const updateField = (e) => {
    if (e.target.name === 'name') setName(e.target.value)
    if (e.target.name === 'name_parents') setNameParents(e.target.value)
    if (e.target.name === 'welcome_message') setWelcome(e.target.value)
    if (e.target.name === 'gender') setGender(e.target.value)
  }

  useEffect(() => {
    if (name && name_parents && welcome_message && gender) {
      setValid(true)
    } else {
      setValid(false)
    }
  }, [name, name_parents, welcome_message, gender])

  const onSubmit = async (e) => {
    if (e) e.preventDefault()
    setError(null)
    setSaving(true)
    try {
      const result = await appProvider.createGiftlist(name, name_parents, welcome_message, gender, published)
      if (result && result.id_giftlist) {
        navigate(`${pageURL('my-giftlist-items', lang)}?id=${result.id_giftlist}`)
      } else if (result.error) {
        setError(true)
        setSaving(false)
      }
    } catch (e) {
      setError(true)
      setSaving(false)
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="giftlist-hero">
        <h1>Maak een nieuwe lijst aan</h1>
        <div className="progress d-none">
          <div className="progress-bar" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">50%</div>
        </div>
        <p>Dit is de publieke naam van jouw lijst. Op basis van deze lijstnaam kan men jouw lijst terugvinden. Dus deze lijstnaam kan je eventueel ook vermelden op jouw drukwerk (bv. geboortekaartje).</p>
      </div>
      <div className="giftlist-new-form">
        <div className="input-label">
          <label htmlFor="name">Geeft een lijstnaam in</label>
          <input name="name" onChange={updateField} value={name} disabled={saving} />
        </div>
        <div className="input-label">
          <label htmlFor="name_parents">Naam ouders</label>
          <input name="name_parents" onChange={updateField} value={name_parents} disabled={saving} />
        </div>
        <div className="input-label">
          <label htmlFor="gender">Geslacht</label>
          <select name="gender" onChange={updateField} value={gender} disabled={saving}>
            <option value=""></option>
            <option value={ID_GENDER_MALE}>Jongen</option>
            <option value={ID_GENDER_FEMALE}>Meisje</option>
          </select>
        </div>
        <div className="input-label">
          <label htmlFor="welcome_message">Boodschap voor op je lijstje</label>
          <textarea name="welcome_message" onChange={updateField} value={welcome_message} rows="5" disabled={saving}>
          </textarea>
        </div>
        {error && (
          <div className="alert alert-danger">Gelieve alle velden na te kijken.</div>
        )}
        <button type="submit" className="btn-large-secondary button" disabled={!valid || saving}>
          <i className={`fas ${saving ? 'fa-spin fa-spinner' : 'fa-plus'}`}></i> Aanmaken
        </button>
      </div>
    </form>
  )
}

AccountGiftListForm.propTypes = {
  lang: PropTypes.object,
}

export default AccountGiftListForm

import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { PageLink, SKUSelector, ProductStructuredData, PageBreadcrumb, SKUGiftListSelector, SKUPromoLabel, ProductNotFoundPage, ProductInlineListItem } from '../..'
import { l, t } from '../../../lib/locale'
import { productURL, productBrandURL, stripStyleTag } from '../../../lib/product'
import { skuURL } from '../../../lib/sku'
import { context } from '../../../store'
import callAPI from '../../../lib/callAPI'
import { campaigns } from '../../../config/campaigns.json'
import { pages } from '../../../config/pages.json'

import './productpage.scss'

// @todo
// default no image ?

const TAB_ABOUT = 'description'
const TAB_COLLECTION = 'collection'
const TAB_BRAND = 'brand'
const TAB_PROMO_BRAND = 'brandpromo'

const ProductPage = (props) => {
  const { brand, attributes, attribute_groups, lang, isGiftlist, productfamily, productfamilyitems } = props
  const { store } = useContext(context)

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const [product, setProduct] = useState(props.product)
  const [sku, setSKU] = useState(product.is_combination
    ? null
    : product._embedded && product._embedded.skus && product._embedded.skus[0]
      ? product._embedded.skus[0]
      : null)
  const [items, setItems] = useState(productfamilyitems)

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  if (!product || product === undefined) return (
    <div className="product-list-breadcrumbs product-undefined">
    </div>
  )

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    Promise.all([
      callAPI(store, product._links.self),
      sku ? callAPI(store, sku._links.self) : Promise.resolve(null),
      productfamily ? callAPI(store, productfamily._links.items) : Promise.resolve(null)
    ]).then(all => {
      const promos = all[0]._embedded.promos ? all[0]._embedded.promos : null

      if (all[0] && +all[0].id_product === +product.id_product) {
        all[0]._embedded = product._embedded
        all[0]._embedded.promos = promos
        setProduct(all[0])
      }
      if (all[1] && +all[1].id_product === +sku.id_product) {
        all[1]._embedded = sku._embedded
        setSKU(all[1])
      }
      if (all[2] && all[2]._embedded && all[2]._embedded.skus) {
        setItems(all[2]._embedded.skus)
      }
    })
  }, [product.id_product, lang.id_lang])

  useEffect(() => {
    if (window && window.ga) {
      window.ga('ec:addProduct', {
        id: `${product.id_product}-0`,
        name: t(product, 'name', lang),
        brand: product.manufacturer_name
      })
      window.ga('ec:setAction', 'detail')
    }
  }, [product, lang])

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const [activeTabAbout, setActiveTabAbout] = useState('description')
  const [activeImage, setActiveImage] = useState(product.id_default_image)

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  if (!product || +product.active === 0) {
    return (
      <ProductNotFoundPage {...props} />
    )
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const isClientSide = (typeof window !== 'undefined')
  const description = product[`description_${lang.suffix}`]
  const brand_description = brand ? brand[`description_${lang.suffix}`] : null

  const cartrules = store.app.cartrules || null
  const product_cart_rules = cartrules
    ? cartrules.filter(i =>
      (i.rule_group_type === 'products' && i.items && i.items.includes(+product.id_product) && i.rule_group_quantity <= 2)
      || (i.rule_group_type === 'manufacturers' && i.items && i.items.includes(+product.id_manufacturer))
    )
    : null
  const active_cart_rules = cartrules
    ? cartrules.filter(i =>
      (i.rule_group_type === '' || i.rule_group_type === null)
    )
    : null

  const tabs = []
  const stringbyopage = pages.find(p => p.id === 'stringbyo')
  let brandpromo = null
  if (description) tabs.push(TAB_ABOUT)
  if (brand_description) tabs.push(TAB_BRAND)
  if (process.env.GATSBY_FEATURE_PRODUCT_COLLECTION) tabs.push(TAB_COLLECTION) // @todo
  if (product && product.flags && product.flags.includes('greenfriday') && product_cart_rules && product_cart_rules.length > 0) {
    brandpromo = product_cart_rules.find(cr => cr.rule_group_type === 'manufacturers')
    tabs.push(TAB_PROMO_BRAND)
  }
  if (+product.id_manufacturer === 34) {
    tabs.push(stringbyopage)
  }

  const hasTab = (id) => {
    return tabs.includes(id)
  }

  const showActiveTabAbout = (e, tab) => {
    if (tab === TAB_BRAND) {
      if (isClientSide) {
        if (e) e.preventDefault()
        setActiveTabAbout(tab)
      }
    } else {
      if (e) e.preventDefault()
      setActiveTabAbout(tab)
    }
  }

  const showImage = (e, image) => {
    if (e) e.preventDefault()
    setActiveImage(image.id_image)
  }

  const onSKUSelected = (sku) => {
    if (sku) {
      navigate(skuURL(sku, lang))
    } else {
      navigate(productURL(product, lang))
    }
  }

  let promo = null
  let promoPrice = null
  let promoFlag = null
  if (product._embedded && product._embedded.promos && product._embedded.promos.length > 0) {
    promo = product._embedded.promos[0]
    if (promo.reduction_type === 'amount') {
      promoPrice = +product.sellPrice - +promo.reduction
      promoFlag = `- €${Number(promo.reduction).toFixed(2)}`
    } else if (promo.reduction_type === 'percentage') {
      promoPrice = +product.sellPrice * (1 - +promo.reduction)
      promoFlag = `- ${Number(promo.reduction * 100).toFixed(0)}%`
    }
  } else if (product_cart_rules) {
    // @todo
    const product_cart_rule = product_cart_rules[0]
    if (product_cart_rule && +product_cart_rule.reduction_percent > 0 && product_cart_rule.rule_group_quantity === 1) {
      promoPrice = +product.sellPrice * (1 - product_cart_rule.reduction_percent/100)
      promoFlag = `- ${Number(product_cart_rule.reduction_percent).toFixed(0)}%`
    }
  }

  let banner = null, campaign = null
  if (campaigns && campaigns.length > 0) {
    if (product_cart_rules && product_cart_rules.length > 0) {
      product_cart_rules.forEach(rule => {
        const c = campaigns.find(c => c.code === rule.code || c.code === rule.display_code)
        if (c) {
          campaign = c
          banner = campaign.banner
        }
      })
    }
    if (active_cart_rules && active_cart_rules.length > 0) {
      active_cart_rules.forEach(rule => {
        const c = campaigns.find(c => c.code === rule.code || c.code === rule.display_code)
        if (c) {
          campaign = c
          banner = campaign.banner
        }
      })
    }
  }

  return (
    <div className="product-page product-list-breadcrumbs">
      <ProductStructuredData lang={lang} product={product} />

      <PageBreadcrumb
        lang={lang}
        current={{ path_en: productURL(product, lang), path_nl: productURL(product, lang), title_en: product.name_en, title_nl: product.name_nl , ...product }}
        items={['homepage']} />

      <div className="row product-detail">
        <div className="col-md-6 product-detail-left">
          <div className="product-detail-right-title-mobile">
            <div className="h1">{t(product, 'name', lang)}</div>
            <div className="h2">
              {product.manufacturer_hidden ? "" : (
                <PageLink to={productBrandURL(product, lang)}>{product.manufacturer_name}</PageLink>
              )}
            </div>
            {(product.is_voucher || product.is_combination)
              ? null
              : (<div className="h3">&euro; {Number(product.sellPrice).toFixed(2)}</div>)
            }
          </div>

          {isGiftlist ? '' : (
            <div className="product-labels">
              {product_cart_rules && product_cart_rules.map(cr => <SKUPromoLabel key={`product-cart-rule-${cr.id_cart_rule}`} cartrule={cr} product={product} sku={sku} lang={lang} />)}
              {!product._links.add_to_cart ? <span className="product-labels-promo mr-1">OUT OF STOCK</span> : ''}
              {product._links.add_to_cart && product.new ? <span className="product-labels-new mr-1">NIEUW</span> : ''}
              {product.on_sale ? <span className="product-labels-promo mr-1">PROMO</span> : ''}
            </div>
          )}

          {activeImage ? (
            <img
              className="img-fluid w-100"
              src={`${process.env.GATSBY_CDN_URL}/p/${activeImage.toString().split('').join('/')}/${activeImage}-medium_default.jpg?v=${process.env.GATSBY_BUILD_VERSION}`}
              alt={product[`name_${lang.suffix}`]} />
          ) : product.id_default_image && product.id_default_image > 0 ? (
            <img
              className="img-fluid w-100"
              src={`${process.env.GATSBY_CDN_URL}/p/${product.id_default_image.toString().split('').join('/')}/${product.id_default_image}-medium_default.jpg?v=${process.env.GATSBY_BUILD_VERSION}`}
              alt={product[`name_${lang.suffix}`]} />
          ) : (
            <div className="product-no-image"></div>
          )}

          {product._embedded && product._embedded.images && product._embedded.images.length > 0 && (
            <div className="row product-detail-left-slider-images">
              {product._embedded.images.slice(0,3).map(image => (
                <div className="col-4 text-center" key={`product-detail-left-slider-image-${image.id_image}`}>
                  <button className="btn btn-link p-0" onClick={e => showImage(e, image)}>
                    <img
                      className="img-fluid"
                      src={`${process.env.GATSBY_CDN_URL}/p/${image.id_image.toString().split('').join('/')}/${image.id_image}-small_default.jpg?v=${process.env.GATSBY_BUILD_VERSION}`}
                      alt={product[`name_${lang.suffix}`]} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="col-md-6 product-detail-right">
          <div className="product-detail-right-title">
            <h1>{t(product, 'name', lang)}</h1>
            <h2>
              {product.manufacturer_hidden ? "" : (
                <PageLink to={productBrandURL(product, lang)}>{product.manufacturer_name}</PageLink>
              )}
            </h2>
            {product.is_voucher ? null : promo || (product_cart_rules && product_cart_rules.length > 0 && +promoPrice > 0) ? (
              <>
                <h4 className="d-inline-block mx-2"><strike>&euro; {Number(product.sellPrice).toFixed(2)}</strike></h4>
                <h3 className="d-inline-block mx-2">&euro; {Number(promoPrice).toFixed(2)}</h3>
                <h4 className="d-inline-block">
                  <span className="badge badge-secondary">{promoFlag}</span>
                </h4>
              </>
            ) : product.is_combination ? '' : (
              <h3>&euro; {Number(product.sellPrice).toFixed(2)}</h3>
            )}

          </div>

          <SKUSelector
            product={product}
            sku={sku}
            attributes={attributes}
            attribute_groups={attribute_groups}
            lang={lang}
            onChange={onSKUSelected} />

          {campaign && banner && banner.small && (
            <div className="product-detail-right-banner mb-4">
              {banner.small.image && (
                <img src={banner.small.source} className="img-fluid" alt={campaign.code} />
              )}
            </div>
          )}

          <div className="product-detail-right-description">
            <div dangerouslySetInnerHTML={{ __html: stripStyleTag(product[`description_short_${lang.suffix}`]) }} />
            {+product.id_manufacturer === 34 && (
              <p>
                <PageLink to={t(stringbyopage, 'path', lang)} className="btn btn-link">
                  {t(stringbyopage, 'title', lang)} <i className="fas fa-chevron-right ml-2"></i>
                </PageLink>
              </p>
            )}
            {process.env.GATSBY_FEATURE_PRODUCT_FEATURES && (
              <>
                <ul>
                  <li><span>Materiaal:</span> Metaal</li>
                  <li><span>Afmetingen:</span> 45cmx80xm</li>
                  <li><span>Productkenmerk:</span> Een beschrijving</li>
                  <li><span>Materiaal:</span> Een beschrijving</li>
                </ul>
                <a href="/">Bekijk alle productkenmerken</a>
              </>
            )}
          </div>

          <SKUGiftListSelector
            product={product}
            sku={sku}
            lang={lang} />

          {productfamily ? (
            <div className="product-detail-right-family mt-4">
              <h3>{l('more-from', lang)} <PageLink to={t(productfamily, 'link', lang)}>{t(productfamily, 'name', lang)}</PageLink></h3>
              {items && items.filter(i => +i.id_product != +product.id_product).sort((a,b) => +b.id_product - +a.id_product).map(i => (
                <ProductInlineListItem
                  key={`productfamily-${i.id_product}-${i.id_product_attribute}`}
                  sku={i}
                  lang={lang}
                  attributes={attributes}
                  attribute_groups={attribute_groups} />
              ))}
            </div>
          ) : ''}

        </div>
      </div>

      <div className="product-about">
        <div className="row product-about-title">
          {hasTab(TAB_ABOUT) && (
            <button className="col-md-3 btn bnt-link" onClick={e => showActiveTabAbout(e, TAB_ABOUT)}>
              <h4 className={`only-desktop ${activeTabAbout === 'description' ? 'product-about-title-active' : 'product-about-title-not-active'}`}>
                {l('product-about-description', lang)}
              </h4>
            </button>
          )}
          {hasTab(TAB_COLLECTION) && (
            <button className="col-md-3 btn bnt-link" onClick={e => showActiveTabAbout(e, TAB_COLLECTION)}>
              <h4 className={`only-desktop ${activeTabAbout === 'collection' ? 'product-about-title-active' : 'product-about-title-not-active'}`}>
                {l('product-about-collection', lang)}
              </h4>
            </button>
          )}
          {hasTab(TAB_PROMO_BRAND) && (
            <button className="col-md-3 btn bnt-link" onClick={e => showActiveTabAbout(e, TAB_PROMO_BRAND)}>
              <h4 className={`only-desktop ${activeTabAbout === 'brandpromo' ? 'product-about-title-active' : 'product-about-title-not-active'}`}>
                {brandpromo.display_code === 'greenfriday' && (<><i className="fas fa-leaf text-success mr-1"></i> {t(brandpromo, 'name', lang)}</>)}
              </h4>
            </button>
          )}
          {hasTab(TAB_BRAND) && (
            <PageLink className="col-md-3 btn bnt-link" to={productBrandURL(product, lang)} onClick={e => showActiveTabAbout(e, TAB_BRAND)}>
              <h4 className={`only-desktop ${activeTabAbout === 'brand' ? 'product-about-title-active' : 'product-about-title-not-active'}`}>
                {l('product-about-brand', lang)}
              </h4>
            </PageLink>
          )}
          {tabs.map(tab => (
            <PageLink className="col-md-3 btn bnt-link" to={t(tab, 'path', lang)}>
              <h4 className={`only-desktop`}>
                {t(tab, 'title', lang)}
              </h4>
            </PageLink>
          ))}
        </div>

        {hasTab(TAB_ABOUT) && (
          <>
            <div className="row product-about-title only-mobile">
              <h4 className="col-12 product-about-title-active">{l('product-about-description', lang)}</h4>
            </div>
            <div className={`row mt-2 mb-5 ${activeTabAbout === TAB_ABOUT ? '' : 'd-md-none'}`}>
              <div
                className="col-12"
                dangerouslySetInnerHTML={{ __html: stripStyleTag(product[`description_${lang.suffix}`]) }} />
            </div>
          </>
        )}

        {hasTab(TAB_COLLECTION) && (
          <>
            <div className="row product-about-title only-mobile">
              <h4 className="col-12 product-about-title-active">{l('product-about-collection', lang)}</h4>
            </div>
            <div className={`row mt-2 mb-5 ${activeTabAbout === 'collection' ? '' : 'd-md-none'}`}>
            </div>
          </>
        )}

        {hasTab(TAB_PROMO_BRAND) && (
          <>
            <div className="row product-about-title only-mobile">
              <h4 className="col-12 product-about-title-active">
                {brandpromo.display_code === 'greenfriday' && (<><i className="fas fa-leaf text-success mr-1"></i> {t(brandpromo, 'name', lang)}</>)}
              </h4>
            </div>
            <div className={`row mt-2 mb-5 ${activeTabAbout === 'brandpromo' ? '' : 'd-md-none'}`}>
              <div
                className="col-12"
                dangerouslySetInnerHTML={{ __html: t(brandpromo, 'description', lang)}} />
            </div>
          </>
        )}

        {isClientSide && hasTab(TAB_BRAND) && (
          <>
            <div className="row product-about-title only-mobile">
              <PageLink className="col-md-3 btn bnt-link" to={productBrandURL(product, lang)}>
                <h4 className="col-12 product-about-title-active">{l('product-about-brand', lang)}</h4>
              </PageLink>
            </div>
            <div className={`row mt-2 mb-5 ${activeTabAbout === TAB_BRAND ? '' : 'd-md-none'}`}>
              <div className="col-12" dangerouslySetInnerHTML={{ __html: stripStyleTag(brand[`description_${lang.suffix}`]) }} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

ProductPage.propTypes = {
  product: PropTypes.object.isRequired,
  attributes: PropTypes.array,
  attribute_groups: PropTypes.array,
  lang: PropTypes.object,
  isGiftlist: PropTypes.bool
}

export default ProductPage

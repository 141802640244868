import React, { useState, useContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
import { PageLink } from '../..'
import { context } from '../../../store'
import callAPI from '../../../lib/callAPI'

const AccountGiftListList = ({ setSelected, lang }) => {
  const { store } = useContext(context)

  const [result, setResult] = useState()

  const fetchList = useCallback(
    () => {
      return callAPI(store, store.app.endpoints.my_giftlists)
    },
    [store]
  )

  useEffect(() => {
    const load = async () => {
      const result = await fetchList()
      setResult(result)
    }
    load()
  }, [fetchList])

  return (
    <section className="giftlist">
      {result && result._embedded && result._embedded.giftlists ? (
        <>
          <div className="row">
            <div className="header col-md-6 d-md-flex justify-content-md-between">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <p>Lijstnaam</p>
                  </div>
                  <div className="col-md-3">
                    <p>Gemaakt op</p>
                  </div>
                  <div className="col-md-3">
                    <p>Totaal</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        	<div className="row ">
        		<hr className="divider" />
        	</div>

        	<div className="container gift">
            {result._embedded.giftlists.map(g => (
              <React.Fragment key={`giftlist-${g.id_giftlist}`}>
                <div className="row">
                  <div className="listdata col col-md-6 d-md-flex align-items-md-center">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6 listname d-flex align-items-center">
                          <p>{g.name}</p>
                        </div>
                        <div className="col-md-3 created-at d-flex align-items-center">
                          <p>{dateFormat(g.date_add, "dd/mm/yyyy")}</p>
                        </div>
                        <div className="col-md-3 created-at d-flex align-items-center">
                          <p>€{g.total_price}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-5 buttons d-flex justify-content-between justify-content-md-end align-items-end">
                    <PageLink to={`/nl/mijn-wenslijst-bestellingen?id=${g.id_giftlist}`} className="btn-medium-secondary mr-2">
                      Bekijk bestellingen
                    </PageLink>
                    <PageLink to={`/nl/mijn-wenslijst?id=${g.id_giftlist}`} className="btn-medium-secondary ml-2">
                      Ga naar lijst
                    </PageLink>
                  </div>
            		</div>
            		<hr />
              </React.Fragment>
            ))}
        	</div>
        </>
      ) : (
        <div className="alert"></div>
      )}
    </section>
  )
}

AccountGiftListList.propTypes = {
  setSelected: PropTypes.func,
  lang: PropTypes.object
}

export default AccountGiftListList

import React, { useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { context } from '../../../store'

const { pages } = require('../../../config/pages.json')
const orderConf = pages.find(i => i.id === 'order-confirmation')
const stepPayment = pages.find(i => i.id === 'checkout-step-payment')
const cartPage = pages.find(i => i.id === 'cart')
const homePage = pages.find(i => i.id === 'homepage')

const CheckoutStatus = ({ lang }) => {
  const { store, cartProvider } = useContext(context)

  useEffect(() => {
    const getStatus = async () => {
      const result = await cartProvider.getCheckoutStatus(store.cart.checkout)

      if (result) {
        if (result.order) {
          navigate(`${orderConf[`path_${lang.suffix}`]}?id=${result.order.id_order}`)
        } else if (!result.cart) {
          navigate(homePage[`path_${lang.suffix}`])
        } else if (result.payment && result.payment.completed) {
          setTimeout(() => getStatus(), 3000); // wait for order to be created
        } else if (result.error) {
          navigate(cartPage[`path_${lang.suffix}`])
        } else {
          navigate(stepPayment[`path_${lang.suffix}`])
        }
      } else {
        navigate(homePage[`path_${lang.suffix}`])
      }
    }

    getStatus()
  }, [])

  return (
    <div className="row">
      <p>Even geduld, we controleren jouw betaling...</p>
    </div>
  )
}

CheckoutStatus.propTypes = {
  lang: PropTypes.object
}

export default CheckoutStatus

import React from 'react'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import { productURL } from '../../../lib/product'
import ProductListTileImage from './image'

const ProductListTileViewVoucher = ({ product, sku, lang, giftlist, cartOnly, navigation, className, add2cart, add2giftlist, disabled, adding, inView }) => {
  return (
    <PageLink to={giftlist ? `?id=${sku.id_giftlist}&item=${sku.id_giftlist_product}` : productURL(sku, lang)}>
      <div className="product-photo voucher">
        <div className="overlay">
          <div className="actions d-flex justify-content-center align-items-center">
            <button className="btn btn-primary btn-product-action clickable" disabled={!sku._links.add_to_cart || disabled} onClick={add2cart}>
              <i className={`fas ${adding === sku.id_sku ? 'fa-spin fa-spinner' : 'fa-cart-plus'}`}></i>
            </button>
          </div>
        </div>

        <ProductListTileImage product={product} sku={sku} lang={lang} inView={inView} />
      </div>
      <div>
        <div className="brand"></div>
        <div className="name-price d-flex justify-content-between">
          <h3>{sku[`name_${lang.suffix}`]}{sku.id_product_attribute ? `: ${sku[`combination_${lang.suffix}`]}` : ''}</h3>
        </div>
      </div>
    </PageLink>
  )
}

ProductListTileViewVoucher.propTypes = {
  product: PropTypes.object.isRequired,
  sku: PropTypes.object,
  lang: PropTypes.object,
  giftlist: PropTypes.bool,
  cartOnly: PropTypes.bool,
  navigation: PropTypes.object,
  className: PropTypes.string,
  add2cart: PropTypes.func,
  add2giftlist: PropTypes.func,
  disabled: PropTypes.bool,
  adding: PropTypes.any,
  inView: PropTypes.bool
}

export default ProductListTileViewVoucher

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import { context } from '../../../store'
import { l } from '../../../lib/locale'

const { pages } = require('../../../config/pages.json')
const homePage = pages.find(i => i.id === 'my-account')
const addressesPage = pages.find(i => i.id === 'my-addresses')
const giftlistsPage = pages.find(i => i.id === 'my-giftlists')
const wishlistPage = pages.find(i => i.id === 'my-wishlist')
const ordersPage = pages.find(i => i.id === 'my-orders')

const AccountSubMenu = ({ lang, className }) => {
  const { store, sessionProvider, cartProvider } = useContext(context)
  const { disabled, logout } = store && store.session
    ? store.session
    : { disabled: false, logout: false }

  const doLogout = async (e) => {
    e.preventDefault()
    await cartProvider.clear()
    await sessionProvider.logout()
  }

  return (
    <div className={`sub-menu d-flex flex-column ${className ? className : ''}`}>
      <div className="d-flex justify-content-between flex-md-column align-items-center align-items-md-start">
        <h2>Account</h2>
      </div>
      <div className="d-flex flex-md-column justify-content-between justify-content-md-start">
        <PageLink className="sub-menu-item clickable" to={homePage[`path_${lang.suffix}`]} disabled={disabled}>
          {homePage[`title_${lang.suffix}`]}
        </PageLink>
        <PageLink className="sub-menu-item clickable" to={addressesPage[`path_${lang.suffix}`]} disabled={disabled}>
          {addressesPage[`title_${lang.suffix}`]}
        </PageLink>
        <PageLink className="sub-menu-item clickable" to={ordersPage[`path_${lang.suffix}`]} disabled={disabled}>
          {ordersPage[`title_${lang.suffix}`]}
        </PageLink>
        {process.env.GATSBY_FEATURE_GIFTLIST ? (
          <PageLink className="sub-menu-item clickable" to={giftlistsPage[`path_${lang.suffix}`]} disabled={disabled}>
            {giftlistsPage[`title_${lang.suffix}`]}
          </PageLink>
        ) : ''}
        {process.env.GATSBY_FEATURE_WISHLIST ? (
          <PageLink className="sub-menu-item clickable" to={wishlistPage[`path_${lang.suffix}`]} disabled={disabled}>
            {wishlistPage[`title_${lang.suffix}`]}
          </PageLink>
        ) : ''}
      </div>

      <div className="d-flex justify-content-between flex-md-column align-items-center align-items-md-start mt-4">
        <button className="btn btn-secondary clickable" disabled={disabled} onClick={doLogout}>
          <i className={`fas ${logout ? 'fa-spin fa-spinner' : 'fa-sign-out'}`}></i> {l('button-logout', lang)}
        </button>
      </div>
    </div>
  )
}

AccountSubMenu.propTypes = {
  lang: PropTypes.object,
  className: PropTypes.string
}

export default AccountSubMenu

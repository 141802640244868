const PRODUCTURLOLD = 1
const PRODUCTURLNEW = 2
const PRODUCTURLOLDWITHOUTEAN = 3

const OUT_OF_STOCK_DEFAULT = 2
const OUT_OF_STOCK_ALLOW = 1
const OUT_OF_STOCK_DISALLOW = 0

const isOutOfStockAllowed = (i) => [OUT_OF_STOCK_ALLOW, OUT_OF_STOCK_DEFAULT].includes(i)

const normalize = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const productURL = (product, lang, version) => {
  if (+version === PRODUCTURLNEW) {
    return `/${lang.suffix}/products/${product.id_product}-${product[`link_rewrite_${lang.suffix}`]}.html`
  }
  if (+version === PRODUCTURLOLDWITHOUTEAN) {
    return `/${lang.suffix}/products/${product[`category_link_rewrite_${lang.suffix}`]}/${product.id_product}-${product[`link_rewrite_${lang.suffix}`]}.html`
  }

  return `/${lang.suffix}/products/${product[`category_link_rewrite_${lang.suffix}`]}/${product.id_product}-${product[`link_rewrite_${lang.suffix}`]}${product.ean13 ? `-${product.ean13}` : ''}.html`
}

const productBrandURL = (product, lang) => {
  const name = product.manufacturer_name ? normalize(product.manufacturer_name.replace(' ', '-')) : ''

  if (lang.id_lang === 1) {
    return `/${lang.suffix}/brands/${product.id_manufacturer}${name ? `-${name}` : ''}`
  } else if (lang.id_lang === 4) {
    return `/${lang.suffix}/merken/${product.id_manufacturer}${name ? `-${name}` : ''}`
  }
}

const stripStyleTag = (s) => {
  if (s && s.length > 0) s = s.replace(/style="[^"]*"/, '')
  return s
}

module.exports = {
  isOutOfStockAllowed,
  productURL,
  productBrandURL,
  stripStyleTag,
  PRODUCTURLOLD,
  PRODUCTURLOLDWITHOUTEAN
}

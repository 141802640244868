import React, { useState } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import { CartAddSKUButton } from '../..'
import { l, t } from '../../../lib/locale'

const OUT_OF_STOCK_DEFAULT = 2
const OUT_OF_STOCK_ALLOW = 1
const OUT_OF_STOCK_DISALLOW = 0

const explicit_out_of_stock = [OUT_OF_STOCK_ALLOW, OUT_OF_STOCK_DISALLOW]

const compareAttributes = (a,b) => {
  const array2Sorted = b.slice().sort()
  return a.length === b.length && a.slice().sort().every((value, index) => {
    return value === array2Sorted[index]
  })
}

const mapSelectedAttributesToSKU = (group, attribute, currentSet, product) => {
  currentSet.set(group.id_attribute_group, attribute.id_attribute)
  const attributes = Array.from(currentSet.values()) // currentSet.values()
  return product._embedded.skus.find(sku => {
    if (sku && sku.attributes && sku.attributes.length === attributes.length) {
      if (compareAttributes(sku.attributes, attributes)) {
        return sku
      }
    }
    return null
  })
}

// @todo should move to backend
const isSKUAvailable = (sku) => {
  if (!sku) return false
  if (!sku.available_for_order) return false
  if (sku.stock_quantity <= 0 && ![1,2].includes(sku.out_of_stock_allowed)) return false
  return true
}

/**
** product._embedded.skus.attributes = [id_attribute,...]
**
** groups --> attributes
** for each group 1 attribute => sku
**
*/

const SKUSelectorCombination = ({ product, sku, attributes, attribute_groups, lang, onChange, giftlist }) => {
  product.attributes = product.attributes || []
  const product_attributes = attributes.filter(a => product.attributes.includes(a.id_attribute))
  const product_attribute_groups = product_attributes
    .map(a => a.id_attribute_group)
    .filter((value, index, self) =>  self.indexOf(value) === index)
    .map(id_attribute_group => attribute_groups.find(g => g.id_attribute_group === id_attribute_group))

  const combination_set = new Map()
  product_attribute_groups.forEach(g => {
   combination_set.set(g.id_attribute_group, sku
     ? product_attributes.find(i => +i.id_attribute_group === +g.id_attribute_group && sku.attributes.includes(+i.id_attribute))
      ? product_attributes.find(i => +i.id_attribute_group === +g.id_attribute_group && sku.attributes.includes(+i.id_attribute)).id_attribute
      : ''
     : ''
   )
  })

  const [selectedSet, setSelectedSet] = useState(combination_set)

  const out_of_stock = explicit_out_of_stock.includes(product.out_of_stock)
    ? product.out_of_stock
    : explicit_out_of_stock.includes(product.manufacturer_out_of_stock)
      ? product.manufacturer_out_of_stock
      : OUT_OF_STOCK_DEFAULT

  const out_of_stock_allowed = out_of_stock !== OUT_OF_STOCK_DISALLOW

  const onAttributeSelected = (e) => {
    const id_attribute_group = +e.target.name
    const id_attribute = +e.target.value

    const new_set = new Map(selectedSet)
    new_set.set(id_attribute_group, id_attribute)
    setSelectedSet(new_set)

    let new_selected = null
    product._embedded.skus.forEach(s => {
      new_set.forEach(c => {
        if (s.attributes.includes(c)) new_selected = s
      })
    })

    onChange(new_selected)
  }

  return (
    <>
      {product_attribute_groups.map(attribute_group => {
        // console.log('loop', product_attributes)
        // console.log('loop', selectedSet.get(attribute_group.id_attribute_group))

        return (
          <div className="product-detail-right-select" key={`attribute-group-${attribute_group.id_attribute_group}`}>
            <p>{l('choose-prefix', lang)}{t(attribute_group, 'public_name', lang)}{l('choose-suffix', lang)}</p>
            <select name={attribute_group.id_attribute_group} value={combination_set.get(attribute_group.id_attribute_group)} className="form-control" onChange={e => onAttributeSelected(e)} onBlur={e => onAttributeSelected(e)}>
              <option value="">{l('choose-prefix', lang)}{t(attribute_group, 'public_name', lang)}{l('choose-suffix', lang)}</option>
              {product_attributes.filter(a => a.id_attribute_group === attribute_group.id_attribute_group).map(attribute => {
                const attribute_sku = mapSelectedAttributesToSKU(attribute_group, attribute, selectedSet, product)

                return (
                  <option value={+attribute.id_attribute} key={`attribute-group-${attribute_group.id_attribute_group}-${attribute.id_attribute}`}>
                    {t(attribute, 'name', lang)} {isSKUAvailable(attribute_sku) ? (
                      ''
                    ) : (
                      `- ${l('lbl-available-not', lang)}`
                    )}
                  </option>
                )
              })}
            </select>
          </div>
        )
      })}

      <div className="product-detail-right-actions combination">
        {sku &&
          <CartAddSKUButton sku={sku} lang={lang} invalid={!isSKUAvailable(sku) && !giftlist} giftlist={giftlist} className="btn-large-secondary" />}

        {process.env.GATSBY_FEATURE_WISHLIST && (
          <button className="btn-add-to-wishlist">
            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.613 6.25444C24.3031 4.53881 23.4094 2.69596 21.1556 1.79411L21.0366 1.74682C20.117 1.3937 19.1306 1.24888 18.1483 1.3228C16.0658 1.47773 14.2083 2.70086 13.0716 4.45237L12.9998 4.56164L12.928 4.45237C11.7865 2.70086 9.93384 1.47773 7.85126 1.3228C6.86902 1.24888 5.88261 1.3937 4.96306 1.74682L4.84401 1.79411C2.5902 2.69596 1.6965 4.53881 1.38664 6.25444C0.965886 8.58001 1.59865 10.961 2.95061 12.8985C5.88611 17.1207 11.7848 22.0703 12.8987 22.9949C12.9253 23.017 12.9588 23.029 12.9933 23.029C13.0278 23.029 13.0613 23.017 13.0879 22.9949C14.2083 22.0703 20.1005 17.1239 23.036 12.8985C24.401 10.961 25.0337 8.58001 24.613 6.25444Z" stroke="#7C8486" strokeWidth="2" strokeMiterlimit="10"></path>
            </svg>
            <p>Voeg toe aan wishlist</p>
          </button>
        )}
      </div>

      {sku && !giftlist && (
        <div className="product-detail-right-extra-info">
          {sku.available_for_order ? (
            <>
              {sku.stock_quantity > 0 ? (
                <>
                  <div className="product-detail-right-extra-info-item">
                    <span className="product-detail-right-extra-info-success"></span>
                    <p>{l('lbl-available-now', lang)}</p>
                  </div>
                  <div className="product-detail-right-extra-info-item">
                    <span className="product-detail-right-extra-info-success"></span>
                    <p>{t(sku, 'available_now', lang) ? t(sku, 'available_now', lang) : l('lbl-delivery-now', lang)}</p>
                  </div>
                </>
              ) : out_of_stock_allowed ? (
                <>
                  <div className="product-detail-right-extra-info-item">
                    <span className="product-detail-right-extra-info-warning"></span>
                    {sku.available_date ? (
                      <p>{l('lbl-available-from', lang)} {dateFormat(new Date(sku.available_date), "dd/mm/yyyy")}</p>
                    ) : t(sku, 'available_later', lang) ? (
                      <p>{t(sku, 'available_later', lang)}</p>
                    ) : (
                      <p>{l('lbl-delivery-later', lang)}</p>
                    )}
                  </div>
                </>
              ) : (
                <div className="product-detail-right-extra-info-item">
                  <span className="product-detail-right-extra-info-error"></span>
                  <p>{l('lbl-available-not', lang)}</p>
                </div>
              )}
            </>
          ) : (
            <div className="product-detail-right-extra-info-item">
              <span className="product-detail-right-extra-info-error"></span>
              <p>{l('lbl-available-not', lang)}</p>
            </div>
          )}
        </div>
      )}
    </>
  )
}

SKUSelectorCombination.propTypes = {
  product: PropTypes.object,
  sku: PropTypes.object,
  attributes: PropTypes.array,
  attribute_groups: PropTypes.array,
  lang: PropTypes.object,
  onChange: PropTypes.func
}

export default SKUSelectorCombination
